import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Scroll from 'react-scroll';
import { isEmpty } from 'lodash';
import { useTabIndex } from 'react-tabindex';
import { getProfileDetailAPI } from "../../API/profile";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { ErrorBox } from "../Element/ErrorBox";


function ProfileDetail() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [profile, setProfile] = useState({});
    const [firstName, setFirstName] = useLocalStorage("DST_U_FIRST_NAME", "");
    const [lastName, setLastName] = useLocalStorage("DST_U_LAST_NAME", "");
    const [email, setEmail] = useLocalStorage("DST_U_EMAIL", "");
    const [language, setLanguage] = useLocalStorage("DST_U_LANGUAGE", "");
    const [timezone, setTimezone] = useLocalStorage("DST_U_TIMEZONE", "");
    const [errors, setErrors] = useState({});

    // --- API --- //
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        document.title = "Profile Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        function onDetailSuccess(responseData) {
            console.log(responseData);
            setFirstName(responseData.firstName);
            setLastName(responseData.lastName);
            setEmail(responseData.email);
            setLanguage(responseData.language);
            setTimezone(responseData.timezone);
            setErrors({});
        }

        let mounted = true;

        if (isEmpty(profile)) {
            getProfileDetailAPI((responseData) => { // A.k.a. "onSuccess"
                if (mounted) {
                    setProfile(responseData);
                    onDetailSuccess(responseData);
                }
            }, (apiErrors) => { // A.k.a. "onError"
                setErrors(apiErrors);

                // The following code will cause the screen to scroll to the top of
                // the page. Please see ``react-scroll`` for more information:
                // https://github.com/fisshy/react-scroll
                var scroll = Scroll.animateScroll;
                scroll.scrollToTop();
            }, () => {  // A.k.a. "onDone"
                setIsLoading(false);
            });
        }

        return () => mounted = false;
    },[
        profile, setEmail, setFirstName, setLanguage, setLastName, setTimezone,
    ]);

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to="/dashboard"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-container">
                                <h2><strong><i className="fa fa-user-circle" aria-hidden="true"></i>&nbsp;Profile</strong></h2>
                                <hr className="w3-grey" />
                            </div>
                            {errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container">
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>First Name</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="firstName"
                                                   value={firstName}
                                        />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Last Name</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="lastName"
                                                   value={lastName}
                                        />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Email</strong>
                                        </label>
                                        <input className={`w3-col m8 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="email"
                                                   value={email}
                                        />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Language</strong>
                                        </label>
                                        <input className={`w3-col m1 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="language"
                                                   value={language}
                                        />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Timezone</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="timezone"
                                                   value={timezone}
                                        />
                                    </p>



                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        {/*
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to="/evaluations"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        */}
                                        <span className="w3-col m6">&nbsp;</span>
                                        {/*
                                        <Link className="w3-col m3 w3-btn w3-blue w3-round-xlarge w3-right"
                                                     to={`/profile/update`}
                                                   type="button"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;UPDATE
                                        </Link>
                                        */}
                                    </p>
                                </form>
                            }

                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default ProfileDetail;
