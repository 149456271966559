import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys } from 'humps';
import {
    DIGITALSCANTRON_SUBMISSION_BROWSER_METRICS_API_ENDPOINT
} from "../Constants/API";


export function getSubmissionBrowserMetricsAPI(submissionID, onSuccessCallback, onErrorCallback, onDoneCallback) {
    let aURL = DIGITALSCANTRON_SUBMISSION_BROWSER_METRICS_API_ENDPOINT.replace("xxx", submissionID);
    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // BUGFIX
        data.submissionID = data.submissionId;
        data.submissionUUID = data.submissionUuid;
        data.TenantID = data.tenantId;
        data.createdFromIP = data.createdFromIp;
        data.modifiedFromIP = data.modifiedFromIp;

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        try {
            const responseData = exception.response.data;
            let errors = camelizeKeys(responseData);
            onErrorCallback(errors);
        } catch (e) {
            console.log("WARNING: getSubmissionDetailAPI has programming error");
            onErrorCallback(e);
        }
    }).then(onDoneCallback);
}
