import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import Scroll from 'react-scroll';
import { useTabIndex } from 'react-tabindex';
import { ErrorBox } from "../Element/ErrorBox";
import { deleteSubmissionAPI } from "../../API/submission";

function SubmissionDelete() {
    //
    // COMPONENT STATE MANAGEMENT
    //
    const [errors, setErrors] = useState({});
    const [forceUrl, setForceUrl] = useState("");

    // --- Form ---

    const tabIndex = useTabIndex();

    // --- API --- //
    const [isLoading, setIsLoading] = useState(false);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-2];

    useEffect(() => {
        document.title = "Submission Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    //
    // API
    //

    //
    // COMPONENT FUNCTIONS
    //

    function onFormSubmitClick(e) {
        setIsLoading(true);
        deleteSubmissionAPI(id, onSuccess, onError, onDone);
    }

    //
    // API Handling
    //

    function onSuccess(submission) {
        console.log("SubmissionDelete:", submission);
        setForceUrl("/submissions");
    }

    function onError(err) {
        setErrors(err);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onDone() {
        setIsLoading(false);
    }

    //
    // COMPONENT RENDER
    //

    if (forceUrl !== "") {
        return <Navigate to={forceUrl} />;
    }

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/submission/${id}`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Submission Deletion</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Submission Deletion</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        {/* Do nothing. */}
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>
                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}


                            {errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }

                            {/*
                                -----
                                TABLE
                                -----
                            */}
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container">
                                    <div className="w3-panel w3-red">
                                        <h3><i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;Danger!</h3>
                                        <p>Deletions are permanent and cannot be undone.</p>
                                    </div>
                                    <p>
                                    You are about to <strong>permanently delete</strong> the submission,
                                    this means it cannot be accessed by your team and your student ever again.
                                    {/*If you are in doubt, it's recommended to&nbsp;<Link to={`/submission/${id}/archive`}>archive&nbsp;<i className="fa fa-link" aria-hidden="true"></i></Link> instead.*/}
                                    </p>
                                    <p>Are you sure you want to continue?</p>
                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to={`/submission/${id}`}
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <button className="w3-col m3 w3-btn w3-green w3-round-xlarge w3-right"
                                                  onClick={onFormSubmitClick}
                                                     type="button"
                                                 tabIndex={tabIndex}>
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;DELETE
                                        </button>
                                    </p>
                                </form>
                            }
                            {/*
                                ----------
                                end TABLE
                                ----------
                            */}
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default SubmissionDelete;
