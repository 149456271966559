import React from "react";
import { Link } from "react-router-dom";


function RegisterComplete() {
    return (
        <>
            <div className="w3-panel w3-row">
                <div className="w3-col m3 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m6">
                    <div className="w3-light-grey w3-border w3-border-grey w3-round-xlarge w3-card-4" style={{width:"100%",}}>
                        <div className="w3-padding w3-center">
                            <i className="fa fa-check-circle w3-text-green w3-jumbo"></i>
                            <h1><strong>Registration Completed</strong></h1>
                            <p>You have successfully registered! To begin, please go to the <Link to="/login">login</Link> page and enter the credentials you registered with.</p>
                            <p>
                            <Link to="/login" className="w3-btn w3-mobile w3-green w3-round-xlarge w3-padding">
                                Login&nbsp;<i className="fa fa-chevron-circle-right"></i>
                            </Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w3-col m3 w3-container">
                </div>
            </div>
        </>
    );
}

export default RegisterComplete;
