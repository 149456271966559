import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Base64 } from 'js-base64';
import { useTabIndex } from 'react-tabindex';
import QRCode from "qrcode.react";
// import ReactJson from 'react-json-view';// DEPRECATED https://github.com/mac-s-g/react-json-view/issues/403
// import { getShareableLinkInfoAPI } from "../../API/evaluation";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { getAPIBaseURL } from "../../Helpers/urlUtility";
// import { ErrorBox } from "../Element/ErrorBox";
// import { QuestionRow } from "../Element/QuestionRowChoiceCol";

function ShareableLinkInfo() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [tenantId] = useLocalStorage("DST_E_TENANT_ID", 0);
    const [uuid] = useLocalStorage("DST_E_UUID", "");
    const [name] = useLocalStorage("DST_E_NAME", "");
    const [alternateName] = useLocalStorage("DST_E_ALTERNATE_NAME", "");
    const [description] = useLocalStorage("DST_E_DESCRIPTION", "");
    const [customId] = useLocalStorage("DST_E_CUSTOM_ID", "");
    const [category] = useLocalStorage("DST_E_CATEGORY", "");
    const [imageURL] = useLocalStorage("DST_E_IMAGE_URL", "");
    const [numberOfMultipleChoiceQuestions] = useLocalStorage("DST_E_NUMBER_OF_MULTIPLE_CHOICE_QUESTIONS", "");
    const [numberOfTextareaQuestions] = useLocalStorage("DST_E_NUMBER_OF_TEXTAREA_QUESTIONS", "");
    const [numberOfAreas] = useLocalStorage("DST_E_NUMBER_OF_AREAS", "");
    const [durationInMinutes] = useLocalStorage("DST_E_DURATION_IN_MINUTES", "");
    const [beginTime] = useLocalStorage("DST_E_BEGINTIME", "");
    const [endTime] = useLocalStorage("DST_E_ENDTIME", "");
    const [grade] = useLocalStorage("DST_GRADE_NAME", "");
    const [year] = useLocalStorage("DST_E_YEAR", "");
    const [city] = useLocalStorage("DST_E_CITY", "");
    const [schoolName] = useLocalStorage("DST_E_SCHOOL_NAME", "");
    const [schoolCode] = useLocalStorage("DST_E_SCHOOL_CODE", "");
    // const [answers] = useLocalStorage("DST_E_ANSWERS", [])
    const [tenant] = useLocalStorage("DIGITALSCANTRON_TENANT");
    // const [errors, setErrors] = useState({});

    // --- API --- //
    // const [isLoading, setIsLoading] = useState(true);
    const [isFetched, setIsFetched] = useState(false);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-2];

    useEffect(() => {
        document.title = "Evaluation Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    //
    // API
    //

    if (isFetched === false) {
        // getShareableLinkInfoAPI(id, onDetailSuccess, onDetailError, onDetailDone);
        setIsFetched(true);
    }

    //
    // COMPONENT FUNCTIONS
    //

    function onDownloadClick(e) {
        // SPECIAL THANKS:
        // https://github.com/zpao/qrcode.react/issues/37
        console.log("Downloading QR Code...");

        const canvas = document.querySelector('.HpQrcode > canvas');
        const pngFile = canvas.toDataURL("image/png");

        const downloadLink = document.createElement("a");
        downloadLink.download = "qrcode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
    }

    //
    // MISC
    //


    // https://stackoverflow.com/a/14246394
    var beginTimeDT = new Date(beginTime).getTime();
    var endTimeDT = new Date(endTime).getTime();
    const codePayload = {
        "uuid": uuid,
        "name": name,
        "alias": alternateName,
        "i_url": imageURL,
        "desc": description,
        "qs": numberOfMultipleChoiceQuestions,
        "taqs": numberOfTextareaQuestions,
        "opts": "4",
        "s_dt": beginTimeDT,
        "e_dt": endTimeDT,
        "s_url": getAPIBaseURL() + "/submit",
        "org_id": tenantId,
        "sid": "",
        "sn": "",
        "sc": "",
        "schn": schoolName,
        "schc": schoolCode,
        "city": city,
        "g": grade,
        "y": year,
        "noa": numberOfAreas,
        "d": durationInMinutes,
        "appt": tenant.appTitle,
        "appi": tenant.appImageURL !== undefined ? tenant.appImageURL : tenant.appImageUrl, // BUGFIX
        "appc": tenant.appColour,
        "appl": tenant.language,
        "t_id": customId,
        "t_c": category
    };
    const codePayloadStr = JSON.stringify(codePayload);
    const codePayloadB64 = Base64.encode(codePayloadStr)
    const qrCodeURL = process.env.REACT_APP_WWW_KEYPAD_URL+"?code="+codePayloadB64;

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/evaluation/${id}`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-container">
                                <h2><strong><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;Shareable Link Information</strong></h2>
                                <hr className="w3-grey" />
                            </div>
                            <form className="w3-container">
                                <h3>Overview</h3>
                                <p>The DigitalScan system works as follows:</p>
                                <ol className="w3-ol">
                                    <li>You create an evaluation.</li>
                                    <li>A JSON payload gets created with values populated from the created evaluation. These values provide enough information for an evaluation to be stared by a student.</li>
                                    <li>The JSON payload gets encoded in a base64 format.</li>
                                    <li>The base64 encoded JSON payload gets appended to a URL</li>
                                    <li>The URL gets a QR code generated.</li>
                                    <li>You give out the QR code to your students. Or you can send them the external URL link.</li>
                                </ol>
                                <h3>JSON Payload</h3>
                                <p>The following is the datastructure used by DigitalScan</p>
                                <div className="w3-col">
                                   <div className="w3-responsive">
                                        {/* <ReactJson src={codePayload} displayDataTypes={false} /> */}
                                        <pre>
                                            {JSON.stringify(codePayload, null, 2)}
                                        </pre>
                                   </div>
                               </div>
                               <p>The fields are mapped as followed:</p>
                               <table className="w3-table w3-bordered">
                                <tr>
                                  <th>Field</th>
                                  <th>Type</th>
                                  <th>Required</th>
                                  <th>Description</th>
                                </tr>
                                <tr>
                                  <td>uuid</td>
                                  <td>string</td>
                                  <td>Yes</td>
                                  <td>The evaluation UUID.</td>
                                </tr>
                                <tr>
                                  <td>name</td>
                                  <td>string</td>
                                  <td>Yes</td>
                                  <td>The name of the evaluation.</td>
                                </tr>
                                <tr>
                                  <td>alias</td>
                                  <td>string</td>
                                  <td>Yes</td>
                                  <td>The alternate name of the evaluation.</td>
                                </tr>
                                <tr>
                                  <td>i_url</td>
                                  <td>string</td>
                                  <td>Yes</td>
                                  <td>The URL of the evaluation thumbnail to show students.</td>
                                </tr>
                                <tr>
                                  <td>desc</td>
                                  <td>string</td>
                                  <td>Yes</td>
                                  <td>The evaluation description to show students before the students start.</td>
                                </tr>
                                <tr>
                                  <td>qs</td>
                                  <td>int</td>
                                  <td>Yes</td>
                                  <td>The value which controls how many multiple-choice questions there will be.</td>
                                </tr>
                                <tr>
                                  <td>taqs</td>
                                  <td>string</td>
                                  <td>Yes</td>
                                  <td>The textarea question count.</td>
                                </tr>
                                <tr>
                                  <td>opts</td>
                                  <td>int</td>
                                  <td>Yes</td>
                                  <td>The number of options per multiple choice questions.</td>
                                </tr>
                                <tr>
                                  <td>s_dt</td>
                                  <td>string</td>
                                  <td>Yes</td>
                                  <td>The date to let the student when that this evaluations starts. Please note this value is a JavaScript <b>datetime</b> converted with a <b>getTime()</b> function.</td>
                                </tr>
                                <tr>
                                  <td>e_dt</td>
                                  <td>string</td>
                                  <td>Yes</td>
                                  <td>The date to let the student when that this evaluations ends. Please note this value is a JavaScript <b>datetime</b> converted with a <b>getTime()</b> function.</td>
                                </tr>
                                <tr>
                                  <td>s_url</td>
                                  <td>string</td>
                                  <td>Yes</td>
                                  <td>The backend API endpoint that the frontend will submit the student evaluation submission. <b>Please do not change</b>.</td>
                                </tr>
                                <tr>
                                  <td>org_id</td>
                                  <td>int</td>
                                  <td>Yes</td>
                                  <td>The organization ID you belong to.</td>
                                </tr>
                                <tr>
                                  <td>sid</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The unique identification of the student from your system that you'd like to attach to the student's submission. This value is not set by DigitalScan but must be set by your system. Please note by specifying this value the <i>My Identification</i> section becomes <strong>locked</strong> when students lands on that page..</td>
                                </tr>
                                <tr>
                                  <td>sn</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The name of the student.</td>
                                </tr>
                                <tr>
                                  <td>sc</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The code of the student.</td>
                                </tr>
                                <tr>
                                  <td>schn</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The name of the school.</td>
                                </tr>
                                <tr>
                                  <td>schc</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The code of the school.</td>
                                </tr>
                                <tr>
                                  <td>city</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The city that the school is located at.</td>
                                </tr>
                                <tr>
                                  <td>g</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The grade that the student is in.</td>
                                </tr>
                                <tr>
                                  <td>y</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The year that the student is in.</td>
                                </tr>
                                <tr>
                                  <td>noa</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The number of areas for this evaluation.</td>
                                </tr>
                                <tr>
                                  <td>d</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The duration in minutes that this evaluation will take.</td>
                                </tr>

                                <tr>
                                  <td>appt</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The application title to display when the student is in the for the keypad service.</td>
                                </tr>
                                <tr>
                                  <td>appi</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The image URL to display when the student is in the for the keypad service for the logo.</td>
                                </tr>
                                <tr>
                                  <td>appc</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The colour to apply in the applications top menu.</td>
                                </tr>
                                <tr>
                                  <td>appl</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The language to render the application in.</td>
                                </tr>
                                <tr>
                                  <td>t_id</td>
                                  <td>int</td>
                                  <td>No</td>
                                  <td>The custom ID you use in your system to identify this test. Every student submission will include this ID value.</td>
                                </tr>
                                <tr>
                                  <td>t_c</td>
                                  <td>string</td>
                                  <td>No</td>
                                  <td>The category you use in your system to identify this test. Every student submission will include this category value.</td>
                                </tr>
                              </table>
                               <h3>Base64</h3>
                               <p>The JSON payload base encoded string is as follows:</p>
                               <p className="w3-row">
                                   <div className="w3-col">
                                       <textarea rows={12} style={{"width":"100%",}} disabled={true}>{codePayloadB64}</textarea>
                                   </div>
                               </p>

                               <h3>External URL</h3>
                               <p>The shareable link is as follows:</p>
                               <p className="w3-row">
                                   <div className="w3-col">
                                       <div className="w3-col">
                                           <textarea rows={12} style={{"width":"100%",}} disabled={true}>{qrCodeURL}</textarea>
                                       </div>
                                   </div>
                               </p>

                               <h3>QR Code</h3>
                               <p>The shareable link encoded as a QR code is as follows:</p>
                               <p className="w3-row">

                                   <div className="w3-col m8 l6 w3-center">
                                       <a href={qrCodeURL} target="_blank" rel="noreferrer" tabIndex={tabIndex} className="HpQrcode">
                                           {qrCodeURL && qrCodeURL.length < 2900
                                               ? <QRCode value={qrCodeURL} size={400} className="w3-image w3-border w3-mobile" />
                                               : <>Receipt Link</>
                                           }
                                       </a>
                                       <br />
                                       <button className="w3-mobile w3-btn w3-green w3-round-xlarge"
                                                 onClick={onDownloadClick}
                                                    type="button"
                                                tabIndex={tabIndex}>
                                           <i className="fa fa-download" aria-hidden="true"></i>&nbsp;DOWNLOAD
                                       </button>
                                    </div>
                               </p>
                               <p className="w3-row">
                                   <hr className="w3-col w3-grey" />
                                   <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                to={`/evaluation/${id}`}
                                          tabIndex={tabIndex}>
                                       <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                   </Link>
                               </p>

                            </form>
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default ShareableLinkInfo;
