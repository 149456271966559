import React from "react";

export function QuestionRow({ questionPK, optionsCount, onQuestionChoiceChange, answers, tabIndex }) {
    const start = 1;
    const end = optionsCount;
    const choicePKs = [...Array(end - start + 1).keys()].map(x => x + start);; // https://jasonwatmore.com/post/2021/10/02/vanilla-js-create-an-array-with-a-range-of-numbers-in-a-javascript
    const widthScale = 100 / (optionsCount + 1);
    const widthScaleStr = widthScale+"%";
    return (
        <div className="w3-margin-bottom">
            <div className="w3-container w3-margin">

                <div className="w3-row w3-medium">
                    <div className="w3-col w3-center" style={{width:widthScaleStr}}>
                        <button class="w3-button w3-hover-white w3-white" style={{cursor:"pointer"}} type="button">
                            <strong>{questionPK}.</strong>
                        </button>
                    </div>
                    {choicePKs.map((choicePK) =>
                        <ChoiceCol key={`c-${choicePK}`}
                            questionPK={questionPK}
                              choicePK={choicePK}
                              cssWidth={widthScaleStr}
                onQuestionChoiceChange={onQuestionChoiceChange}
                               answers={answers}
                              tabIndex={tabIndex}
                        />
                    )}

                </div>


            </div>
        </div>
    );
}


const letters = {
    1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F", 7: "G", 8: "H", 9: "I",
    10: "J", 11: "K", 12: "L", 13: "M", 14: "N", 15: "O", 16: "P", 17: "Q",
    18: "R", 19: "S", 20: "T", 21: "U", 22: "V", 23: "W", 24: "X", 25: "Y", 26: "Z",
}

export function ChoiceCol({ questionPK, choicePK, cssWidth, onQuestionChoiceChange, answers, tabIndex }) {
    const pickedLetter = answers[questionPK];
    const letter = letters[choicePK];

    const className = pickedLetter === letter
        ? "w3-btn w3-blue w3-circle w3-border w3-border-light-blue w3-text-white w3-card"
        : "w3-btn w3-light-gray w3-circle w3-border w3-border-blue w3-text-blue";
    return (
        <div className="w3-col w3-center" style={{width:cssWidth}}>
            <button className={className}
                      onClick={(e,i,l) => onQuestionChoiceChange(e, questionPK, choicePK, letter)}
                     tabIndex={tabIndex}
                         type="button">
                      {letter}
            </button>
        </div>
    );
}
