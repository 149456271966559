import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Scroll from 'react-scroll';
import { isEmpty } from 'lodash';
import { useTabIndex } from 'react-tabindex';
import { getSubmissionBrowserMetricsAPI } from "../../API/submissionBrowserMetrics";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { ErrorBox } from "../Element/ErrorBox";


function SubmissionBrowserMetrics() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [submissionBrowserMetric, setSubmissionBrowserMetric] = useState({});
    const [submissionID, setSubmissionID] = useLocalStorage("DST_SBM_SUBMISSION_ID", "");
    const [browserName, setBrowserName] = useLocalStorage("DST_SBM_BROWSER_NAME", "");
    const [browserVersion, setBrowserVersion] = useLocalStorage("DST_SBM_BROWSER_VERSION", "");
    const [osName, setOsName] = useLocalStorage("DST_SBM_OS_NAME", "");
    const [osVersion, setOsVersion] = useLocalStorage("DST_SBM_OS_VERSION", "");
    const [isDesktop, setIsDesktop] = useLocalStorage("DST_SBM_IS_DESKTOP", "");
    const [isTablet, setIsTablet] = useLocalStorage("DST_SBM_IS_TABLET", "");
    const [isMobile, setIsMobile] = useLocalStorage("DST_SBM_IS_MOBILE", "");
    const [appCodeName, setAppCodeName] = useLocalStorage("DST_SBM_APP_CODE_NAME", "");
    const [appName, setAppName] = useLocalStorage("DST_SBM_APP_NAME", "");
    const [appVersion, setAppVersion] = useLocalStorage("DST_SBM_APP_VERSION", "");
    const [cookiesEnabled, setCookiesEnabled] = useLocalStorage("DST_SBM_SET_COOKIES_ENABLED", "");
    const [language, setLanguage] = useLocalStorage("DST_SBM_SET_LANGUAGE", "");
    const [platform, setPlatform] = useLocalStorage("DST_SBM_SET_PLATFORM", "");
    const [product, setProduct] = useLocalStorage("DST_SBM_SET_PRODUCT", "");
    const [agent, setAgent] = useLocalStorage("DST_SBM_SET_AGENT", "");
    const [screenAvailHeight, setScreenAvailHeight] = useLocalStorage("DST_SBM_SET_SCREEN_AVAIL_HEIGHT", "");
    const [screenAvailWidth, setScreenAvailWidth] = useLocalStorage("DST_SBM_SET_SCREEN_AVAIL_WIDTH", "");
    const [screenColorDepth, setScreenColorDepth] = useLocalStorage("DST_SBM_SET_SCREEN_COLOR_DEPTH", "");
    const [screenHeight, setScreenHeight] = useLocalStorage("DST_SBM_SET_SCREEN_HEIGHT", "");
    const [screenWidth, setScreenWidth] = useLocalStorage("DST_SBM_SET_SCREEN_WIDTH", "");
    const [frontendVersion, setFrontendVersion] = useLocalStorage("DST_SBM_SET_FRONTEND_VERSION", "");
    const [createdFromIP, setCreatedFromIP] = useLocalStorage("DST_SBM_SET_CREATED_FROM_IP", "");
    const [errors, setErrors] = useState({});

    // --- API --- //
    const [isLoading, setIsLoading] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-2];

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Submission Metric Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        function onDetailSuccess(responseData) {
            console.log("onDetailSuccess:",responseData);
            setSubmissionID(responseData.submissionID);
            setBrowserName(responseData.browserName);
            setBrowserVersion(responseData.browserVersion);
            setOsName(responseData.osName);
            setOsVersion(responseData.osVersion);
            setIsDesktop(responseData.isDesktop);
            setIsTablet(responseData.isTablet);
            setIsMobile(responseData.isMobile);
            setAppCodeName(responseData.appCodeName);
            setAppName(responseData.appName);
            setAppVersion(responseData.appVersion);
            setCookiesEnabled(responseData.cookiesEnabled);
            setLanguage(responseData.language);
            setPlatform(responseData.platform);
            setProduct(responseData.product);
            setAgent(responseData.userAgent);
            setScreenAvailHeight(responseData.screenAvailHeight);
            setScreenAvailWidth(responseData.screenAvailWidth);
            setScreenColorDepth(responseData.screenColorDepth);
            setScreenHeight(responseData.screenHeight);
            setScreenWidth(responseData.screenWidth);
            setFrontendVersion(responseData.frontendVersion);
            setCreatedFromIP(responseData.createdFromIP);
            setErrors({});
        }

        let mounted = true;

        if (isEmpty(submissionBrowserMetric)) {
            getSubmissionBrowserMetricsAPI(id, (responseData) => { // A.k.a. "onSuccess"
                if (mounted) {
                    setSubmissionBrowserMetric(responseData);
                    onDetailSuccess(responseData);
                }
            }, (apiErrors) => { // A.k.a. "onError"
                setErrors(apiErrors);

                // The following code will cause the screen to scroll to the top of
                // the page. Please see ``react-scroll`` for more information:
                // https://github.com/fisshy/react-scroll
                var scroll = Scroll.animateScroll;
                scroll.scrollToTop();
            }, () => {  // A.k.a. "onDone"
                setIsLoading(false);
            });
        }

        return () => mounted = false;
    },[
        id, setAgent, setAppCodeName, setAppName, setAppVersion, setBrowserName, setBrowserVersion, setCookiesEnabled, setCreatedFromIP, setFrontendVersion, setIsDesktop, setIsMobile, setIsTablet, setLanguage, setOsName, setOsVersion, setPlatform, setProduct, setScreenAvailHeight, setScreenAvailWidth, setScreenColorDepth, setScreenHeight, setScreenWidth, setSubmissionID, submissionBrowserMetric
    ]);

    //
    // COMPONENT FUNCTIONS
    //

    function onMoreClicked() {
        setShowMore(!showMore);
    }

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/submission/${submissionID}`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-safari" aria-hidden="true"></i>&nbsp;Submission Browser Info</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-safari" aria-hidden="true"></i>&nbsp;Submission Browser Info</strong>
                                            </h2>
                                        </div>
                                    </div>

                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>
                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}

                            {errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container" key={submissionID}>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Submission ID</strong>
                                        </label>
                                        <input className={`w3-col m6 w3-input w3-small`}
                                                disabled={true}
                                                    type="text"
                                                    name="submissionID"
                                                   value={submissionID} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Browser</strong>
                                        </label>
                                        <input className={`w3-col m6 w3-input w3-small`}
                                                disabled={true}
                                                    type="text"
                                                    name="browserName"
                                                   value={`${browserName} (${browserVersion})`} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>OS</strong>
                                        </label>
                                        <input className={`w3-col m6 w3-input w3-small`}
                                                disabled={true}
                                                    type="text"
                                                    name="browserName"
                                                   value={`${osName} (${osVersion})`} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Desktop</strong>
                                        </label>
                                        <input className={`w3-col m6 w3-check w3-small`}
                                                disabled={true}
                                                    type="checkbox"
                                                    name="isDesktop"
                                                   checked={isDesktop} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Tablet</strong>
                                        </label>
                                        <input className={`w3-col m6 w3-check w3-small`}
                                                disabled={true}
                                                    type="checkbox"
                                                    name="isTablet"
                                                   checked={isTablet} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Mobile</strong>
                                        </label>
                                        <input className={`w3-col m6 w3-check w3-small`}
                                                disabled={true}
                                                    type="checkbox"
                                                    name="isMobile"
                                                   checked={isMobile} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Language</strong>
                                        </label>
                                        <input className={`w3-col m3 w3-input w3-small`}
                                                disabled={true}
                                                    type="text"
                                                    name="language"
                                                   value={language} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Created from IP</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`}
                                                disabled={true}
                                                    type="text"
                                                    name="createdFromIP"
                                                   value={createdFromIP} />
                                    </p>
                                    {!showMore &&
                                        <p>
                                            <button onClick={onMoreClicked} className="w3-button w3-block w3-center-align w3-light-grey" type="button">Show More ...</button>
                                        </p>
                                    }
                                    <div id="moreMetricDetails" className={`${showMore ? "w3-show" : "w3-hide"}`}>

                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>App Code Name</strong>
                                            </label>
                                            <input className={`w3-col m6 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="appCodeName"
                                                       value={appCodeName} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>App Name</strong>
                                            </label>
                                            <input className={`w3-col m6 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="appName"
                                                       value={appName} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>App Version</strong>
                                            </label>
                                            <textarea className={`w3-col 12 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="appVersion"
                                                       value={appVersion}
                                                        rows={3} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Cookies Enabled</strong>
                                            </label>
                                            <input className={`w3-col m3 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="cookiesEnabled"
                                                       value={cookiesEnabled} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Platform</strong>
                                            </label>
                                            <input className={`w3-col m3 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="platform"
                                                       value={platform} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Product</strong>
                                            </label>
                                            <input className={`w3-col m3 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="product"
                                                       value={product} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Agent</strong>
                                            </label>
                                            <textarea className={`w3-col m12 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="agent"
                                                       value={agent}
                                                       rows={3} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Screen Available Height</strong>
                                            </label>
                                            <input className={`w3-col m1 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="screenAvailHeight"
                                                       value={screenAvailHeight} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Screen Available Width</strong>
                                            </label>
                                            <input className={`w3-col m1 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="screenAvailWidth"
                                                       value={screenAvailWidth} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Screen Color Depth</strong>
                                            </label>
                                            <input className={`w3-col m1 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="screenColorDepth"
                                                       value={screenColorDepth} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Screen Height</strong>
                                            </label>
                                            <input className={`w3-col m1 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="screenHeight"
                                                       value={screenHeight} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Screen Width</strong>
                                            </label>
                                            <input className={`w3-col m1 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="screenWidth"
                                                       value={screenWidth} />
                                        </p>
                                        <p className="w3-row">
                                            <label className="w3-col">
                                                <strong>Frontend Version</strong>
                                            </label>
                                            <input className={`w3-col m2 w3-input w3-small`}
                                                    disabled={true}
                                                        type="text"
                                                        name="frontendVersion"
                                                       value={frontendVersion} />
                                        </p>

                                    </div>


                                </form>
                            }

                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default SubmissionBrowserMetrics;
