import { React, Component } from "react";
import "./w3.css";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import ProfileUpdate from "./Components/Profile/Update";
import ProfileDetail from "./Components/Profile/Detail";
import RemotePayloadInfo from "./Components/Organization/RemotePayloadInfo";
import SampleSubmissionList from "./Components/Organization/SampleSubmissionList";
import SubmissionRemoteTransferUpdate from "./Components/SubmissionRemoteTransfer/Update";
import SubmissionRemoteTransferDetail from "./Components/SubmissionRemoteTransfer/Detail";
import SubmissionRemoteTransferList from "./Components/SubmissionRemoteTransfer/List";
import OrganizationUpdate from "./Components/Organization/Update";
import OrganizationDetail from "./Components/Organization/Detail";
import SubmissionLogList from "./Components/Submission/Log/List";
import SubmissionBrowserMetrics from "./Components/Submission/BrowserMetrics";
import SubmissionDelete from "./Components/Submission/Delete";
import SubmissionDetail from "./Components/Submission/Detail";
import SubmissionList from "./Components/Submission/List";
import ShareableLinkInfo from "./Components/Evaluation/ShareableLinkInfo";
import EvaluationQRCode from "./Components/Evaluation/QRCode";
import EvaluationUpdate from "./Components/Evaluation/Update";
import EvaluationImport from "./Components/Evaluation/Import";
import EvaluationExport from "./Components/Evaluation/Export";
import EvaluationDetail from "./Components/Evaluation/Detail";
import EvaluationCreate from "./Components/Evaluation/Create";
import EvaluationArchivedList from "./Components/Evaluation/ArchivedList";
import EvaluationUnarchive from "./Components/Evaluation/Unarchive";
import EvaluationList from "./Components/Evaluation/List";
import EvaluationArchive from "./Components/Evaluation/Archive";
import EvaluationDelete from "./Components/Evaluation/Delete";
import EvaluationAnalysis from "./Components/Evaluation/Analysis";
import UserUpdate from "./Components/User/Update";
import UserDetail from "./Components/User/Detail";
import UserCreate from "./Components/User/Create";
import UserList from "./Components/User/List";
import Dashboard from "./Components/Dashboard/Dashboard";
import LogoutRedirector from "./Components/Gateway/LogoutRedirector";
import RegisterComplete from "./Components/Gateway/RegisterComplete";
import Register from "./Components/Gateway/Register";
import Login from "./Components/Gateway/Login";
import Index from "./Components/Gateway/Index";
import TopNavigation from "./Components/Misc/TopNavigation";
import NotFoundError from "./Components/Misc/NotFoundError";

class AppRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <>
                <Router>
                    <div>
                        <TopNavigation />
                    </div>
                    <Routes>
                        <Route exact path="/profile/update" element={<ProfileUpdate />}/>
                        <Route exact path="/profile" element={<ProfileDetail/>}/>
                        <Route exact path="/organization/sample-submissions" element={<SampleSubmissionList/>}/>
                        <Route exact path="/organization/remote-payload-info" element={<RemotePayloadInfo/>}/>
                        <Route exact path="/organization/update" element={<OrganizationUpdate/>}/>
                        <Route exact path="/transfer/:id/update" element={<SubmissionRemoteTransferUpdate/>}/>
                        <Route exact path="/transfer/:id" element={<SubmissionRemoteTransferDetail/>}/>
                        <Route exact path="/transfers" element={<SubmissionRemoteTransferList/>}/>
                        <Route exact path="/organization" element={<OrganizationDetail/>}/>
                        <Route exact path="/submission/logs" element={<SubmissionLogList/>}/>
                        <Route exact path="/submission/:id/metrics" element={<SubmissionBrowserMetrics/>}/>
                        <Route exact path="/submission/:id/delete" element={<SubmissionDelete/>}/>
                        <Route exact path="/submission/:id" element={<SubmissionDetail/>}/>
                        <Route exact path="/submissions" element={<SubmissionList/>}/>
                        <Route exact path="/evaluation/:id/analysis" element={<EvaluationAnalysis/>}/>
                        <Route exact path="/evaluation/:id/qrcode" element={<EvaluationQRCode/>}/>
                        <Route exact path="/evaluation/:id/export" element={<EvaluationExport/>}/>
                        <Route exact path="/evaluation/:id/unarchive" element={<EvaluationUnarchive/>}/>
                        <Route exact path="/evaluation/:id/delete" element={<EvaluationDelete/>}/>
                        <Route exact path="/evaluation/:id/archive" element={<EvaluationArchive/>}/>
                        <Route exact path="/evaluation/:id/shareable-link-info" element={<ShareableLinkInfo/>}/>
                        <Route exact path="/evaluation/:id/update" element={<EvaluationUpdate/>}/>
                        <Route exact path="/evaluation/:id" element={<EvaluationDetail/>}/>
                        <Route exact path="/evaluations/add" element={<EvaluationCreate/>}/>
                        <Route exact path="/evaluations/archived" element={<EvaluationArchivedList/>}/>
                        <Route exact path="/evaluations/import" element={<EvaluationImport/>}/>
                        <Route exact path="/evaluations" element={<EvaluationList/>}/>
                        <Route exact path="/user/:id/update" element={<UserUpdate/>}/>
                        <Route exact path="/user/:id" element={<UserDetail/>}/>
                        <Route exact path="/users/add" element={<UserCreate/>}/>
                        <Route exact path="/users" element={<UserList/>}/>
                        <Route exact path="/dashboard" element={<Dashboard/>}/>
                        <Route exact path="/register-complete" element={<RegisterComplete/>}/>
                        <Route exact path="/register" element={<Register/>}/>
                        <Route exact path="/login" element={<Login/>}/>
                        <Route exact path="/logout" element={<LogoutRedirector/>}/>
                        <Route exact path="/" element={<Index/>}/>
                        <Route path="*" element={<NotFoundError/>}/>
                    </Routes>
                    <div>
                        {/* DEVELOPERS NOTE: Mobile tab-bar menu can go here */}
                    </div>
                </Router>
            </>
        );
    }
}

export default AppRoute;
