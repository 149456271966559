const HTTP_API_SERVER =  process.env.REACT_APP_API_PROTOCOL + "://" + process.env.REACT_APP_API_DOMAIN;
export const DIGITALSCANTRON_API_BASE_PATH = "/api/v1";
export const DIGITALSCANTRON_LOGIN_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/login";
export const DIGITALSCANTRON_REGISTER_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/register";
export const DIGITALSCANTRON_REFRESH_TOKEN_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/refresh-token";
export const DIGITALSCANTRON_EVALUATION_LIST_CREATE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/evaluations";
export const DIGITALSCANTRON_EVALUATION_DETAIL_UPDATE_DELETE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/evaluation/xxx";
export const DIGITALSCANTRON_EVALUATION_ARCHIVE_OR_UNARCHIVE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/evaluation/xxx/archive-or-unarchive";
export const DIGITALSCANTRON_EVALUATION_EXPORT_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/evaluation-export";
export const DIGITALSCANTRON_EVALUATION_IMPORT_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/evaluation-import";
export const DIGITALSCANTRON_EVALUATION_ANALYSIS_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/evaluation/xxx/analysis";
export const DIGITALSCANTRON_SUBMISSION_BROWSER_METRICS_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/submission-browser-metric/xxx";
export const DIGITALSCANTRON_SUBMISSION_LIST_CREATE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/submissions";
export const DIGITALSCANTRON_SUBMISSION_DETAIL_UPDATE_DELETE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/submission/xxx";
export const DIGITALSCANTRON_SUBMISSION_LOG_LIST_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/submission-logs";
export const DIGITALSCANTRON_DASHBOARD_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/dashboard";
export const DIGITALSCANTRON_RECEIPT_SUBMISSION_OPERATION_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/receipt";
export const DIGITALSCANTRON_ORGANIZATION_DETAIL_UPDATE_DELETE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/tenant/xxx";
export const DIGITALSCANTRON_USER_LIST_CREATE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/users";
export const DIGITALSCANTRON_USER_DETAIL_UPDATE_DELETE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/user/xxx";
export const DIGITALSCANTRON_USER_PROFILE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/profile";
export const DIGITALSCANTRON_SUBMISSION_REMOTE_TRANSFER_LIST_CREATE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/submission-remote-transfers";
export const DIGITALSCANTRON_SUBMISSION_REMOTE_TRANSFER_DETAIL_UPDATE_DELETE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/submission-remote-transfer/xxx";
export const DIGITALSCANTRON_SAMPLE_SUBMISSION_CREATE_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/sample-submit";
export const DIGITALSCANTRON_SAMPLE_SUBMISSION_LIST_API_ENDPOINT = HTTP_API_SERVER + "/api/v1/sample-submissions";
