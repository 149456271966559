import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Scroll from 'react-scroll';
import { isEmpty } from 'lodash';
import { useTabIndex } from 'react-tabindex';
import { getOrganizationDetailAPI } from "../../API/organization";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { ErrorBox } from "../Element/ErrorBox";

function OrganizationDetail() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [tenant, setTenant] = useState({});
    const [profile] = useLocalStorage("DIGITALSCANTRON_PROFILE");
    const [name, setName] = useLocalStorage("DST_T_NAME", "");
    const [hasRemoteSubmissions, setHasRemoteSubmissions] = useLocalStorage("DST_T_HAS_REMOTE_SUBMISSIONS", "");
    const [remoteSubmissionURL, setRemoteSubmissionURL] = useLocalStorage("DST_T_REMOTE_SUBMISSION_URL", "");
    const [appTitle, setAppTitle] = useLocalStorage("DST_T_APP_TITLE", "");
    const [appImageURL, setAppImageURL] = useLocalStorage("DST_T_APP_IMAGE_URL", "");
    const [appColour, setAppColour] = useLocalStorage("DST_T_APP_COLOUR", "");
    const [errors, setErrors] = useState({});

    // --- API --- //
    const [isLoading, setIsLoading] = useState(true);
    const id = profile.tenantId;

    useEffect(() => {
        document.title = "Organization Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        function onDetailSuccess(responseData) {
            console.log(responseData);
            setName(responseData.name);
            setHasRemoteSubmissions(responseData.hasRemoteSubmissions);
            setRemoteSubmissionURL(responseData.remoteSubmissionURL);
            setAppTitle(responseData.appTitle);
            setAppImageURL(responseData.appImageURL);
            setAppColour(responseData.appColour);
            responseData.appImageURL = responseData.appImageUrl;
            setTenant(responseData);
            setErrors({});
        }

        let mounted = true;

        if (isEmpty(tenant)) {
            getOrganizationDetailAPI(id, (responseData) => { // A.k.a. "onSuccess"
                if (mounted) {
                    setTenant(responseData);
                    onDetailSuccess(responseData);
                }
            }, (apiErrors) => { // A.k.a. "onError"
                setErrors(apiErrors);

                // The following code will cause the screen to scroll to the top of
                // the page. Please see ``react-scroll`` for more information:
                // https://github.com/fisshy/react-scroll
                var scroll = Scroll.animateScroll;
                scroll.scrollToTop();
            }, () => {  // A.k.a. "onDone"
                setIsLoading(false);
            });
        }

        return () => mounted = false;
    },[
        id, setAppColour, setAppImageURL, setAppTitle, setHasRemoteSubmissions, setName, setRemoteSubmissionURL, tenant
    ]);

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to="/dashboard"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-building" aria-hidden="true"></i>&nbsp;Organization</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-building" aria-hidden="true"></i>&nbsp;Organization</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        <div className="w3-dropdown-hover w3-right">
                                            <button className="w3-button w3-light-grey">
                                                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                            </button>
                                            <div className="w3-dropdown-content w3-bar-block w3-border" style={{right:0}}>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/organization/update`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Update
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>

                            {errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }

                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container" id={tenant && tenant.id}>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Name</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="name" value={name} />

                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Has Remote Submissions</strong>
                                        </label>
                                        <input className={`w3-check`} checked={hasRemoteSubmissions ? "checked" : ""} disabled={true} type="checkbox" name="hasRemoteSubmissions" />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Remote Submission URL</strong>
                                        </label>
                                        <input className={`w3-col m8 w3-input`} disabled={true} type="text" name="remoteSubmissionURL" value={remoteSubmissionURL} />
                                        <p className="w3-col w3-text-grey">FOR MORE INFORMATION HOW THE PAYLOAD, <Link to={`/organization/remote-payload-info`}>CLICK HERE&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></Link></p>
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>App Title</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="appTitle" value={appTitle} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>App Image URL</strong>
                                        </label>
                                        <input className={`w3-col m12 w3-input`} disabled={true} type="text" name="appImageURL" value={appImageURL} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>App Colour</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input`} disabled={true} type="text" name="appColour" value={appColour} />
                                    </p>
                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        {/*
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to="/evaluations"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        */}
                                        <span className="w3-col m6">&nbsp;</span>
                                        <Link className="w3-col m3 w3-btn w3-blue w3-round-xlarge w3-right"
                                                     to={`/organization/update`}
                                                   type="button"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;UPDATE
                                        </Link>
                                    </p>
                                </form>
                            }

                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default OrganizationDetail;
