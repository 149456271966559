import React, { useState, useEffect } from "react";
import { useTabIndex } from 'react-tabindex';
import Scroll from 'react-scroll';
import { ErrorBox } from "../Element/ErrorBox";
import { Link, Navigate, useLocation } from "react-router-dom";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { putOrganizationAPI } from "../../API/organization";
import { QuestionRow } from "../Element/QuestionRowChoiceCol";
import { localStorageGetBooleanOrNullItem } from "../../Helpers/localStorageUtility";


function TenantUpdate() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [profile] = useLocalStorage("DIGITALSCANTRON_PROFILE");
    const [tenant, setTenant] = useLocalStorage("DIGITALSCANTRON_TENANT");
    const [name, setName] = useLocalStorage("DST_T_NAME", "");
    const [hasRemoteSubmissions, setHasRemoteSubmissions] = useState(localStorageGetBooleanOrNullItem("DST_T_HAS_REMOTE_SUBMISSIONS"));
    const [remoteSubmissionURL, setRemoteSubmissionURL] = useLocalStorage("DST_T_REMOTE_SUBMISSION_URL", "");
    const [appTitle, setAppTitle] = useLocalStorage("DST_T_APP_TITLE", "");
    const [appImageURL, setAppImageURL] = useLocalStorage("DST_T_APP_IMAGE_URL", "");
    const [appColour, setAppColour] = useLocalStorage("DST_T_APP_COLOUR", "");
    const [forceUrl, setForceUrl] = useState("");
    const [errors, setErrors] = useState({});

    // --- API --- //
    const [isLoading, setIsLoading] = useState(false);
    const id = profile.tenantId;

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Update Organization";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        if (remoteSubmissionURL === undefined || remoteSubmissionURL === null || remoteSubmissionURL === "undefined") { // BUGFIX
            setRemoteSubmissionURL("");
        }
    },[]);

    //
    // COMPONENT FUNCTIONS
    //

    // --- Form ---

    function onNameChange(e) {
        setName(e.target.value);
    }

    function onHasRemoteSubmissionsChange(e) {
        setHasRemoteSubmissions(e.target.checked);
    }

    function onRemoteSubmissionURLChange(e) {
        setRemoteSubmissionURL(e.target.value);
    }

    function onAppTitleChange(e) {
        setAppTitle(e.target.value);
    }

    function onAppImageURLChange(e) {
        setAppImageURL(e.target.value);
    }

    function onAppColourChange(e) {
        setAppColour(e.target.value);
    }

    function onFormSubmitClick(e) {
        setIsLoading(true);
        const putData = {
            name: name,
            hasRemoteSubmissions: hasRemoteSubmissions,
            remoteSubmissionURL: remoteSubmissionURL,
            appTitle: appTitle,
            appImageURL: appImageURL,
            appColour: appColour,
        };
        putOrganizationAPI(id, putData, onCreateSuccess, onCreateError, onCreateDone);
    }

    //
    // API
    //

    function onCreateSuccess(tenant) {
        setTenant(tenant);
        setForceUrl("/organization");
    }

    function onCreateError(err) {
        console.log("Tenant Update: Detected error.");
        setErrors(err);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onCreateDone() {
        setIsLoading(false);
    }

    //
    // MISC
    //

    //
    // COMPONENT RENDER
    //

    if (forceUrl !== "") {
        return <Navigate to={forceUrl} />;
    }

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/organization`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-container">
                                <h2><strong><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Update Organization</strong></h2>
                                <hr className="w3-grey" />
                            </div>
                            {isLoading
                                ? <>
                                sending ...
                                </>
                                : <form className="w3-container">
                                    <ErrorBox errors={errors} />
                                    <p>Please fill in all the required fields (*).</p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Name (*)</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input w3-border ${errors.name ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="name"
                                                   value={name}
                                                onChange={onNameChange}
                                             placeholder={"Please fill name."}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.name && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.name}</strong>}
                                        </label>}
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Has Remote Submissions</strong>
                                        </label>
                                        <input className={`w3-check`}
                                                 checked={hasRemoteSubmissions ? "checked" : ""}
                                                    type="checkbox"
                                                    name="hasRemoteSubmissions"
                                                onChange={onHasRemoteSubmissionsChange}
                                        />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Remote Submission URL</strong>
                                        </label>
                                        <input className={`w3-col m8 w3-input w3-border ${errors.remoteSubmissionURL ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="remoteSubmissionURL"
                                                   value={remoteSubmissionURL}
                                                onChange={onRemoteSubmissionURLChange}
                                             placeholder={""}
                                                disabled={hasRemoteSubmissions === false}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.remoteSubmissionURL && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.remoteSubmissionURL}</strong>}
                                        </label>}
                                        <p className="w3-col w3-text-grey">FOR MORE INFORMATION HOW THE PAYLOAD, <Link to={`/organization/remote-payload-info`}>CLICK HERE&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></Link></p>
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>App Title</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input w3-border ${errors.appTitle ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="appTitle"
                                                   value={appTitle}
                                                onChange={onAppTitleChange}
                                             placeholder={""}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.appTitle && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.appTitle}</strong>}
                                        </label>}
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>App Image URL</strong>
                                        </label>
                                        <input className={`w3-col m12 w3-input w3-border ${errors.appImageURL ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="appImageURL"
                                                   value={appImageURL}
                                                onChange={onAppImageURLChange}
                                             placeholder={""}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.appImageURL && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.appImageURL}</strong>}
                                        </label>}
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>App Colour</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-border ${errors.appColour ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="appColour"
                                                   value={appColour}
                                                onChange={onAppColourChange}
                                             placeholder={""}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.appColour && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.appColour}</strong>}
                                        </label>}

                                    </p>
                                    <p>The available colour options can be found <a href="https://www.w3schools.com/w3css/w3css_colors.asp" target="_blank">here</a>&nbsp;<i className="fa fa-external-link" aria-hidden="true"></i>.</p>
                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-red w3-round-xlarge w3-left"
                                                     to={`/organization`}
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-times-circle" aria-hidden="true"></i>&nbsp;CANCEL
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <button className="w3-col m3 w3-btn w3-green w3-round-xlarge w3-right"
                                                  onClick={onFormSubmitClick}
                                                     type="button"
                                                 tabIndex={tabIndex}>
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;SAVE
                                        </button>
                                    </p>
                                </form>
                            }
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default TenantUpdate;
