import React, { useState, useEffect } from "react";
import { useTabIndex } from 'react-tabindex';
import Scroll from 'react-scroll';
import { ErrorBox } from "../Element/ErrorBox";
import { Link, Navigate } from "react-router-dom";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { putOrganizationAPI } from "../../API/organization";


function ProfileUpdate() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [profile] = useLocalStorage("DIGITALSCANTRON_PROFILE");
    const [firstName, setFirstName] = useLocalStorage("DST_U_FIRST_NAME", "");
    const [lastName, setLastName] = useLocalStorage("DST_U_LAST_NAME", "");
    const [email, setEmail] = useLocalStorage("DST_U_EMAIL", "");
    // const [language, setLanguage] = useLocalStorage("DST_U_LANGUAGE", "");
    // const [timezone, setTimezone] = useLocalStorage("DST_U_TIMEZONE", "");
    const [forceUrl, setForceUrl] = useState("");
    const [errors, setErrors] = useState({});

    // --- API --- //
    const [isLoading, setIsLoading] = useState(false);
    const id = profile.tenantId;

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Update Organization";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    //
    // COMPONENT FUNCTIONS
    //

    // --- Form ---

    function onFirstNameChange(e) {
        setFirstName(e.target.value);
    }

    function onLastNameChange(e) {
        setLastName(e.target.value);
    }

    function onEmailChange(e) {
        setEmail(e.target.value);
    }

    function onFormSubmitClick(e) {
        setIsLoading(true);
        const putData = {
            firstName: firstName,
        };
        putOrganizationAPI(id, putData, onCreateSuccess, onCreateError, onCreateDone);
    }

    //
    // API
    //

    function onCreateSuccess(tenant) {
        setForceUrl("/tenant/"+id);
    }

    function onCreateError(err) {
        setErrors(err);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onCreateDone() {
        setIsLoading(false);
    }

    //
    // MISC
    //

    //
    // COMPONENT RENDER
    //

    if (forceUrl !== "") {
        return <Navigate to={forceUrl} />;
    }

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/profile`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-container">
                                <h2><strong><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Update Profile</strong></h2>
                                <hr className="w3-grey" />
                            </div>
                            {isLoading
                                ? <>
                                sending ...
                                </>
                                : <form className="w3-container">
                                    <ErrorBox errors={errors} />
                                    <p>Please fill in all the required fields (*).</p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>First Name (*)</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input w3-border ${errors.firstName ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="firstName"
                                                   value={firstName}
                                                onChange={onFirstNameChange}
                                             placeholder={"Please fill first name."}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.firstName && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.firstName}</strong>}
                                        </label>}
                                    </p>

                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Last Name (*)</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input w3-border ${errors.lastName ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="lastName"
                                                   value={lastName}
                                                onChange={onLastNameChange}
                                             placeholder={"Please fill last name."}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.lastName && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.lastName}</strong>}
                                        </label>}
                                    </p>

                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Email (*)</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input w3-border ${errors.email ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="email"
                                                   value={email}
                                                onChange={onEmailChange}
                                             placeholder={"Please fill last name."}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.email && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.email}</strong>}
                                        </label>}
                                    </p>

                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-red w3-round-xlarge w3-left"
                                                     to={`/profile`}
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-times-circle" aria-hidden="true"></i>&nbsp;CANCEL
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <button className="w3-col m3 w3-btn w3-green w3-round-xlarge w3-right"
                                                  onClick={onFormSubmitClick}
                                                     type="button"
                                                 tabIndex={tabIndex}>
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;SAVE
                                        </button>
                                    </p>
                                </form>
                            }
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default ProfileUpdate;
