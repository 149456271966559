import React, { useState } from "react";
import Select from 'react-select'
import Scroll from 'react-scroll';
import { useTabIndex } from 'react-tabindex';
import { Navigate } from "react-router-dom";
import { ErrorBox } from "../Element/ErrorBox";
import { isEmpty } from 'lodash';
import { postRegisterAPI } from "../../API/gateway";
import { LANGUAGE_FIELD_OPTIONS } from "../../Constants/FieldOptions";


function Register() {
    //
    // Component state.
    //

    const tabIndex = useTabIndex();
    const [forceUrl, setForceUrl] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [languageOption, setLanguageOption] = useState({});
    const [errors, setErrors] = useState({});

    //
    // Event handling.
    //

    function onFirstNameChange(e) {
        setFirstName(e.target.value);
    }

    function onLastNameChange(e) {
        setLastName(e.target.value);
    }

    function onEmailChange(e) {
        setEmail(e.target.value);
    }

    function onPasswordChange(e) {
        setPassword(e.target.value);
    }

    function onOrganizationNameChange(e) {
        setOrganizationName(e.target.value);
    }

    function onLanguageSelectChange(selectedOption) {
        setLanguageOption(selectedOption);
    }

    function onClick(e) {
        const postData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            password_repeat: password,
            agree_tos: true,
            organization_name: organizationName,
            language: isEmpty(languageOption) ? "" : languageOption.value,
        };
        postRegisterAPI(postData, onSuccess, onError, onDone);
    }

    //
    // API.
    //

    function onSuccess(success){
        console.log(success);
        setForceUrl("/register-complete");
    }

    function onError(apiErrors) {
        console.log(apiErrors);
        const apiErrorsJSON = JSON.stringify(apiErrors);
        if (apiErrorsJSON.includes("Organization already exists")) {
            setErrors({
                organizationName: "Organization already exists",
            });
        } else if (apiErrorsJSON.includes("Email already exists")) {
            setErrors({
                email: "Email already exists",
            });
        } else {
            setErrors(apiErrors);
        }

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onDone() {}

    //
    // Component Rendering.
    //

    if (forceUrl !== "") {
        return <Navigate to={forceUrl} />;
    }

    return (
        <>
            <div className="w3-row">
                <div className="w3-col m3 l4 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m6 l4 w3-container">
                    <div className="w3-white w3-card-4 w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-container w3-center">
                            <h2><strong>Member Registration</strong></h2>
                        </div>
                        <form className="w3-container" autoComplete="off">
                            <ErrorBox errors={errors} />
                            <p>Please fill in all the required fields (*).</p>
                            <p className="w3-row">
                                <label className="w3-col">
                                    <strong className={`${errors.firstName ? 'w3-text-red' : ''}`}>First Name (*)</strong>
                                </label>
                                <input className={`w3-col m7 w3-input w3-border ${errors.firstName ? 'w3-border-red' : ''}`}
                                            type="text"
                                           value={firstName}
                                        onChange={onFirstNameChange}
                                     placeholder="Enter first name"
                                        tabIndex={tabIndex}
                                />
                                {errors.firstName && <label className="w3-col m7 w3-text-red">{errors.firstName}</label>}
                            </p>
                            <p className="w3-row">
                                <label className="w3-col">
                                    <strong className={`${errors.lastName ? 'w3-text-red' : ''}`}>Last Name (*)</strong>
                                </label>
                                <input className={`w3-col m6 w3-input w3-border ${errors.lastName ? 'w3-border-red' : ''}`}
                                            type="text"
                                           value={lastName}
                                        onChange={onLastNameChange}
                                     placeholder="Enter last name"
                                        tabIndex={tabIndex}
                                />
                                {errors.lastName && <label className="w3-col m7 w3-text-red">{errors.lastName}</label>}
                            </p>
                            <p className="w3-row">
                                <label className="w3-col">
                                    <strong className={`${errors.email ? 'w3-text-red' : ''}`}>Email (*)</strong>
                                </label>
                                <input className={`w3-col m9 w3-input w3-border ${errors.email ? 'w3-border-red' : ''}`}
                                            type="text"
                                           value={email}
                                        onChange={onEmailChange}
                                     placeholder="Enter email"
                                        tabIndex={tabIndex}
                                />
                                {errors.email && <label className="w3-col m7 w3-text-red">{errors.email}</label>}
                                <label className="w3-col w3-text-grey w3-tiny">MUST BE A UNIQUE EMAIL IN OUR SYSTEM</label>
                            </p>
                            <p className="w3-row">
                                <label className="w3-col">
                                    <strong className={`${errors.password ? 'w3-text-red' : ''}`}>Password (*)</strong>
                                </label>
                                <input className={`w3-col m6 w3-input w3-border ${errors.password ? 'w3-border-red' : ''}`}
                                            type="password"
                                           value={password}
                                        onChange={onPasswordChange}
                                     placeholder="Enter password"
                                        tabIndex={tabIndex}
                                />
                                {errors.password && <label className="w3-col m7 w3-text-red">{errors.password}</label>}
                            </p>
                            <p className="w3-row">
                                <label className="w3-col">
                                    <strong className={`${errors.organizationName ? 'w3-text-red' : ''}`}>Organization Name (*)</strong>
                                </label>
                                <input className={`w3-col m9 w3-input w3-border ${errors.organizationName ? 'w3-border-red' : ''}`}
                                            type="text"
                                           value={organizationName}
                                        onChange={onOrganizationNameChange}
                                     placeholder="Enter organization name"
                                        tabIndex={tabIndex}
                                />
                                {errors.organizationName && <label className="w3-col m7 w3-text-red">{errors.organizationName}</label>}
                                <label className="w3-col w3-text-grey w3-tiny">THE NAME OF THE ORGANIZATION YOU ARE REPRESENTING</label>
                            </p>
                            <p className="w3-row">
                                <label className="w3-col">
                                    <strong className={`${errors.language ? 'w3-text-red' : ''}`}>Language (*)</strong>
                                </label>
                                <Select className={`w3-col m9 ${errors.language ? 'w3-border w3-border-red' : ''}`}
                                        options={LANGUAGE_FIELD_OPTIONS}
                                       onChange={onLanguageSelectChange}
                                           name="language"
                                          value={languageOption}
                                    placeholder="Please select language"
                                       tabIndex={tabIndex}
                                />
                                {errors.language && <label className="w3-col m7 w3-text-red">{errors.language}</label>}
                                <label className="w3-col w3-text-grey w3-tiny">THE LANGUAGE USED THROUGHOUT THE APPLICATION</label>
                            </p>
                            <p className="w3-row">
                                <button className="w3-btn w3-blue w3-round-xlarge w3-block"
                                          onClick={onClick}
                                             type="button"
                                         tabIndex={tabIndex}>REGISTER
                                </button>
                            </p>
                            <hr />
                            <p className="w3-center">
                                Already a member? <a href="/login" tabIndex={tabIndex}>Login</a>.
                            </p>

                        </form>
                    </div>
                </div>
                <div className="w3-col m3 l4 w3-container">
                </div>

            </div>
        </>
    );
}

export default Register;
