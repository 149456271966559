import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import {
    DIGITALSCANTRON_SUBMISSION_LOG_LIST_API_ENDPOINT
} from "../Constants/API";


export function getSubmissionLogsAPI(offset=0, limit=10, filtersMap=new Map(), onSuccessCallback, onErrorCallback, onDoneCallback) {
    // Generate the URL from the map.
    // Note: Learn about `Map` iteration via https://hackernoon.com/what-you-should-know-about-es6-maps-dc66af6b9a1e
    let aURL = DIGITALSCANTRON_SUBMISSION_LOG_LIST_API_ENDPOINT+"?offset="+offset+"&limit="+limit;
    filtersMap.forEach(
        (value, key) => {
            let decamelizedkey = decamelize(key)
            aURL += "&"+decamelizedkey+"="+value;
        }
    )

    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}
