import React, { useState, useEffect } from "react";
import Scroll from 'react-scroll';
import { useTabIndex } from 'react-tabindex';
import { ErrorBox } from "../Element/ErrorBox";
import { postLoginAPI } from "../../API/gateway";
import useLocalStorage from "../../Hooks/useLocalStorage";


function Login() {
    //
    // Component States.
    //

    const tabIndex = useTabIndex();
    const [profile, setProfile] = useLocalStorage("DIGITALSCANTRON_PROFILE");
    const [tenant, setTenant] = useLocalStorage("DIGITALSCANTRON_TENANT");
    const [forceUrl, setForceUrl] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    console.log("PREVIOUS PROFILE:", profile, tenant); //TODO: Automatic redirect??

    //
    // API.
    //

    function onLoginSuccess(profile){
        profile.appImageURL = profile.appImageUrl;
        setProfile(profile);
        setTenant(profile.tenant);
        setForceUrl("/dashboard");
    }

    function onLoginError(apiErr) {
        setErrors(apiErr);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onLoginDone() {}

    //
    // Event handling.
    //

    function onClick(e) {
        const postData = {
            email: email,
            password: password,
        };
        postLoginAPI(postData, onLoginSuccess, onLoginError, onLoginDone);
    }

    function onEmailChange(e) {
        setEmail(e.target.value);
    }

    function onPasswordChange(e) {
        setPassword(e.target.value);
    }

    //
    // Misc
    //

    useEffect(() => {
        if (forceUrl !== "") {
            // Do not use `Link` but instead use the `window.location` change
            // to fix the issue with the `TopNavigation` component to restart.
            // If you use use `Link` then when you redirect to the navigation then
            // the menu will not update.
            window.location.href = forceUrl;
        }
    },[forceUrl,]);

    //
    // Component Rendering.
    //

    return (
        <>
            <div className="w3-row">
                <div className="w3-col m3 l4 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m6 l4 w3-container">
                    <div className="w3-white w3-card-4 w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-container w3-center">
                            <h2><strong>Member Login</strong></h2>
                        </div>
                        <form className="w3-container" autoComplete="off">

                            <ErrorBox errors={errors} />

                            <p>
                                <label>
                                    <strong className={`${errors.email ? 'w3-text-red' : ''}`}>Email</strong>
                                </label>
                                <input className={`w3-input w3-border ${errors.email ? 'w3-border-red' : ''}`}
                                            type="text"
                                        onChange={onEmailChange}
                                        tabIndex={tabIndex}
                                />
                                {errors.email && <label className="w3-text-red">{errors.email}</label>}
                            </p>
                            <p>
                                <label>
                                    <strong className={`${errors.password ? 'w3-text-red' : ''}`}>Password</strong>
                                </label>
                                <input className={`w3-input w3-border ${errors.password ? 'w3-border-red' : ''}`}
                                            type="password"
                                        onChange={onPasswordChange}
                                        tabIndex={tabIndex}
                                />
                                {errors.password && <label className="w3-text-red">{errors.password}</label>}
                            </p>
                            <p>
                                <button className="w3-btn w3-blue w3-round-xlarge w3-block"
                                          onClick={onClick}
                                             type="button"
                                         tabIndex={tabIndex}>LOGIN</button>
                            </p>
                            <hr />
                            <p className="w3-center">
                                Not a member yet? <a href="/register" tabIndex={tabIndex}>Register</a>.
                            </p>

                        </form>
                    </div>
                </div>
                <div className="w3-col m3 l4 w3-container">
                </div>

            </div>
        </>
    );
}

export default Login;
