import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Scroll from 'react-scroll';
import { isEmpty } from 'lodash';
// import ReactJson from 'react-json-view'; // https://github.com/mac-s-g/react-json-view/issues/403
import { useTabIndex } from 'react-tabindex';
import { getSubmissionRemoteTransferDetailAPI } from "../../API/submissionRemoteTransfer";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { ErrorBox } from "../Element/ErrorBox";


function SubmissionRemoteTransferDetail() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [submissionRemoteTransfer, setSubmissionRemoteTransfer] = useState({});
    const [uuid, setUuid] = useLocalStorage("DST_E_UUID", "");
    const [evaluationName, setEvaluationName] = useLocalStorage("DST_S_EVALUATION_NAME", "");
    const [evaluationID, setEvaluationID] = useLocalStorage("DST_S_EVALUATION_ID", "");
    const [studentName, setStudentName] = useLocalStorage("DST_S_STUDENT_NAME", "");
    const [studentCode, setStudentCode] = useLocalStorage("DST_S_STUDENT_CODE", "");
    const [schoolName, setSchoolName] = useLocalStorage("DST_S_SCHOOL_NAME", "");
    const [schoolCode, setSchoolCode] = useLocalStorage("DST_S_SCHOOL_CODE", "");
    const [submissionID, setSubmissionID] = useLocalStorage("DST_S_SUBMISSION_ID", "");
    const [content, setContent] = useLocalStorage("DST_S_CONTENT", "");
    const [success, setSuccess] = useLocalStorage("DST_S_SUCCESS", "");
    const [path, setPath] = useLocalStorage("DST_S_PATH", "");
    const [error, setError] = useLocalStorage("DST_S_ERROR", "");
    const [status, setStatus] = useLocalStorage("DST_S_STATUS", "");
    const [lastModifiedAt, setLastModifiedAt] = useLocalStorage("DST_S_LAST_MODIFIED_AT", "");
    const [errors, setErrors] = useState({});

    // --- API --- //
    const [isLoading, setIsLoading] = useState(true);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-1];

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Submission Transfer Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        function onDetailSuccess(responseData) {
            console.log("onDetailSuccess:",responseData);
            setUuid(responseData.uuid);
            setEvaluationName(responseData.evaluationName);
            setEvaluationID(responseData.evaluationID);
            setStudentName(responseData.studentName);
            setStudentCode(responseData.studentCode);
            setSchoolName(responseData.schoolName);
            setSchoolCode(responseData.schoolCode);
            setSubmissionID(responseData.submissionID);
            setPath(responseData.path);
            setContent(responseData.content);
            setSuccess(responseData.success);
            setError(responseData.error);
            setStatus(responseData.state);
            setLastModifiedAt(responseData.lastModifiedAt);
            setErrors({});
        }

        let mounted = true;

        if (isEmpty(submissionRemoteTransfer)) {
            getSubmissionRemoteTransferDetailAPI(id, (responseData) => { // A.k.a. "onSuccess"
                if (mounted) {
                    setSubmissionRemoteTransfer(responseData);
                    onDetailSuccess(responseData);
                }
            }, (apiErrors) => { // A.k.a. "onError"
                setErrors(apiErrors);

                // The following code will cause the screen to scroll to the top of
                // the page. Please see ``react-scroll`` for more information:
                // https://github.com/fisshy/react-scroll
                var scroll = Scroll.animateScroll;
                scroll.scrollToTop();
            }, () => {  // A.k.a. "onDone"
                setIsLoading(false);
            });
        }

        return () => mounted = false;
    },[
        id, setContent, setError, setEvaluationID, setEvaluationName, setLastModifiedAt, setPath, setSchoolCode, setSchoolName, setStatus, setStudentCode, setStudentName, setSubmissionID, setSuccess, setUuid, submissionRemoteTransfer
    ]);

    //
    // Custom labels.
    //

    var statusLabel = "";
    if (status === 1) {
        statusLabel = "On Hold"
    } else if (status === 2) {
        statusLabel = "Queued"
    } else if (status === 3) {
        statusLabel = "Completed"
    } else if (status === 4) {
        statusLabel = "Error"
    } else if (status === 5) {
        statusLabel = "Not Implemented"
    }

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to="/transfers"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-exchange" aria-hidden="true"></i>&nbsp;Transfer</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-exchange" aria-hidden="true"></i>&nbsp;Transfer</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        <div className="w3-dropdown-hover w3-right">
                                            <button className="w3-button w3-light-grey">
                                                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                            </button>
                                            <div className="w3-dropdown-content w3-bar-block w3-border" style={{right:0}}>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/transfer/${id}/update`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Update
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>


                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}
                            {errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container" key={uuid}>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>UUID</strong>
                                        </label>
                                        <input className={`w3-col m6 w3-input w3-small`}
                                                disabled={true}
                                                    type="text"
                                                    name="evaluationUUID"
                                                   value={uuid} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Evaluation Name</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="evaluationName"
                                                   value={evaluationName} />
                                        <label className="w3-col">
                                           <Link to={`/evaluation/${evaluationID}`} className="w3-text-grey w3-small" tabIndex={tabIndex}>VIEW RECORD&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                        </label>
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Student Name</strong>
                                        </label>
                                        <input className={`w3-col m7 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="studentName"
                                                   value={studentName} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Student Code</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="studentCode"
                                                   value={studentCode} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>School Name</strong>
                                        </label>
                                        <input className={`w3-col m7 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="schoolName"
                                                   value={schoolName} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>School Code</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="schoolCode"
                                                   value={schoolCode} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Submission ID</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="submissionID"
                                                   value={submissionID} />
                                        <label className="w3-col">
                                           <Link to={`/submission/${submissionID}`} className="w3-text-grey w3-small" tabIndex={tabIndex}>VIEW RECORD&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                        </label>
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Path</strong>
                                        </label>
                                        <input className={`w3-col m9 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="path"
                                                   value={path} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Last Modified At</strong>
                                        </label>
                                        <input className={`w3-col m9 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="lastModifiedAt"
                                                   value={lastModifiedAt} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Status</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="status"
                                                   value={statusLabel} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Success</strong>
                                        </label>
                                        <textarea className={`w3-col w3-input w3-text-red`}
                                                disabled={true}
                                                    type="text"
                                                    name="success"
                                                   value={success} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Error</strong>
                                        </label>
                                        <textarea className={`w3-col w3-input w3-text-red`}
                                                disabled={true}
                                                    type="text"
                                                    name="error"
                                                   value={error} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Content</strong>
                                        </label>
                                        <div className="w3-col">
                                           <div className="w3-responsive">
                                                {/* {content && <ReactJson src={JSON.parse(content)} displayDataTypes={false} />} */}
                                                <div>{content}</div>
                                           </div>
                                       </div>
                                    </p>

                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to="/transfers"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <Link className="w3-col m3 w3-btn w3-blue w3-round-xlarge w3-right"
                                                     to={`/transfer/${id}/update`}
                                                   type="button"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;UPDATE
                                        </Link>
                                    </p>
                                </form>
                            }

                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default SubmissionRemoteTransferDetail;
