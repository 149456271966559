import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import {
    DIGITALSCANTRON_ORGANIZATION_DETAIL_UPDATE_DELETE_API_ENDPOINT
} from "../Constants/API";

export function getOrganizationDetailAPI(tenantID, onSuccessCallback, onErrorCallback, onDoneCallback) {
    let aURL = DIGITALSCANTRON_ORGANIZATION_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", tenantID);
    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // BUGFIX
        data.imageURL = data.imageUrl;
        data.remoteSubmissionURL = data.remoteSubmissionUrl;
        if (data.remoteSubmissionURL === "\"\"") {
            data.remoteSubmissionURL = "";
        }
        data.appImageURL = data.appImageUrl;

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function putOrganizationAPI(tenantID, data, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    let aURL = DIGITALSCANTRON_ORGANIZATION_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", tenantID);

    // To Snake-case for API from camel-case in React.
    let decamelizedData = decamelizeKeys(data);

    // BUGFIX
    decamelizedData.image_url = decamelizedData.image_u_r_l;
    decamelizedData.remote_submission_url = decamelizedData.remote_submission_u_r_l;
    decamelizedData.app_image_url = decamelizedData.app_image_u_r_l;

    axios.put(aURL, decamelizedData).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // BUGFIX
        data.imageURL = data.imageUrl;
        data.remoteSubmissionURL = data.remoteSubmissionUrl;
        if (data.remoteSubmissionURL === "\"\"") {
            data.remoteSubmissionURL = "";
        }
        data.appImageURL = data.appImageUrl;

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        console.log("putOrganizationAPI: has error result from API server");
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}
