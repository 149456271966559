import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTabIndex } from 'react-tabindex';
import { getUserDetailAPI } from "../../API/user";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { ErrorBox } from "../Element/ErrorBox";
import { USER_ROLE_IDS, USER_STATES } from "../../Constants/FieldOptions";

function UserDetail() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const [errors, setErrors] = useState({});
    const tabIndex = useTabIndex();
    const [uuid, setUuid] = useLocalStorage("DST_USER_UUID", "");
    const [createdTime, setCreatedTime] = useLocalStorage("DST_USER_CREATED_TIME", "");
    const [email, setEmail] = useLocalStorage("DST_USER_EMAIL", "");
    const [firstName, setFirstName] = useLocalStorage("DST_USER_FIRST_NAME", "");
    const [imageURL, setImageURL] = useLocalStorage("DST_USER_IMAGE_URL", "");
    const [joinedTime, setJoinedTime] = useLocalStorage("DST_USER_JOINED_TIME", "");
    const [language, setLanguage] = useLocalStorage("DST_USER_LANGUAGE", "");
    const [lastName, setLastName] = useLocalStorage("DST_USER_LAST_NAME", "");
    const [lexicalName, setLexicalName] = useLocalStorage("DST_USER_LEXICAL_NAME", "");
    const [modifiedTime, setModifiedTime] = useLocalStorage("DST_USER_MODIFIED_TIME", "");
    const [name, setName] = useLocalStorage("DST_USER_NAME", "");
    const [roleId, setRoleId] = useLocalStorage("DST_USER_ROLE_ID", "");
    const [status, setStatus] = useLocalStorage("DST_USER_STATUS", "");
    const [tenantId, setTenantId] = useLocalStorage("DST_USER_TENANT_ID", "");
    const [timezone, setTimezone] = useLocalStorage("DST_USER_TIMEZONE", "");

    // --- API --- //
    const [isLoading, setIsLoading] = useState(true);
    const [isFetched, setIsFetched] = useState(false);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-1];

    useEffect(() => {
        document.title = "User Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    //
    // API
    //

    if (isFetched === false) {
        getUserDetailAPI(id, onDetailSuccess, onDetailError, onDetailDone);
        setIsFetched(true);
    }

    //
    // COMPONENT FUNCTIONS
    //


    //
    // API Handling
    //

    function onDetailSuccess(responseData) {
        console.log(responseData);
        setUuid(responseData.uuid);
        setName(responseData.name);
        setCreatedTime(responseData.createdTime);
        setEmail(responseData.email);
        setFirstName(responseData.firstName);
        setImageURL(responseData.imageURL);
        setJoinedTime(responseData.joinedTime);
        setLanguage(responseData.language);
        setLastName(responseData.lastName);
        setLexicalName(responseData.lexicalName);
        setModifiedTime(responseData.modifiedTime);
        setName(responseData.name);
        setRoleId(responseData.roleId);
        setStatus(responseData.state);
        setTenantId(responseData.tenantId);
        setTimezone(responseData.timezone);
        setErrors({});
    }

    function onDetailError(apiErrors) {
        setErrors(apiErrors);
    }

    function onDetailDone(){
        setIsLoading(false);
    }

    //
    // MISC
    //



    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to="/users"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-container">
                                <h2><strong><i className="fa fa-users" aria-hidden="true"></i>&nbsp;User</strong></h2>
                                <hr className="w3-grey" />
                            </div>
                            {errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container">
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Name</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="name" value={name} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>UUID</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="uuid" value={uuid} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Created Time</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="createdTime" value={createdTime} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Email</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="email" value={email} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Language</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="language" value={language} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Role</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="role" value={roleId && USER_ROLE_IDS[roleId]} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Status</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="status" value={status && USER_STATES[status]} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Last modified time</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="modifiedTime" value={modifiedTime} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Timezone</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="timezone" value={timezone} />
                                    </p>

                                    {/*

                                        setModifiedTime(responseData.modifiedTime);
                                        setName(responseData.name);
                                        setRoleId(responseData.roleId);
                                        setStatus(responseData.status);
                                        setTenantId(responseData.tenantId);
                                        setTimezone(responseData.);
                                    */}





                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to="/users"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <Link className="w3-col m3 w3-btn w3-blue w3-round-xlarge w3-right"
                                                     to={`/user/${id}/update`}
                                                   type="button"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;UPDATE
                                        </Link>
                                    </p>
                                </form>
                            }

                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default UserDetail;
