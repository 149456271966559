import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import Scroll from 'react-scroll';
import { isEmpty } from 'lodash';
import { useTabIndex } from 'react-tabindex';
import { getSubmissionDetailAPI } from "../../API/submission";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { ErrorBox } from "../Element/ErrorBox";


function SubmissionDetail() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [submission, setSubmission] = useState({});
    const [uuid, setUuid] = useLocalStorage("DST_E_UUID", "");
    const [evaluationName, setEvaluationName] = useLocalStorage("DST_S_EVALUATION_NAME", "");
    const [evaluationID, setEvaluationID] = useLocalStorage("DST_S_EVALUATION_ID", "");
    const [studentName, setStudentName] = useLocalStorage("DST_S_STUDENT_NAME", "");
    const [studentCode, setStudentCode] = useLocalStorage("DST_S_STUDENT_CODE", "");
    const [schoolName, setSchoolName] = useLocalStorage("DST_S_SCHOOL_NAME", "");
    const [schoolCode, setSchoolCode] = useLocalStorage("DST_S_SCHOOL_CODE", "");
    const [startedTime, setStartedTime] = useLocalStorage("DST_S_STARTED_TIME", "");
    const [finishedTime, setFinishedTime] = useLocalStorage("DST_S_FINISHED_TIME", "");
    const [durationInMinutes, setDurationInMinutes] = useLocalStorage("DST_S_DURATION_IN_MINUTES", "");
    const [markedJSON, setMarkedJSON] = useLocalStorage("DST_S_MARKED_JSON", {});
    const [markedCorrect, setMarkedCorrect] = useLocalStorage("DST_S_MARKED_CORRECT", "");
    const [markedWrong, setMarkedWrong] = useLocalStorage("DST_S_MARKED_WRONG", "");
    const [markedTotal, setMarkedTotal] = useLocalStorage("DST_S_MARKED_TOTAL", "");
    const [markedFinal, setMarkedFinal] = useLocalStorage("DST_S_MARKED_FINAL", "");
    const [markedFailure, setMarkedFailure] = useLocalStorage("DST_S_MARKED_FAILURE", "");
    const [errors, setErrors] = useState({});

    // --- API --- //
    const [isLoading, setIsLoading] = useState(true);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-1];

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Submission Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        function onDetailSuccess(responseData) {
            console.log(responseData);
            setUuid(responseData.uuid);
            setEvaluationName(responseData.evaluationName);
            setEvaluationID(responseData.evaluationID);
            setStudentName(responseData.studentName);
            setStudentCode(responseData.studentCode);
            setSchoolName(responseData.schoolName);
            setSchoolCode(responseData.schoolCode);

            const d = new Date(responseData.startedTime);
            let text = getLocalDate(d);
            setStartedTime(text);

            const d2 = new Date(responseData.finishedTime);
            let text2 = getLocalDate(d2);
            setFinishedTime(text2);

            setDurationInMinutes(responseData.durationInMinutes);
            setMarkedJSON(responseData.markedJSON);
            setMarkedCorrect(responseData.markedCorrect);
            setMarkedWrong(responseData.markedWrong);
            setMarkedTotal(responseData.markedTotal);
            setMarkedFinal(responseData.markedFinal);
            setMarkedFailure(responseData.markedFailure);
            setErrors({});
        }

        let mounted = true;

        if (isEmpty(submission)) {
            getSubmissionDetailAPI(id, (responseData) => { // A.k.a. "onSuccess"
                if (mounted) {
                    setSubmission(responseData);
                    onDetailSuccess(responseData);
                }
            }, (apiErrors) => { // A.k.a. "onError"
                setErrors(apiErrors);

                // The following code will cause the screen to scroll to the top of
                // the page. Please see ``react-scroll`` for more information:
                // https://github.com/fisshy/react-scroll
                var scroll = Scroll.animateScroll;
                scroll.scrollToTop();
            }, () => {  // A.k.a. "onDone"
                setIsLoading(false);
            });
        }

        return () => mounted = false;
    },[
        id, setDurationInMinutes, setEvaluationID, setEvaluationName, setFinishedTime,
        setMarkedCorrect, setMarkedFailure, setMarkedFinal, setMarkedJSON,
        setMarkedTotal, setMarkedWrong, setSchoolCode, setSchoolName, setStartedTime,
        setStudentCode, setStudentName, setUuid, submission
    ]);

    //
    // API Handling
    //

    // https://stackoverflow.com/questions/85116/display-date-time-in-users-locale-format-and-time-offset
    function getLocalDate(php_date) {
        var dt = new Date(php_date);
        var minutes = dt.getTimezoneOffset();
        dt = new Date(dt.getTime() + minutes*60000);
        return dt;
    }

    // The following code will generate our CSV file which is downloadable.
    // Special thanks to: https://stackoverflow.com/questions/53504924/reactjs-download-csv-file-on-button-click
    let markedCSV = [];
    const markedEntries = Object.entries(markedJSON);
    markedCSV.push(["", "", "", "", ""]);
    markedCSV.push(["Student Name", studentName, "", "", ""]);
    markedCSV.push(["Student #", studentCode, "", "", ""]);
    markedCSV.push(["School Name", schoolName, "", "", ""]);
    markedCSV.push(["School Code", studentCode, "", "", ""]);
    markedCSV.push(["", "", "", "", ""]);
    markedCSV.push(["Question Number", "actualLetter", "expectedLetter", "isCorrect", "type"]); // Add header.
    for (let markedEntry of markedEntries) { // Iterate through all entries and add rows.
        let row = [
            markedEntry[1]["questionNumber"],
            markedEntry[1]["actualLetter"],
            markedEntry[1]["expectedLetter"],
            markedEntry[1]["isCorrect"],
            markedEntry[1]["type"],
        ]
        markedCSV.push(row)
    }

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to="/submissions"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-table" aria-hidden="true"></i>&nbsp;Submission</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-table" aria-hidden="true"></i>&nbsp;Submission</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        <div className="w3-dropdown-hover w3-right">
                                            <button className="w3-button w3-light-grey">
                                                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                            </button>
                                            <div className="w3-dropdown-content w3-bar-block w3-border" style={{right:0}}>
                                                {/*
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluation/${id}/update`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Update
                                                </Link>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluation/${id}/export`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-cloud-download" aria-hidden="true"></i>&nbsp;Export
                                                </Link>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluation/${id}/archive`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-archive" aria-hidden="true"></i>&nbsp;Archive
                                                </Link>*/}
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/submission/${id}/metrics`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-safari" aria-hidden="true"></i>&nbsp;Browser Info
                                                </Link>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/submission/${id}/delete`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>
                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}

                            {errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container" key={uuid}>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>UUID</strong>
                                        </label>
                                        <input className={`w3-col m6 w3-input w3-small`}
                                                disabled={true}
                                                    type="text"
                                                    name="evaluationUUID"
                                                   value={uuid} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Evaluation Name</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="evaluationName"
                                                   value={evaluationName} />
                                        <label className="w3-col">
                                           <Link to={`/evaluation/${evaluationID}`} className="w3-text-grey w3-small" tabIndex={tabIndex}>VIEW RECORD&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                        </label>
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Student Name</strong>
                                        </label>
                                        <input className={`w3-col m7 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="studentName"
                                                   value={studentName} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Student Code</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="studentCode"
                                                   value={studentCode} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>School Name</strong>
                                        </label>
                                        <input className={`w3-col m7 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="schoolName"
                                                   value={schoolName} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>School Code</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="schoolCode"
                                                   value={schoolCode} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Started at</strong>
                                        </label>
                                        <input className={`w3-col m9 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="startedTime"
                                                   value={startedTime} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Finished at</strong>
                                        </label>
                                        <input className={`w3-col m9 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="finishedTime"
                                                   value={finishedTime} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Duration (Minutes)</strong>
                                        </label>
                                        <input className={`w3-col m1 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="durationInMinutes"
                                                   value={durationInMinutes} />
                                    </p>
                                    {/* TABLE */}
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Multiple-Choice Answers</strong></label>

                                        <p className="w3-col">The following list is a summary of the correct answers:</p>

                                        <p className="w3-col">
                                        <table className={`w3-col m6 w3-table-all`}>
                                            <tr>
                                                <th>#</th>
                                                <th>Student Choice</th>
                                                <th>Correct Answer</th>
                                                <th>Status</th>
                                                <th>

                                                </th>
                                            </tr>
                                            {markedJSON && Object.entries(markedJSON).map(([key, value]) => (
                                                <>{value.type === 1 &&
                                                    <tr className={`${value.isCorrect ? "w3-text-green" : "w3-text-red"}`}>
                                                        <td>{value.questionNumber}</td>
                                                        <td>{value.actualLetter}</td>
                                                        <td>{value.expectedLetter}</td>
                                                        <td>
                                                        {value.isCorrect
                                                            ? <i className="fa fa-check" aria-hidden="true"></i>
                                                            : <i className="fa fa-times" aria-hidden="true"></i>
                                                        }
                                                        </td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                }
                                                </>
                                            ))}
                                        </table>
                                        {markedCSV &&
                                            <p className="w3-col w3-text-grey"><CSVLink data={markedCSV}><i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download CSV</CSVLink></p>
                                        }
                                        </p>
                                        {errors && errors.markedJSON !== undefined &&
                                            <p className="w3-col w3-text-red">{errors.markedJSON}</p>
                                        }
                                    </p>
                                    {/* END TABLE */}

                                    {/* ANOTHER TABLE */}
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Text Answers</strong></label>

                                        <p className="w3-col">The following is the long answer form submission:</p>

                                        <div className="w3-col">
                                            <div className="w3-right w3-mobile">
                                                <Link className="w3-button w3-mobile w3-blue" to={`/submission/${id}/text-answers`}>
                                                    <i class="fa fa-pencil"></i>&nbsp;
                                                    Edit Marks
                                                </Link>
                                            </div>
                                        </div>

                                        <p className="w3-col">
                                            <table className={`w3-col w3-table-all`}>
                                                <tr>
                                                    <th style={{width:"5%"}}>#</th>
                                                    <th>Answer</th>
                                                </tr>
                                                {markedJSON && Object.entries(markedJSON).map(([key, value]) => (
                                                    <>{value.type === 2 &&
                                                        <tr className="w3-text-black">
                                                            <td>{value.questionNumber}</td>
                                                            <td>
                                                                {value.actualText}
                                                                <br />
                                                                <br />
                                                                <ul>
                                                                    <li><b>Mark Given</b>: -</li>
                                                                    <li><b>Mark Out of</b>: -</li>
                                                                    <li><b>Marked By</b>: -</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    }</>
                                                ))}
                                            </table>
                                        </p>
                                        <p className="w3-col w3-text-grey">Please note, it is your responsibilty to mark the long form submission answers.</p>
                                    </p>
                                    {/* END ANOTHER TABLE */}

                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Correct Choices</strong>
                                        </label>
                                        <input className={`w3-col m1 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="markedCorrect"
                                                   value={markedCorrect} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Incorrect Choices</strong>
                                        </label>
                                        <input className={`w3-col m1 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="markedWrong"
                                                   value={markedWrong} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Total Choices</strong>
                                        </label>
                                        <input className={`w3-col m1 w3-input`}
                                                disabled={true}
                                                    type="text"
                                                    name="markedTotal"
                                                   value={markedTotal} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Final Mark</strong>
                                        </label>
                                        <input className={`w3-col m1 w3-input ${markedFailure ? 'w3-text-red' : 'w3-text-green'}`}
                                                disabled={true}
                                                    type="text"
                                                    name="markedFinal"
                                                   value={`${markedFinal} %`} />
                                    </p>
                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to="/submissions"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>

                                    </p>
                                </form>
                            }

                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}


export default SubmissionDetail;
