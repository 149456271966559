import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import {
    DIGITALSCANTRON_SUBMISSION_REMOTE_TRANSFER_LIST_CREATE_API_ENDPOINT,
    DIGITALSCANTRON_SUBMISSION_REMOTE_TRANSFER_DETAIL_UPDATE_DELETE_API_ENDPOINT
} from "../Constants/API";


export function getSubmissionRemoteTransfersAPI(offset=0, limit=10, filtersMap=new Map(), onSuccessCallback, onErrorCallback, onDoneCallback) {
    // Generate the URL from the map.
    // Note: Learn about `Map` iteration via https://hackernoon.com/what-you-should-know-about-es6-maps-dc66af6b9a1e
    let aURL = DIGITALSCANTRON_SUBMISSION_REMOTE_TRANSFER_LIST_CREATE_API_ENDPOINT+"?offset="+offset+"&limit="+limit;
    filtersMap.forEach(
        (value, key) => {
            let decamelizedkey = decamelize(key)
            aURL += "&"+decamelizedkey+"="+value;
        }
    )

    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function getSubmissionRemoteTransferDetailAPI(evaluationID, onSuccessCallback, onErrorCallback, onDoneCallback) {
    let aURL = DIGITALSCANTRON_SUBMISSION_REMOTE_TRANSFER_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", evaluationID);
    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // BUGFIX
        data.submissionID = data.submissionId;
        data.evaluationID = data.evaluationId;
        data.evaluationUUID = data.evaluationUuid;
        data.imageURL = data.imageUrl;
        data.TenantID = data.tenantId;
        data.UUID = data.uuid;

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        try {
            const responseData = exception.response.data;
            let errors = camelizeKeys(responseData);
            onErrorCallback(errors);
        } catch (e) {
            console.log("WARNING: getSubmissionRemoteTransferDetailAPI has programming error");
            onErrorCallback(e);
        }
    }).then(onDoneCallback);
}

export function putSubmissionRemoteTransferAPI(id, data, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    let aURL = DIGITALSCANTRON_SUBMISSION_REMOTE_TRANSFER_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", id);

    // To Snake-case for API from camel-case in React.
    let decamelizedData = decamelizeKeys(data);

    // BUGFIX
    // decamelizedData.image_url = decamelizedData.image_u_r_l;
    // decamelizedData.startdate = decamelizedData.start_date;
    console.log("putSubmissionRemoteTransferAPI | data:", data);

    axios.put(aURL, decamelizedData).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}
