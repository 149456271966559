import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import {
    DIGITALSCANTRON_EVALUATION_LIST_CREATE_API_ENDPOINT,
    DIGITALSCANTRON_EVALUATION_DETAIL_UPDATE_DELETE_API_ENDPOINT,
    DIGITALSCANTRON_EVALUATION_ARCHIVE_OR_UNARCHIVE_API_ENDPOINT,
    DIGITALSCANTRON_EVALUATION_IMPORT_API_ENDPOINT,
    DIGITALSCANTRON_EVALUATION_ANALYSIS_API_ENDPOINT
} from "../Constants/API";


export function postEvaluationAPI(data, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    // To Snake-case for API from camel-case in React.
    let decamelizedData = decamelizeKeys(data);

    // BUGFIX
    decamelizedData.image_url = decamelizedData.image_u_r_l;
    decamelizedData.startdate = decamelizedData.start_date;

    axios.post(DIGITALSCANTRON_EVALUATION_LIST_CREATE_API_ENDPOINT, decamelizedData).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function getEvaluationsAPI(offset=0, limit=10, filtersMap=new Map(), onSuccessCallback, onErrorCallback, onDoneCallback) {
    // Generate the URL from the map.
    // Note: Learn about `Map` iteration via https://hackernoon.com/what-you-should-know-about-es6-maps-dc66af6b9a1e
    let aURL = DIGITALSCANTRON_EVALUATION_LIST_CREATE_API_ENDPOINT+"?offset="+offset+"&limit="+limit;
    filtersMap.forEach(
        (value, key) => {
            let decamelizedkey = decamelize(key)
            aURL += "&"+decamelizedkey+"="+value;
        }
    )

    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function getEvaluationDetailAPI(evaluationID, onSuccessCallback, onErrorCallback, onDoneCallback) {
    let aURL = DIGITALSCANTRON_EVALUATION_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", evaluationID);
    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // BUGFIX
        data.imageURL = data.imageUrl;
        data.startDateTime = data.startdateTime;

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function putEvaluationAPI(evaluationID, data, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    let aURL = DIGITALSCANTRON_EVALUATION_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", evaluationID);

    // To Snake-case for API from camel-case in React.
    let decamelizedData = decamelizeKeys(data);

    // BUGFIX
    decamelizedData.image_url = decamelizedData.image_u_r_l;
    decamelizedData.startdate = decamelizedData.start_date;

    axios.put(aURL, decamelizedData).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function deleteEvaluationAPI(evaluationID, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    let aURL = DIGITALSCANTRON_EVALUATION_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", evaluationID);

    axios.delete(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function archiveOrUnarchiveEvaluationAPI(evaluationID, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    let aURL = DIGITALSCANTRON_EVALUATION_ARCHIVE_OR_UNARCHIVE_API_ENDPOINT.replace("xxx", evaluationID);

    axios.put(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function importEvaluationOperationAPI(data, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    let aURL = DIGITALSCANTRON_EVALUATION_IMPORT_API_ENDPOINT;

    // To Snake-case for API from camel-case in React.
    let decamelizedData = decamelizeKeys(data);

    axios.put(aURL, decamelizedData).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        // console.log("importEvaluationOperationAPI | exception:", exception);
        let responseData = null;
        if (exception.response !== undefined && exception.response !== null) {
            if (exception.response.data !== undefined && exception.response.data !== null) {
                responseData = exception.response.data;
            } else {
                responseData = exception.response;
            }
        } else {
            responseData = exception;
        }
        let errors = camelizeKeys(responseData);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function getEvaluationAnalysisAPI(evaluationID, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    let aURL = DIGITALSCANTRON_EVALUATION_ANALYSIS_API_ENDPOINT.replace("xxx", evaluationID);

    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}
