import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys, decamelizeKeys } from 'humps';
import {
    DIGITALSCANTRON_USER_PROFILE_API_ENDPOINT,
    DIGITALSCANTRON_USER_DETAIL_UPDATE_DELETE_API_ENDPOINT
} from "../Constants/API";

export function getProfileDetailAPI(onSuccessCallback, onErrorCallback, onDoneCallback) {
    let aURL = DIGITALSCANTRON_USER_PROFILE_API_ENDPOINT;
    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // BUGFIX
        data.imageURL = data.imageUrl;
        data.startDateTime = data.startdateTime;

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function putProfileAPI(evaluationID, data, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    let aURL = DIGITALSCANTRON_USER_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", evaluationID);

    // To Snake-case for API from camel-case in React.
    let decamelizedData = decamelizeKeys(data);

    // BUGFIX
    decamelizedData.image_url = decamelizedData.image_u_r_l;
    decamelizedData.startdate = decamelizedData.start_date;

    axios.put(aURL, decamelizedData).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}
