import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { isEmpty } from 'lodash';
import Scroll from 'react-scroll';
import { useTabIndex } from 'react-tabindex';
import { getEvaluationAnalysisAPI } from "../../API/evaluation";
import { ErrorBox } from "../Element/ErrorBox";


import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, LinearScale, CategoryScale } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
    Tooltip, Legend,                       // For all graphs.
    ArcElement,                            // For pie graph.
    BarElement, LinearScale, CategoryScale // For bar graph.
);

function EvaluationAnalysis() {
    //
    // COMPONENT STATE MANAGEMENT
    //
    const [errors, setErrors] = useState({});

    // --- Form ---

    const tabIndex = useTabIndex();
    const [analysis, setAnalysis] = useState();

    // --- API --- //
    const [isLoading, setIsLoading] = useState(true);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-2];

    useEffect(() => {
        document.title = "Evaluation Analysis";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        //
        // API
        //

        let mounted = true;

        getEvaluationAnalysisAPI(id, (responseData) => { // A.k.a. "onSuccess"
            if (mounted && isEmpty(analysis)) {
                setAnalysis(responseData);
            }
        }, (apiErrors) => { // A.k.a. "onError"
            setErrors(apiErrors);

            // The following code will cause the screen to scroll to the top of
            // the page. Please see ``react-scroll`` for more information:
            // https://github.com/fisshy/react-scroll
            var scroll = Scroll.animateScroll;
            scroll.scrollToTop();
        }, () => {  // A.k.a. "onDone"
            setIsLoading(false);
        });

        return () => mounted = false;
    },[id, analysis, setIsLoading, setAnalysis]);


    //
    // COMPONENT FUNCTIONS
    //


    //
    // COMPONENT RENDER
    //

    // DEVELOPERS NOTE:
    // Chart.js via https://react-chartjs-2.js.org/

    // --- Passed / Failed ---
    var passedCount = 0;
    var failedCount = 0;
    if (analysis) {
        passedCount = analysis.totalPasses;
        failedCount = analysis.totalFailures;
    }

    const passFailData = {
      labels: ["Failed", "Passed"],
      datasets: [
        {
          label: 'Passed / Failed Chart',
          data: [failedCount,passedCount],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(75, 192, 192, 0.2)',

          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(75, 192, 192, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    // --- On-Time / Late ---

    var onTimeCount = 0;
    var lateCount = 0;
    if (analysis) {
        onTimeCount = analysis.totalOnTime;
        lateCount = analysis.totalLate;
    }

    const onTimeLateData = {
      labels: ["Late", "On-Time"],
      datasets: [
        {
          label: 'On-Time / Late Chart',
          data: [lateCount,onTimeCount],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(75, 192, 192, 0.2)',

          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(75, 192, 192, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    // --- Browser ---

    var browserLabels = [];
    var browserValues = [];
    if (analysis && analysis.browserLabels && analysis.browserLabels.length > 0) {
        browserLabels = analysis.browserLabels;
    }
    if (analysis && analysis.browserValues && analysis.browserValues.length > 0) {
        browserValues = analysis.browserValues;
    }

    const browserData = {
      labels: browserLabels,
      datasets: [
        {
          // label: '# of Votes',
          data: browserValues,
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(255, 99, 132, 0.2)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(255, 99, 132, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    // --- OS ---

    var osLabels = [];
    var osValues = [];
    if (analysis && analysis.osLabels && analysis.osLabels.length > 0) {
        osLabels = analysis.osLabels;
    }
    if (analysis && analysis.osValues && analysis.osValues.length > 0) {
        osValues = analysis.osValues;
    }

    const osData = {
      labels: osLabels,
      datasets: [
        {
          label: '# of Votes',
          data: osValues,
          backgroundColor: [
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 99, 132, 0.2)',
          ],
          borderColor: [
            'rgba(75, 192, 192, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 99, 132, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    // --- Devices ---

    var deviceLabels = [];
    var deviceValues = [];
    if (analysis && analysis.deviceLabels && analysis.deviceLabels.length > 0) {
        deviceLabels = analysis.deviceLabels;
    }
    if (analysis && analysis.deviceValues && analysis.deviceValues.length > 0) {
        deviceValues = analysis.deviceValues;
    }

    const deviceData = {
      labels: deviceLabels,
      datasets: [
        {
          label: '# of Votes',
          data: deviceValues,
          backgroundColor: [
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
          ],
          borderColor: [
            'rgba(153, 102, 255, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 w3-container">
                    <Link className="w3-large"
                                 to={`/evaluation/${id}`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row" key={`${analysis && analysis.uuid}`}>
                <div className="w3-col m1 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-flask" aria-hidden="true"></i>&nbsp;Evaluation Analysis</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-flask" aria-hidden="true"></i>&nbsp;Analysis</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        {/* Do nothing. */}
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>
                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}


                            {errors && errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }

                            {/*
                                -----
                                TABLE
                                -----
                            */}
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container">
                                    <h2><i className="fa fa-list-ul" aria-hidden="true"></i>&nbsp;Summary</h2>
                                    <p className="w3-margin-bottom">The following analysis is the summary information for all the students whom taken this evaluation.</p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Total Submissions</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.totalSubmissions} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Total Passes</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.totalPasses} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Total Failures</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.totalFailures} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Mark Mean</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.markMean} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Mark Median</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.markMedian} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Mark Mode</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.markMode} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Minimum Duration (minutes)</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.minDurationInMinutes} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Average Duration (minutes)</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.avgDurationInMinutes} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Maximum Duration (minutes)</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.maxDurationInMinutes} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Total On-time</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.totalOnTime} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Total Late</strong>
                                        </label>
                                        <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="uuid" value={analysis && analysis.totalLate} />
                                    </p>

                                    <div className="w3-row">

                                        <div className="w3-col m2">
                                            <h4 className="w3-center"><i className="fa fa-check" aria-hidden="true"></i>&nbsp;Passed / Failed</h4>
                                            <Pie data={passFailData} />
                                        </div>

                                        <div className="w3-col m1 w3-container">
                                        </div>

                                        <div className="w3-col m2">
                                            <h4 className="w3-center"><i className="fa fa-bell" aria-hidden="true"></i>&nbsp;On-Time / Late</h4>
                                            <Pie data={onTimeLateData} />
                                        </div>

                                        <div className="w3-col m1 w3-container">
                                        </div>
                                    </div>
                                    <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                                    <h2><i className="fa fa-list-ol" aria-hidden="true"></i>&nbsp;Answers</h2>
                                    <p className="w3-margin-bottom">The following pertains to the student answer choices.</p>
                                    {analysis && analysis.answers && analysis.answers.length > 0
                                        ? <div className="w3-col">
                                            <div className="w3-responsive">
                                                <AnswersSection answers={analysis.answers} />
                                            </div>
                                        </div>
                                        : <div>
                                        </div>
                                    }

                                    <p className="w3-row"><hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                                        <h2 className="w3-margin-top"><i className="fa fa-table" aria-hidden="true"></i>&nbsp;Submissions</h2>
                                        <p className="w3-margin-bottom">The following pertains to the student submissions.</p>
                                    </p>

                                    <div className="w3-col">
                                        <div className="w3-responsive">
                                            <table className="w3-table-all">
                                                <tr style={{cursor: "pointer"}}>
                                                    <th></th>
                                                    <th>Student</th>
                                                    <th>Mark</th>
                                                    <th>Duration (min)</th>
                                                    <th>School</th>
                                                    <th>Grade</th>
                                                    <th>City</th>
                                                    <th>Submitted</th>
                                                    <th className=""></th>
                                                </tr>
                                                {analysis && analysis.submissions && analysis.submissions.map((submission) => (
                                                    <tr className={`${submission.markedFailure ? "w3-text-red" : "w3-text-green"}`} id={submission.uuid}>
                                                        <td>
                                                            {submission.markedFailure
                                                                ? <><i className="fa fa-times" aria-hidden="true"></i></>
                                                                : <><i className="fa fa-check" aria-hidden="true"></i></>
                                                            }
                                                        </td>
                                                        <td>{submission.studentName}&nbsp;(#{submission.studentCode})</td>
                                                        <td>{submission.markedFinal}&nbsp;%</td>
                                                        <td>{submission.durationInMinutes}</td>
                                                        <td>{submission.schoolName}&nbsp;(#{submission.studentCode})</td>
                                                        <td>{submission.grade}</td>
                                                        <td>{submission.city}</td>
                                                        <td>{new Date(submission.createdTime).toLocaleDateString()}</td>
                                                        <td>
                                                            <a className="w3-left"
                                                                    href={`/submission/${submission.id}`}
                                                                  target="_blank"
                                                                     rel="noreferrer"
                                                                tabIndex={tabIndex}
                                                                   style={{ textDecoration: 'none' }}>
                                                                View&nbsp;<i className="fa fa-external-link" aria-hidden="true"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>

                                            <br />
                                        </div>
                                    </div>

                                    <p className="w3-row"><hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                                        <h2 className="w3-margin-top"><i className="fa fa-safari" aria-hidden="true"></i>&nbsp;Browser Metrics</h2>
                                        <p className="w3-margin-bottom">The following pertains web-browser breakdown that took the evaluation.</p>
                                    </p>
                                    <div className="w3-row">
                                        <div className="w3-col m1 w3-container">
                                        </div>

                                        <div className="w3-col m2">
                                            <h4 className="w3-center"><i className="fa fa-safari" aria-hidden="true"></i>&nbsp;Browser(s)</h4>
                                            <Pie data={browserData} />
                                        </div>

                                        <div className="w3-col m2 w3-container">
                                        </div>

                                        <div className="w3-col m2">
                                            <h4 className="w3-center"><i className="fa fa-desktop" aria-hidden="true"></i>&nbsp;OS(s)</h4>
                                            <Pie data={osData} />
                                        </div>

                                        <div className="w3-col m2 w3-container">
                                        </div>

                                        <div className="w3-col m2">
                                            <h4 className="w3-center"><i className="fa fa-microchip" aria-hidden="true"></i>&nbsp;Device(s)</h4>
                                            <Pie data={deviceData} />
                                        </div>

                                        <div className="w3-col m1 w3-container">
                                        </div>
                                    </div>


                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to={`/evaluation/${id}`}
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        {/*
                                        <button className="w3-col m3 w3-btn w3-green w3-round-xlarge w3-right"
                                                  onClick={onFormSubmitClick}
                                                     type="button"
                                                 tabIndex={tabIndex}>
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;ARCHIVE
                                        </button>
                                        */}
                                    </p>
                                </form>
                            }
                            {/*
                                ----------
                                end TABLE
                                ----------
                            */}
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 w3-container">
                </div>

            </div>

        </>
    );
}

function AnswersSection({ answers }) {
    return (
        <div className="">
            {answers.map((answer) => (
                <AnswerCard answer={answer} />
            ))}
        </div>
    )
}

function AnswerCard({ answer}) {
    return (
        <div key={answer.uuid} className="w3-card-4 w3-margin">
            <header className="w3-container w3-light-blue">
            <h3><i className="fa fa-braille" aria-hidden="true"></i>&nbsp;<strong>Question #{answer.questionNumber}</strong></h3>
            </header>

            <div className="w3-container w3-row">
                {/* COL 1 */}
                <div className="w3-col m4">
                    <AnswerRightSection answer={answer} />
                </div>
                {/* COL 2 */}
                <div className="w3-col m4">
                    <AnswerMiddleSection answer={answer} />
                </div>
                {/* COL 3 */}
                <div className="w3-col m4">
                    { answer && answer.choicesRaw && <AnswerLeftSection answer={answer} /> }
                </div>
            </div>

            <footer className="w3-container">
            {/* Do nothing */}
            </footer>
        </div>
    )
}

function AnswerRightSection({ answer }) {
    return (
        <>
            <p className="w3-row">
                <label className="w3-col">
                    <strong>Total Submissions</strong>
                </label>
                <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="total" value={answer.total} />
            </p>
            <p className="w3-row">
                <label className="w3-col">
                    <strong>Min Duration (sec)</strong>
                </label>
                <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="total" value={answer.minDurationInSeconds.toFixed(3)} />
            </p>
            <p className="w3-row">
                <label className="w3-col">
                    <strong>Avg Duration (sec)</strong>
                </label>
                <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="total" value={answer.avgDurationInSeconds.toFixed(3)} />
            </p>
            <p className="w3-row">
                <label className="w3-col">
                    <strong>Max Duration (sec)</strong>
                </label>
                <input className={`w3-col m2 w3-input w3-small`} disabled={true} type="text" name="total" value={answer.maxDurationInSeconds.toFixed(3)} />
            </p>
        </>
    );
}

function AnswerMiddleSection({ answer }) {
    const passFailData = {
      labels: ["Incorrect", "Correct"],
      datasets: [
        {
          label: 'Correct / Incorrect Chart',
          data: [answer.totalFailures, answer.totalPasses],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(75, 192, 192, 0.2)',

          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(75, 192, 192, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    return (
        <>
            <p className="w3-row w3-center">
                <label className="w3-col">
                    <strong className="">Correct / Incorrect</strong>
                </label>
                <div style={{height:"250px"}}>
                <Pie data={passFailData} height={250} options={{ maintainAspectRatio: false }} />
                </div>
            </p>
        </>
    );
}

function AnswerLeftSection({ answer }) {

    const choices = JSON.parse(answer.choicesRaw);

    // The following code will iterate through all the selections and generate
    // the data to create a `bar graph` using `chart.js`.
    let labels = [];
    let data = [];
    let backgroundColors = [];
    let borderColors = [];

    for (var choice of choices) {
        // For debugging purposes only.
        console.log(choice.letter);
        console.log(choice.is_correct);
        console.log(choice.picked_count);
        console.log(choice.picked_percent);
        console.log();

        // Generate our graph data.
        labels.push(choice.letter);
        data.push(choice.picked_count);
        if (choice.is_correct) {
            backgroundColors.push("rgba(75, 192, 192, 0.2)");
            borderColors.push("rgba(75, 192, 192, 1)");
        } else {
            backgroundColors.push("rgba(255, 99, 132, 0.2");
            borderColors.push("rgba(255, 99, 132, 1)");
        }
    }

    const state = {
      labels: labels, // The letter selection.
      datasets: [
        {
          label: 'Selection',
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 2,
          data: data
        }
      ]
    }

    return (
        <>
            <p className="w3-row w3-center">
                <label className="w3-col">
                    <strong className="w3-center">Picked Letter</strong>
                </label>
                <Bar
                  data={state}
                  options={{
                      plugins: {
                          legend: {
                              display: false
                          },
                          tooltips: {
                              display: false
                          },
                      }
                  }}
                />
            </p>
        </>
    );
}

export default EvaluationAnalysis;
