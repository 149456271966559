import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTabIndex } from 'react-tabindex';
import useLocalStorage from "../../Hooks/useLocalStorage";
import { isEmpty } from 'lodash';
import { ADMINISTRATOR_ROLE_ID } from "../../Constants/App";

function TopNavigation() {
    const tabIndex = useTabIndex();
    const [showMenu, setShowMenu] = useState(false);
    const [profile] = useLocalStorage("DIGITALSCANTRON_PROFILE");
    const [tenant] = useLocalStorage("DIGITALSCANTRON_TENANT");

    function onHamburgerClick() {
        setShowMenu(!showMenu);
    }

    let appTitle = "DigitalScan"
    let appImageURL = ""; // Default does not have image url.
    let appColour = "indigo"; // The default colour theme for our app.
    if (profile !== undefined && profile !== null && profile !== "" && profile !== "undefined" && !isEmpty(profile)) {
        if (tenant !== undefined && tenant != null && tenant !== "" && tenant !== "undefined" && !isEmpty(tenant)) {
            if (tenant.appTitle !== "") {
                appTitle = tenant.appTitle;
            }
            if (tenant.appColour !== "") {
                appColour = tenant.appColour;
            }
            if (tenant.appImageURL !== "") {
                appImageURL = tenant.appImageURL;
            }
        }
    }
    console.log("Utilizing defaults...")

    return (
        <>
            <div className={`w3-${appColour} w3-top`} style={{height:"70px",}}>
                {showMenu && <div className="w3-sidebar w3-bar-block  w3-card w3-animate-right" style={{width:"200px",right:"0"}}>
                    <button onClick={onHamburgerClick}
                          className="w3-bar-item w3-large"
                           tabIndex={tabIndex}>Close &times;</button>
                    {isEmpty(profile)
                        ?<>
                            <a href="/login" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                <i className="fa fa-sign-in" aria-hidden="true"></i>&nbsp;Login
                            </a>
                            <a href="/register" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                <i className="fa fa-user-circle-o" aria-hidden="true"></i>&nbsp;Register
                            </a>
                        </>
                        : <>
                            <a href="/dashboard" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                <i className="fa fa-dashboard" aria-hidden="true"></i>&nbsp;Dashboard
                            </a>
                            <a href="/evaluations" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                <i className="fa fa-braille" aria-hidden="true"></i>&nbsp;Evalations
                            </a>
                            <a href="/submissions" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                <i className="fa fa-table" aria-hidden="true"></i>&nbsp;Submissions
                            </a>
                            <a href="/transfers" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                <i className="fa fa-exchange" aria-hidden="true"></i>&nbsp;Transfers
                            </a>
                            {profile && profile.roleId === ADMINISTRATOR_ROLE_ID &&
                                <a href="/organization" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                    <i className="fa fa-building" aria-hidden="true"></i>&nbsp;Organization
                                </a>
                            }
                            {profile && profile.roleId === ADMINISTRATOR_ROLE_ID &&
                                <a href="/users" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                    <i className="fa fa-users" aria-hidden="true"></i>&nbsp;Users
                                </a>
                            }
                            <a href="/profile" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                <i className="fa fa-user-circle" aria-hidden="true"></i>&nbsp;Profile
                            </a>
                            <a href="/logout" className="w3-bar-item w3-button w3-text-black" tabIndex={tabIndex}>
                                <i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Logout
                            </a>
                        </>
                    }
                </div>}

                <div className="w3-bar w3-xxlarge">
                    <Link to={isEmpty(profile) ? "/login" : "/dashboard"}
                   className="w3-bar-item"
                       style={{textDecoration:"none"}}
                    tabIndex={tabIndex}>
                        <div className="w3-hide-small">
                            {appImageURL !== undefined && appImageURL !== null && appImageURL !== ""
                                ? <>
                                    <img src={appImageURL} class="w3-round" width={45} alt={"Logo"}/>
                                </>
                                : <i className="fa fa-qrcode" aria-hidden="true"></i>
                            }
                            &nbsp;{appTitle}
                        </div>
                    </Link>
                    <button onClick={onHamburgerClick}
                          className="w3-bar-item w3-button w3-right"
                           disabled={showMenu}
                           tabIndex={tabIndex}>&#9776;</button>
                </div>
            </div>
        </>
    );
}

export default TopNavigation;
