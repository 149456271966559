import React, { useEffect } from "react";
import { Base64 } from 'js-base64';
import { Link, useLocation } from "react-router-dom";
import { useTabIndex } from 'react-tabindex';
import useLocalStorage from "../../Hooks/useLocalStorage";
import { getAPIBaseURL } from "../../Helpers/urlUtility";
import QRCode from "qrcode.react";

function EvaluationQRCode() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [tenantId] = useLocalStorage("DST_E_TENANT_ID", 0);
    const [uuid] = useLocalStorage("DST_E_UUID", "");
    const [name] = useLocalStorage("DST_E_NAME", "");
    const [alternateName] = useLocalStorage("DST_E_ALTERNATE_NAME", "");
    const [description] = useLocalStorage("DST_E_DESCRIPTION", "");
    const [customId] = useLocalStorage("DST_E_CUSTOM_ID", "");
    const [category] = useLocalStorage("DST_E_CATEGORY", "");
    const [imageURL] = useLocalStorage("DST_E_IMAGE_URL", "");
    const [numberOfMultipleChoiceQuestions] = useLocalStorage("DST_E_NUMBER_OF_MULTIPLE_CHOICE_QUESTIONS", "");
    const [numberOfTextareaQuestions] = useLocalStorage("DST_E_NUMBER_OF_TEXTAREA_QUESTIONS", "");
    const [numberOfAreas] = useLocalStorage("DST_E_NUMBER_OF_AREAS", "");
    const [durationInMinutes] = useLocalStorage("DST_E_DURATION_IN_MINUTES", "");
    const [beginTime] = useLocalStorage("DST_E_BEGINTIME", "");
    const [endTime] = useLocalStorage("DST_E_ENDTIME", "");
    const [grade] = useLocalStorage("DST_GRADE_NAME", "");
    const [year] = useLocalStorage("DST_E_YEAR", "");
    const [city] = useLocalStorage("DST_E_CITY", "");
    const [schoolName] = useLocalStorage("DST_E_SCHOOL_NAME", "");
    const [schoolCode] = useLocalStorage("DST_E_SCHOOL_CODE", "");
    const [tenant] = useLocalStorage("DIGITALSCANTRON_TENANT");

    // --- API --- //
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-2];

    useEffect(() => {
        document.title = "DigitalScan - Evaluation QR Code";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    //
    // API
    //

    // Do nothing.

    //
    // COMPONENT FUNCTIONS
    //

    function onFormSubmitClick(e) {
        window.print();
    }

    function onDownloadClick(e) {
        // SPECIAL THANKS:
        // https://github.com/zpao/qrcode.react/issues/37
        console.log("Downloading QR Code...");

        const canvas = document.querySelector('.HpQrcode > canvas');
        const pngFile = canvas.toDataURL("image/png");

        const downloadLink = document.createElement("a");
        downloadLink.download = "qrcode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
    }

    //
    // API Handling
    //

    // Do nothing.

    //
    // MISC
    //

    const codePayload = {
        "uuid": uuid,
        "name": name,
        "alias": alternateName,
        "i_url": imageURL,
        "desc": description,
        "qs": numberOfMultipleChoiceQuestions,
        "taqs": numberOfTextareaQuestions,
        "opts": "4",
        "s_dt": new Date(beginTime).getTime(),
        "e_dt": new Date(endTime).getTime(),
        "s_url": getAPIBaseURL() + "/submit",
        "org_id": tenantId,
        "sid": "",
        "sn": "",
        "sc": "",
        "schn": schoolName,
        "schc": schoolCode,
        "city": city,
        "g": grade,
        "y": year,
        "noa": numberOfAreas,
        "d": durationInMinutes,
        "appt": tenant.appTitle,
        "appi": tenant.appImageURL !== undefined ? tenant.appImageURL : tenant.appImageUrl, // BUGFIX
        "appc": tenant.appColour,
        "appl": tenant.language,
        "t_id": customId,
        "t_c": category
    };
    const codePayloadStr = JSON.stringify(codePayload);
    const codePayloadB64 = Base64.encode(codePayloadStr)
    const qrCodeURL = process.env.REACT_APP_WWW_KEYPAD_URL+"?code="+codePayloadB64;

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/evaluation/${id}`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-qrcode" aria-hidden="true"></i>&nbsp;QR Code</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-qrcode" aria-hidden="true"></i>&nbsp;QR Code</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        {/* Do nothing. */}
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>
                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}


                            {/*
                                -----
                                TABLE
                                -----
                            */}
                            <form className="w3-container  w3-center">
                                <div className="w3-col">
                                    <p className="w3-text-grey w3-medium">This QR code can be scanned by your student's smartphone to redirect them to the evaluation they must complete.</p>
                                </div>
                            </form>
                            <form className="w3-container">

                                <div className="w3-col m2 l3 w3-container">
                                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                                </div>

                                <div className="w3-col m8 l6 w3-center">
                                    {/* Desktop*/}
                                    <div className="w3-hide-small w3-hide-medium HpQrcode">
                                        {qrCodeURL && qrCodeURL.length < 2900
                                            ? <>
                                                <QRCode value={qrCodeURL} size={500} className="w3-image w3-border" />
                                            </>
                                            : <>Receipt Link</>
                                        }
                                    </div>
                                    {/* Tablet / Mobile */}
                                    <div className="w3-hide-large HpQrcode">
                                        {qrCodeURL && qrCodeURL.length < 2900
                                            ? <>
                                                <QRCode value={qrCodeURL} size={250} className="w3-image w3-border" />
                                            </>
                                            : <>Receipt Link</>
                                        }
                                    </div>
                                    <button className="w3-mobile w3-btn w3-green w3-round-xlarge"
                                              onClick={onDownloadClick}
                                                 type="button"
                                             tabIndex={tabIndex}>
                                        <i className="fa fa-download" aria-hidden="true"></i>&nbsp;DOWNLOAD
                                    </button>
                                </div>
                                <div className="w3-col m2 l3 w3-container">
                                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                                </div>


                                <p className="w3-row">
                                    <hr className="w3-col w3-grey" />
                                    <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                 to={`/evaluation/${id}`}
                                           tabIndex={tabIndex}>
                                        <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                    </Link>
                                    <span className="w3-col m6">&nbsp;</span>
                                    <button className="w3-col m3 w3-btn w3-green w3-round-xlarge w3-right"
                                              onClick={onFormSubmitClick}
                                                 type="button"
                                             tabIndex={tabIndex}>
                                        <i className="fa fa-print" aria-hidden="true"></i>&nbsp;PRINT
                                    </button>
                                </p>
                            </form>
                            {/*
                                ----------
                                end TABLE
                                ----------
                            */}
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default EvaluationQRCode;
