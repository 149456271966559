import React, { useState, useEffect } from "react";
import { Base64 } from 'js-base64';
import { isEmpty } from 'lodash';
import Scroll from 'react-scroll';
import { Link, useLocation } from "react-router-dom";
import { useTabIndex } from 'react-tabindex';
import { getEvaluationDetailAPI } from "../../API/evaluation";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { getAPIBaseURL } from "../../Helpers/urlUtility";
import { ErrorBox } from "../Element/ErrorBox";
import { QuestionRow } from "../Element/QuestionRowChoiceCol";

function EvaluationDetail() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [evaluation, setEvaluation] = useState({});
    const [tenantId, setTenantId] = useLocalStorage("DST_E_TENANT_ID", 0);
    const [uuid, setUuid] = useLocalStorage("DST_E_UUID", "");
    const [name, setName] = useLocalStorage("DST_E_NAME", "");
    const [alternateName, setAlternateName] = useLocalStorage("DST_E_ALTERNATE_NAME", "");
    const [description, setDescription] = useLocalStorage("DST_E_DESCRIPTION", "");
    const [customId, setCustomId] = useLocalStorage("DST_E_CUSTOM_ID", "");
    const [category, setCategory] = useLocalStorage("DST_E_CATEGORY", "");
    const [imageURL, setImageURL] = useLocalStorage("DST_E_IMAGE_URL", "");
    const [numberOfMultipleChoiceQuestions, setNumberOfMultipleChoiceQuestions] = useLocalStorage("DST_E_NUMBER_OF_MULTIPLE_CHOICE_QUESTIONS", "");
    const [numberOfTextareaQuestions, setNumberOfTextareaQuestions] = useLocalStorage("DST_E_NUMBER_OF_TEXTAREA_QUESTIONS", "");
    const [numberOfAreas, setNumberOfAreas] = useLocalStorage("DST_E_NUMBER_OF_AREAS", "");
    const [durationInMinutes, setDurationInMinutes] = useLocalStorage("DST_E_DURATION_IN_MINUTES", "");
    const [beginTime, setBeginTime] = useLocalStorage("DST_E_BEGINTIME", "");
    const [endTime, setEndTime] = useLocalStorage("DST_E_ENDTIME", "");
    const [grade, setGrade] = useLocalStorage("DST_GRADE_NAME", "");
    const [year, setYear] = useLocalStorage("DST_E_YEAR", "");
    const [city, setCity] = useLocalStorage("DST_E_CITY", "");
    const [schoolName, setSchoolName] = useLocalStorage("DST_E_SCHOOL_NAME", "");
    const [schoolCode, setSchoolCode] = useLocalStorage("DST_E_SCHOOL_CODE", "");
    const [answers, setAnswers] = useLocalStorage("DST_E_ANSWERS", [])
    const [errors, setErrors] = useState({});
    const [tenant] = useLocalStorage("DIGITALSCANTRON_TENANT");

    // --- API --- //
    const [isLoading, setIsLoading] = useState(true);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-1];

    useEffect(() => {
        document.title = "Evaluation Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        //
        // API Handling
        //

        function onDetailSuccess(responseData) {
            console.log(responseData);
            setTenantId(responseData.tenantId);
            setUuid(responseData.uuid);
            setName(responseData.name);
            setAlternateName(responseData.alternateName);
            setDescription(responseData.description);
            setCustomId(responseData.customId);
            setCategory(responseData.category);
            setImageURL(responseData.imageURL);
            setNumberOfMultipleChoiceQuestions(responseData.numberOfMultipleChoiceQuestions);
            setNumberOfTextareaQuestions(responseData.numberOfTextareaQuestions);
            setNumberOfAreas(responseData.numberOfAreas);
            setDurationInMinutes(responseData.durationInMinutes);
            setBeginTime(responseData.beginTime);
            setEndTime(responseData.endTime);
            setGrade(responseData.grade);
            setYear(responseData.year);
            setCity(responseData.city);
            setSchoolName(responseData.schoolName);
            setSchoolCode(responseData.schoolCode);
            setAnswers(JSON.parse(responseData.answers));
            setErrors({});
        }

        let mounted = true;

        if (isEmpty(evaluation)) {
            getEvaluationDetailAPI(id, (responseData) => { // A.k.a. "onSuccess"
                if (mounted) {
                    setEvaluation(responseData);
                    onDetailSuccess(responseData);
                }
            }, (apiErrors) => { // A.k.a. "onError"
                setErrors(apiErrors);

                // The following code will cause the screen to scroll to the top of
                // the page. Please see ``react-scroll`` for more information:
                // https://github.com/fisshy/react-scroll
                var scroll = Scroll.animateScroll;
                scroll.scrollToTop();
            }, () => {  // A.k.a. "onDone"
                setIsLoading(false);
            });
        }

        return () => mounted = false;
    },[
        id, evaluation, setEvaluation, setErrors, setIsLoading,
        setAlternateName, setAnswers, setBeginTime, setCategory,
        setCity, setCustomId, setDescription, setDurationInMinutes,
        setEndTime, setGrade, setImageURL, setName, setNumberOfAreas,
        setNumberOfMultipleChoiceQuestions, setNumberOfTextareaQuestions,
        setSchoolCode, setSchoolName, setUuid, setYear, setTenantId
    ]);

    //
    // API Handling
    //



    //
    // MISC
    //

    // Generate an array of a range of all the numbers from 1 to the questionsCount.
    // Each integer value in this array will be our question ID.
    const start = 1;
    const end = numberOfMultipleChoiceQuestions;
    const questionPKs = [...Array(end - start + 1).keys()].map(x => x + start);; // https://jasonwatmore.com/post/2021/10/02/vanilla-js-create-an-array-with-a-range-of-numbers-in-a-javascript

    // https://stackoverflow.com/a/61082536
    var beginTimeDT = new Date(beginTime);
    var beginTimeStr = "";
    try {
        if (beginTime !== undefined && beginTime !== null) {
            beginTimeDT.setMinutes(beginTimeDT.getMinutes() - beginTimeDT.getTimezoneOffset());
            beginTimeStr = beginTimeDT.toISOString().slice(0,16);
        }
    } catch(err) {
        // Do nothing...
    }

    var endTimeDT = new Date(endTime);
    var endTimeStr = "";
    try {
        if (endTime !== undefined && endTime !== null) {
            endTimeDT.setMinutes(endTimeDT.getMinutes() - endTimeDT.getTimezoneOffset());
            endTimeStr = endTimeDT.toISOString().slice(0,16);
        }
    } catch(err) {
        // Do nothing...
    }

    const codePayload = {
        "uuid": uuid,
        "name": name,
        "alias": alternateName,
        "i_url": imageURL,
        "desc": description,
        "qs": numberOfMultipleChoiceQuestions,
        "taqs": numberOfTextareaQuestions,
        "opts": "4",
        "s_dt": new Date(beginTime).getTime(),
        "e_dt": new Date(endTime).getTime(),
        "s_url": getAPIBaseURL() + "/submit",
        "org_id": tenantId,
        "sid": "",
        "sn": "",
        "sc": "",
        "schn": schoolName,
        "schc": schoolCode,
        "city": city,
        "g": grade,
        "y": year,
        "noa": numberOfAreas,
        "d": durationInMinutes,
        "appt": tenant.appTitle,
        "appi": tenant.appImageURL !== undefined ? tenant.appImageURL : tenant.appImageUrl, // BUGFIX
        "appc": tenant.appColour,
        "appl": tenant.language,
        "t_id": customId,
        "t_c": category
    };
    const codePayloadStr = JSON.stringify(codePayload);
    const codePayloadB64 = Base64.encode(codePayloadStr); // Developers Note: If you use `btoa` you will get error on NON-ASCII error as per issue https://stackoverflow.com/a/45725439.
    const qrCodeURL = process.env.REACT_APP_WWW_KEYPAD_URL+"?code="+codePayloadB64;

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to="/evaluations"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-braille" aria-hidden="true"></i>&nbsp;Evaluation</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-braille" aria-hidden="true"></i>&nbsp;Evaluation</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        <div className="w3-dropdown-hover w3-right">
                                            <button className="w3-button w3-light-grey">
                                                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                            </button>
                                            <div className="w3-dropdown-content w3-bar-block w3-border" style={{right:0}}>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluation/${id}/update`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Update
                                                </Link>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluation/${id}/export`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-cloud-download" aria-hidden="true"></i>&nbsp;Export
                                                </Link>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluation/${id}/archive`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-archive" aria-hidden="true"></i>&nbsp;Archive
                                                </Link>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluation/${id}/delete`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
                                                </Link>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluation/${id}/qrcode`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-qrcode" aria-hidden="true"></i>&nbsp;QR Code
                                                </Link>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluation/${id}/analysis`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-flask" aria-hidden="true"></i>&nbsp;Analysis
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>


                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}


                            {errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }

                            {/*
                                -----
                                TABLE
                                -----
                            */}
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container">
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>UUID</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input w3-small`} disabled={true} type="text" name="uuid" value={uuid} />

                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Name</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="name" value={name} />

                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Alternate Name</strong></label>
                                        <input className={`w3-col m7 w3-input`} disabled={true} type="text" name="alternateName" value={alternateName} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Description</strong></label>
                                        <textarea className={`w3-col m10 w3-input`} disabled={true} type="text" name="description" value={description} rows={9}>
                                        </textarea>
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Custom ID</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="name" value={customId} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Category</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input`} disabled={true} type="text" name="name" value={category} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Image URL</strong></label>
                                        <input className={`w3-col m9 w3-input`} disabled={true} type="text" name="imageURL" value={imageURL} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Duration in Minutes</strong></label>
                                        <input className={`w3-col m2 w3-input`} disabled={true} type="text" name="durationInMinutes" value={durationInMinutes} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Begin Time</strong></label>
                                        <input className={`w3-col m7 w3-input`} disabled={true}
                                                    type="datetime-local"
                                                    name="beginTime"
                                                   value={beginTimeStr}
                                        />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>End Time</strong></label>
                                        <input className={`w3-col m7 w3-input`} disabled={true}
                                                    type="datetime-local"
                                                    name="endTime"
                                                   value={endTimeStr}
                                        />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>School Name</strong></label>
                                        <input className={`w3-col m6 w3-input`} disabled={true} type="text" name="schoolName" value={schoolName} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>School Code</strong></label>
                                        <input className={`w3-col m6 w3-input`} disabled={true} type="text" name="schoolCode" value={schoolCode} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>City</strong></label>
                                        <input className={`w3-col m4 w3-input`} disabled={true} type="text" name="city" value={city} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Grade</strong></label>
                                        <input className={`w3-col m2 w3-input`} disabled={true} type="number" name="grade" value={grade} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Year</strong></label>
                                        <input className={`w3-col m2 w3-input`} disabled={true} type="number" name="year" value={year} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col"><strong>Number of Areas</strong></label>
                                        <input className={`w3-col m2 w3-input`} disabled={true} type="number" name="numberOfAreas" value={numberOfAreas} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col m12"><strong># of Multiple-Choice Questions</strong></label>
                                        <input className={`w3-col m2 w3-input`} disabled={true} type="number" name="numberOfMultipleChoiceQuestions" value={numberOfMultipleChoiceQuestions} />
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col m12"><strong># of Textarea Questions</strong></label>
                                        <input className={`w3-col m2 w3-input`} disabled={true} type="number" name="numberOfTextareaQuestions" value={numberOfTextareaQuestions} />
                                    </p>
                                    {/* TABLE */}
                                    <p className={`w3-row`}>
                                        <hr className="w3-col w3-grey" />
                                        <h4 className="w3-col m6 w3-left">
                                            <strong><i className="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Correct Answers</strong>
                                        </h4>

                                        <p className="w3-col">Please add the correct answers to this evaluation</p>
                                        <p className="w3-col">

                                        <div className={`w3-col m6 ${errors && errors.answers !== undefined ? 'w3-border w3-border-red' : ''} w3-padding`}>
                                            {/* START ROW */}
                                            <div className="w3-white w3-round w3-border w3-border-grey w3-card">
                                                <div>
                                                    {/* Load all the questions BUT disable action handling. */}
                                                    {questionPKs.map((questionPK) =>
                                                        <QuestionRow key={`q-${questionPK}`}
                                                              questionPK={questionPK}
                                                            optionsCount={4}
                                                  onQuestionChoiceChange={null}
                                                                 answers={answers}
                                                                tabIndex={tabIndex}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            {/* END ROW */}
                                        </div>
                                        </p>
                                        {errors && errors.answers !== undefined &&
                                            <p className="w3-col w3-text-red">{errors.answers}</p>
                                        }
                                    </p>
                                    {/* END TABLE */}
                                    <div className="w3-row">
                                        <h3><strong><i className="fa fa-share" aria-hidden="true"></i>&nbsp;Shareable Link</strong></h3>
                                        <hr className="w3-grey" />
                                        <div className="w3-text-grey w3-medium">THIS IS THE URL OR QR CODE WHICH YOU CAN SHARE TO STUDENTS. THESE SHAREABLE LINKS WILL LOAD UP THE EVALUATION ON THE STUDENT DEVICE. IF YOU WOULD LIKE MORE DETAILS ON THE SHAREABLE LINKS THEN <Link to={`/evaluation/${id}/shareable-link-info`}>CLICK HERE&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></Link>.</div>

                                    </div>
                                    <p className="w3-row">

                                        <div className="w3-col ">
                                            <div className="w3-card w3-margin w3-border w3-border-black w3-card">
                                                <div className="w3-light-grey w3-padding w3-border-bottom w3-border-black">
                                                    <h3 className="w3-center"><i className="fa fa-graduation-cap" aria-hidden="true"></i>&nbsp;Student Evaluation Portal</h3>
                                                </div>
                                                <div className="w3-padding">
                                                    <p>Evaluations are conducted by students using the <strong>DigitalScan Keypad Service</strong>. To allow your students to take this evaluation, they must be given the following link to run in their web-browser. Simply copy and paste this link and give to your students; in addition, you may provide a QR code as well.</p>
                                                    <p>When the student finishes their evaluation, they submit it automatically as a <Link to="/submissions" target="_blank" rel="noreferrer" tabIndex={tabIndex}>submission&nbsp;<i className="fa fa-external-link" aria-hidden="true"></i></Link> into the system.</p>
                                                    <p>
                                                        <label><strong>DigitalScan Keypad Service URL:</strong></label>
                                                        <textarea className="w3-input w3-border w3-border-black" type="text" value={qrCodeURL} rows={10} disabled={true} />
                                                        <a className="w3-right w3-small w3-text-grey" href={qrCodeURL} target="_blank" rel="noreferrer" tabIndex={tabIndex}>VIEW&nbsp;<i className="fa fa-external-link" aria-hidden="true"></i></a>

                                                    </p>
                                                    <p className="w3-center">
                                                        <Link className="w3-btn w3-green w3-round-xlarge"
                                                                     to={`/evaluation/${id}/qrcode`}
                                                               tabIndex={tabIndex}>
                                                            <i className="fa fa-qrcode" aria-hidden="true"></i>&nbsp;QR CODE
                                                        </Link>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                    </p>


                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to="/evaluations"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <Link className="w3-col m3 w3-btn w3-blue w3-round-xlarge w3-right"
                                                     to={`/evaluation/${id}/update`}
                                                   type="button"
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;UPDATE
                                        </Link>
                                    </p>
                                </form>
                            }
                            {/*
                                ----------
                                end TABLE
                                ----------
                            */}
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default EvaluationDetail;
