import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from 'react-scroll';
import { useTabIndex } from 'react-tabindex';
import { importEvaluationOperationAPI } from "../../API/evaluation";
import { ErrorBox } from "../Element/ErrorBox";

function EvaluationImport() {
    //
    // COMPONENT STATE MANAGEMENT
    //
    const [errors, setErrors] = useState();
    const [forceUrl, setForceUrl] = useState("");

    // --- Form ---

    const tabIndex = useTabIndex();
    const [file, setFile] = useState(null)

    // --- API --- //
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = "Import Evaluation";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    //
    // API
    //

    //
    // COMPONENT FUNCTIONS
    //

    function onFileChange(e) {
        // Get the single file we uploaded to the form.
        const selectedFileElement = e.target
        const selectedFile = selectedFileElement.files[0];

        // For debugging purposes only.
        console.log(selectedFile); // Print object contents.
        console.log(selectedFile.name) // Name
        console.log(selectedFile.size) // Size
        console.log(selectedFile.type) // type

        const fileWithPreview = Object.assign(selectedFile, {
            preview: URL.createObjectURL(selectedFile)
        });

        // For debugging purposes.
        console.log("onFileChange | fileWithPreview", fileWithPreview);

        // Update our local state to update the GUI.
        setFile(fileWithPreview);
    }

    function onFormSubmitClick(e) {
        if (file === undefined || file === null || file === "") {
            setErrors({"file": "Please upload a file.",});
            return;
        }

        // DJANGO-REACT UPLOAD: STEP 4 OF 4.
        // DEVELOPERS NOTE:
        // (1) http://jsbin.com/piqiqecuxo/1/edit?js,console,output
        // (2) https://stackoverflow.com/questions/51272255/how-to-use-filereader-in-react
        var fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = function handleFile(e) { // DJANGO-REACT UPLOAD: STEP 3 OF 4.
            const content = fileReader.result;
            console.log("handleFile | content:", content);

            const postData = {
                fileBase64Content: content,
                fileName: file.name,
                fileType: file.type,
            }

            // This is where you upload the data.
            console.log("Sending:", postData);

            setIsLoading(true);
            importEvaluationOperationAPI(postData, onSuccess, onError, onDone);
        };
        fileReader.onerror = function (error) {
            setIsLoading(false);
            setErrors(console.error());
        };
    }

    //
    // API Handling
    //

    function onSuccess(evaluation) {
        setForceUrl("/evaluations");
    }

    function onError(apiErrors) {
        setErrors(apiErrors);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onDone() {
        setIsLoading(false);
    }

    //
    // COMPONENT RENDER
    //

    if (forceUrl !== "") {
        return <Navigate to={forceUrl} />;
    }

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/evaluations`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-cloud-upload" aria-hidden="true"></i>&nbsp;Import Evaluation</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-cloud-upload" aria-hidden="true"></i>&nbsp;Import Evaluation</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        {/* Do nothing. */}
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>
                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}


                            <ErrorBox errors={errors} />

                            {/*
                                -----
                                TABLE
                                -----
                            */}
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container">
                                    <p>
                                    You are about add an evaluation using the <strong>import</strong> method. To begin please upload the evaluation <i>json</i> file:
                                    </p>

                                    <label>File (*):</label>
                                    <input className={`w3-input w3-border ${errors ? 'w3-border-red' : ''}`}
                                                type="file"
                                            onChange={onFileChange} />
                                    {errors && errors.file && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.file}</strong>}
                                    </label>}


                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to={`/evaluations`}
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <button className="w3-col m3 w3-btn w3-green w3-round-xlarge w3-right"
                                                  onClick={onFormSubmitClick}
                                                     type="button"
                                                 tabIndex={tabIndex}>
                                            <i className="fa fa-upload" aria-hidden="true"></i>&nbsp;UPLOAD
                                        </button>
                                    </p>
                                </form>
                            }
                            {/*
                                ----------
                                end TABLE
                                ----------
                            */}
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default EvaluationImport;
