import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTabIndex } from 'react-tabindex';
import { getUsersAPI } from "../../API/user";
import { PAGINATION_LIMIT } from "../../Constants/App";
import { USER_ROLE_IDS, USER_STATES } from "../../Constants/FieldOptions";

function UserList() {
    //
    // State
    //

    const tabIndex = useTabIndex();
    const [isLoading, setIsLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [nextID, setNextID] = useState(0);
    const [offsetHistory, setOffsetHistory] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit] = useState(PAGINATION_LIMIT);
    const [errors, setErrors] = useState({});
    const [isLastPage, setIsLastPage] = useState(false);

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Users";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        let mounted = true;

        let parametersMap = new Map();
        parametersMap.set("state", 1);
        parametersMap.set("sort_order", "ASC");
        parametersMap.set("sort_field", "id");

        getUsersAPI(offset, limit, parametersMap, (responseData) => {
            if (mounted) {
                const { nextId, count, results } = responseData;
                if (results === null) {
                    setResults([]);
                } else {
                    setResults(results);
                }
                setNextID(nextId);
                setCount(count);
                setIsLastPage(results.length < PAGINATION_LIMIT);
            }
        }, (apiErrors) => {
            setErrors(apiErrors);
            console.log(apiErrors);
        }, () => {
            setIsLoading(false);
        });

        return () => mounted = false;
    }, [limit, offset]);

    //
    // Event handling.
    //

    function onNextButtonClick(e){
        // Save our current offset to our navigational history.
        offsetHistory.push(offset);
        setOffsetHistory(offsetHistory);

        // Set the current offset to the `next` one so the page reloads.
        setOffset(nextID);

        // Set the `isLoading` to be `true` thus forcing this component to
        // make the API call to the backend and refresh with the latest data.
        setIsLoading(true);
        setErrors({});
    }

    function onPrevioustButtonClick(e){
        // Retrieve from our navigational history the pervious offset ID.
        const previousID = offsetHistory.pop();

        // Set the previous offset ID as the current one so we go back in history.
        setOffset(previousID);

        // Set the `isLoading` to be `true` thus forcing this component to
        // make the API call to the backend and refresh with the latest data.
        setIsLoading(true);
        setErrors({});
    }

    //
    // Rendering.
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to="/dashboard"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-row">
                                <div className="w3-col m12 w3-container">
                                    <h1>
                                        <strong><i className="fa fa-users" aria-hidden="true"></i>&nbsp;Users</strong>
                                    </h1>
                                </div>

                                <div className="w3-col w3-margin-bottom">
                                    <Link className="w3-center w3-btn w3-green w3-round-large w3-right w3-mobile"
                                                 to="/users/add"
                                           tabIndex={tabIndex}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add
                                    </Link>
                                </div>

                                {errors.length >= 0 &&
                                    <div>
                                        <h1>Contains errors!</h1>
                                    </div>
                                }

                                {isLoading
                                    ? <div className="w3-col">
                                        <div className="w3-responsive">
                                        <div className="w3-panel w3-pale-blue">
                                        <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                        <p>Please wait while we fetch the data.</p>
                                    </div>
                                    </div>
                                    </div>
                                    : <>
                                        {results && count > 0
                                            ? <div className="w3-col">
                                                <div className="w3-responsive">
                                                    <table className="w3-table-all">
                                                        <tr>
                                                          <th>Name</th>
                                                          <th>Role</th>
                                                          <th>State</th>
                                                          <th>Email</th>
                                                          <th className="">
                                                          </th>
                                                        </tr>
                                                        {results.map((user) => (
                                                            <tr>
                                                                <td>{user.name}</td>
                                                                <td>{USER_ROLE_IDS[user.roleId]}</td>
                                                                <td>{USER_STATES[user.state]}</td>
                                                                <td>
                                                                    <a href={`mailto:${user.email}`} target="_blank" rel="noreferrer">{user.email}&nbsp;<i className="fa fa-external-link"></i></a>
                                                                </td>
                                                                <td className="w3-text-grey w3-right">
                                                                    <Link to={`/user/${user.id}`}
                                                                       style={{textDecoration:"none",}}
                                                                    tabIndex={tabIndex}>
                                                                        View&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                    <div className="w3-bar w3-border w3-round">
                                                        {offset > 0 &&
                                                            <button onClick={onPrevioustButtonClick} className="w3-button" type="button">
                                                                <strong>&#10094; Previous</strong>
                                                            </button>
                                                        }
                                                        {results.length > 0 && count > 0 && !isLastPage &&
                                                            <button onClick={onNextButtonClick} className="w3-button w3-right" type="button">
                                                                <strong>Next &#10095;</strong>
                                                            </button>
                                                        }
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            : <div className="w3-col">
                                                <div className="w3-pale-blue w3-round-xlarge w3-container w3-padding w3-border w3-margin-bottom">
                                                    <h3>No Users</h3>
                                                    <p>There are no records, please <Link to="/users/add">add an user</Link> now.</p>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>
            </div>
        </>
    );
}

export default UserList;
