import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTabIndex } from 'react-tabindex';
import { getEvaluationsAPI } from "../../API/evaluation";
import { PAGINATION_LIMIT } from "../../Constants/App";


function EvaluationList() {
    //
    // State
    //

    const tabIndex = useTabIndex();
    const [isLoading, setIsLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [nextID, setNextID] = useState(0);
    const [offsetHistory, setOffsetHistory] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit] = useState(PAGINATION_LIMIT);
    const [errors, setErrors] = useState({});
    const [isLastPage, setIsLastPage] = useState(false);
    const [sortField, setSortField] = useState("id");
    const [sortOrder, setSortOrder] = useState("ASC");
    const [search, setSearch] = useState("");

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Evaluations";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        let mounted = true;

        let parametersMap = new Map();
        parametersMap.set("state", 1);
        parametersMap.set("sort_order", sortOrder);
        parametersMap.set("sort_field", sortField);
        parametersMap.set("search", search);

        getEvaluationsAPI(offset, limit, parametersMap, (responseData) => {
            if (mounted) {
                const { nextId, count, results } = responseData;
                if (results === null) {
                    setResults([]);
                } else {
                    setResults(results);
                }
                setNextID(nextId);
                setCount(count);
                // console.log(nextId, count, results);
                setIsLastPage(results.length < PAGINATION_LIMIT);
            }
        }, (apiErrors) => {
            setErrors(apiErrors);
            console.log(apiErrors);
        }, () => {
            setIsLoading(false);
        });

        return () => mounted = false;
    }, [limit, offset, search, sortField, sortOrder]);

    //
    // Event handling.
    //

    function onNextButtonClick(e){
        // Save our current offset to our navigational history.
        offsetHistory.push(offset);
        setOffsetHistory(offsetHistory);

        // Set the current offset to the `next` one so the page reloads.
        setOffset(offset + PAGINATION_LIMIT);

        // Set the `isLoading` to be `true` thus forcing this component to
        // make the API call to the backend and refresh with the latest data.
        setIsLoading(true);
        setErrors({});
    }

    function onPrevioustButtonClick(e){
        // Retrieve from our navigational history the pervious offset ID.
        const previousID = offsetHistory.pop();

        // Set the previous offset ID as the current one so we go back in history.
        setOffset(previousID);

        // Set the `isLoading` to be `true` thus forcing this component to
        // make the API call to the backend and refresh with the latest data.
        setIsLoading(true);
        setErrors({});
    }

    function onSortFieldClick(e, newSortOrder, newSortField) {
        console.log("onSortFieldClick: ",newSortOrder, newSortField);
        setSortOrder(newSortOrder);
        setSortField(newSortField);

        let parametersMap = new Map();
        parametersMap.set("state", 1);
        parametersMap.set("sort_order", sortOrder);
        parametersMap.set("sort_field", sortField);
        parametersMap.set("search", search);

        getEvaluationsAPI(offset, limit, parametersMap, (responseData) => {
            const { nextId, count, results } = responseData;
            if (results === null) {
                setResults([]);
            } else {
                setResults(results);
            }
            setNextID(nextId);
            setCount(count);
            // console.log(nextId, count, results);
            setIsLastPage(results.length < PAGINATION_LIMIT);
        }, (apiErrors) => {
            setErrors(apiErrors);
            console.log(apiErrors);
        }, () => {
            setIsLoading(false);
        });
    }

    function onSearchChange(e) {
        const value = e.target.value;
        setSearch(value);

        let parametersMap = new Map();
        parametersMap.set("state", 1);
        parametersMap.set("sort_order", sortOrder);
        parametersMap.set("sort_field", sortField);
        parametersMap.set("search", search);

        getEvaluationsAPI(offset, limit, parametersMap, (responseData) => {
            const { nextId, count, results } = responseData;
            if (results === null) {
                setResults([]);
            } else {
                setResults(results);
            }
            setNextID(nextId);
            setCount(count);
            // console.log(nextId, count, results);
            setIsLastPage(results.length < PAGINATION_LIMIT);
        }, (apiErrors) => {
            setErrors(apiErrors);
            console.log(apiErrors);
        }, () => {
            setIsLoading(false);
        });
    }

    //
    // Rendering.
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col w3-container">
                    <Link className="w3-large"
                                 to="/dashboard"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-row">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-braille" aria-hidden="true"></i>&nbsp;Evaluation</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-braille" aria-hidden="true"></i>&nbsp;Evaluation</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        <div className="w3-dropdown-hover w3-right">
                                            <button className="w3-button w3-light-grey">
                                                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                            </button>
                                            <div className="w3-dropdown-content w3-bar-block w3-border" style={{right:0}}>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluations/import`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>&nbsp;Import
                                                </Link>
                                                <Link className="w3-bar-item w3-button"
                                                             to={`/evaluations/add`}
                                                       tabIndex={tabIndex}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>


                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}

                            <div className="w3-col w3-margin-bottom w3-row">

                                <div className="w3-col m3 w3-right">
                                    <input className="w3-input w3-border"
                                                type="text"
                                         placeholder={"Search by Name, Alias, etc"}
                                            onChange={onSearchChange}
                                               value={search}
                                    />
                                </div>
                            </div>

                            {errors.length >= 0 &&
                                <div>
                                    <h1>Contains errors!</h1>
                                </div>
                            }

                            {isLoading
                                ? <div className="w3-col">
                                    <div className="w3-responsive">
                                    <div className="w3-panel w3-pale-blue">
                                    <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                    <p>Please wait while we fetch the data.</p>
                                    </div>
                                    </div>
                                </div>
                                    : <>
                                        {results && count > 0
                                            ? <div className="w3-col">
                                                <div className="w3-responsive">
                                                    <table className="w3-table-all">
                                                        <tr>
                                                            <HeaderTableCell text={"UUID"} field={"uuid"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"Name"} field={"name"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"Alias"} field={"alternate_name"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"Grade"} field={"grade"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"Year"} field={"year"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"Custom ID"} field={"custom_id"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"Category"} field={"category"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <th className="">
                                                          </th>
                                                        </tr>
                                                        {results.map((evaluation) => (
                                                            <tr>
                                                                <td className="w3-tiny">{evaluation.uuid}</td>
                                                                <td>{evaluation.name}</td>
                                                                <td>{evaluation.alternateName}</td>
                                                                <td>{evaluation.grade}</td>
                                                                <td>{evaluation.year}</td>
                                                                <td>{evaluation.customId}</td>
                                                                <td>{evaluation.category}</td>
                                                                <td className="w3-text-grey w3-right">
                                                                    <Link to={`/evaluation/${evaluation.id}`}
                                                                       style={{textDecoration:"none",}}
                                                                    tabIndex={tabIndex}>
                                                                        View&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                    <div className="w3-bar w3-border w3-round">
                                                        {offset > 0 &&
                                                            <button onClick={onPrevioustButtonClick} className="w3-button" type="button">
                                                                <strong>&#10094; Previous</strong>
                                                            </button>
                                                        }
                                                        {results.length > 0 && count > 0 && !isLastPage &&
                                                            <button onClick={onNextButtonClick} className="w3-button w3-right" type="button">
                                                                <strong>Next &#10095;</strong>
                                                            </button>
                                                        }
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            : <div className="w3-col">
                                                <div className="w3-pale-blue w3-round-xlarge w3-container w3-padding w3-border w3-margin-bottom">
                                                    <h3>No Evaluations</h3>
                                                    <p>There are no records, please <Link to="/evaluations/add">add an evaluation&nbsp;<i className="fa fa-link w3-text-grey" aria-hidden="true"></i></Link> now.</p>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="w3-text-grey w3-tiny w3-right w3-padding">
                        <i>
                            <Link to={"/evaluations/archived"} style={{textDecoration: "none",}}>Archived&nbsp;<i className="fa fa-arrow-right w3-text-grey" aria-hidden="true"></i></Link>
                        </i>
                    </div>
                </div>
            </div>
        </>
    );
}

function HeaderTableCell(props) {
    const { text, field, sortOrder, sortField, onSortFieldClick } = props;
    // console.log("HeaderTableCell", text, field, sortOrder, sortField);
    if (sortField === field) {
        if (sortOrder === "ASC") {
            return (
                <th onClick={(e, so, sf) => onSortFieldClick(e, "DESC", field) }>
                    {text}&nbsp;<i className="fa fa-sort-asc" aria-hidden="true"></i>
                </th>
            );
        } else {
            return (
                <th onClick={(e, so, sf) => onSortFieldClick(e, "ASC", field) }>
                    {text}&nbsp;<i className="fa fa-sort-desc" aria-hidden="true"></i>
                </th>
            );
        }
    } else {
        return (
            <th onClick={(e, so, sf) => onSortFieldClick(e, sortOrder, field) }>
                {text}&nbsp;<i className="fa fa-sort" aria-hidden="true"></i>
            </th>
        );
    }
}

export default EvaluationList;
