import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import Scroll from 'react-scroll';
import { useTabIndex } from 'react-tabindex';
import useLocalStorage from "../../Hooks/useLocalStorage";
import { ErrorBox } from "../Element/ErrorBox";
import { putSubmissionRemoteTransferAPI } from "../../API/submissionRemoteTransfer";


function SubmissionRemoteTransferUpdate() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [uuid] = useLocalStorage("DST_E_UUID", "");
    const [content, setContent] = useLocalStorage("DST_S_CONTENT", "");
    const [path, setPath] = useLocalStorage("DST_S_PATH", "");
    const [forceUrl, setForceUrl] = useState("");
    const [errors, setErrors] = useState({});

    // --- API --- //
    const [profile] = useLocalStorage("DIGITALSCANTRON_PROFILE");
    const [isLoading, setIsLoading] = useState(false);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-2];

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Submission Details";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    //
    // API
    //

    //
    // COMPONENT FUNCTIONS
    //

    function onPathChange(e) {
        setPath(e.target.value);
    }

    function onContentChange(e) {
        setContent(e.target.value);
    }

    function onFormSubmitClick(e) {
        setIsLoading(true);
        const putData = {
            tenantId: profile.tenantId,
            path: path,
            content: content,
        };
        putSubmissionRemoteTransferAPI(id, putData, onSuccess, onError, onDone);
    }

    //
    // API Handling
    //

    function onSuccess(evaluation) {
        setForceUrl("/transfer/"+id);
    }

    function onError(err) {
        setErrors(err);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onDone() {
        setIsLoading(false);
    }

    //
    // COMPONENT RENDER
    //

    if (forceUrl !== "") {
        return <Navigate to={forceUrl} />;
    }

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/transfer/${id}`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-container">
                                <h2><strong><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Transfer Update</strong></h2>
                                <hr className="w3-grey" />
                                <p className="w3-text-grey">Please note by clicking "Update" this submission remote transfer will be sent again to the remote server.</p>
                            </div>
                            {errors.length >= 0 &&
                                <ErrorBox errors={errors} />
                            }
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container" key={uuid}>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Path</strong>
                                        </label>
                                        <input className={`w3-col m9 w3-input`}
                                                onChange={onPathChange}
                                                disabled={true}
                                                    type="text"
                                                    name="path"
                                                   value={path} />
                                    </p>

                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Content</strong>
                                        </label>
                                        <textarea className={`w3-col w3-input`}
                                                   onChange={onContentChange}
                                                   disabled={false}
                                                       rows={25}
                                                       type="text"
                                                       name="content"
                                                      value={content} />
                                    </p>

                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to={`/transfer/${id}`}
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <button className="w3-col m3 w3-btn w3-blue w3-round-xlarge w3-right"
                                                   type="button"
                                                onClick={onFormSubmitClick}
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;UPDATE
                                        </button>
                                    </p>
                                </form>
                            }

                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default SubmissionRemoteTransferUpdate;
