import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys } from 'humps';
import {
    DIGITALSCANTRON_DASHBOARD_API_ENDPOINT
} from "../Constants/API";


export default function getDashboardAPI(onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();
    axios.get(DIGITALSCANTRON_DASHBOARD_API_ENDPOINT).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}
