import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { useTabIndex } from 'react-tabindex';
import Scroll from 'react-scroll';
import { ErrorBox } from "../Element/ErrorBox";
import { Link, Navigate, useLocation } from "react-router-dom";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { putUserAPI } from "../../API/user";
import { isEmpty } from 'lodash';
import { USER_ROLE_ID_OPTIONS, getUserRoleIdOption, LANGUAGE_FIELD_OPTIONS, getLanguageOption } from "../../Constants/FieldOptions";


function UserUpdate() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const [errors, setErrors] = useState({});
    const tabIndex = useTabIndex();
    // const [uuid, setUuid] = useLocalStorage("DST_USER_UUID", "");
    // const [createdTime, setCreatedTime] = useLocalStorage("DST_USER_CREATED_TIME", "");
    const [email, setEmail] = useLocalStorage("DST_USER_EMAIL", "");
    const [firstName, setFirstName] = useLocalStorage("DST_USER_FIRST_NAME", "");
    // const [imageURL, setImageURL] = useLocalStorage("DST_USER_IMAGE_URL", "");
    // const [joinedTime, setJoinedTime] = useLocalStorage("DST_USER_JOINED_TIME", "");
    // const [roleId, setRoleId] = useLocalStorage("DST_USER_LANGUAGE", "");
    const [lastName, setLastName] = useLocalStorage("DST_USER_LAST_NAME", "");
    // const [lexicalName, setLexicalName] = useLocalStorage("DST_USER_LEXICAL_NAME", "");
    // const [modifiedTime, setModifiedTime] = useLocalStorage("DST_USER_MODIFIED_TIME", "");
    // const [name, setName] = useLocalStorage("DST_USER_NAME", "");
    // const [roleId, setRoleId] = useLocalStorage("DST_USER_ROLE_ID", "");
    // const [status, setStatus] = useLocalStorage("DST_USER_STATUS", "");
    // const [tenantId, setTenantId] = useLocalStorage("DST_USER_TENANT_ID", "");
    // const [timezone, setTimezone] = useLocalStorage("DST_USER_TIMEZONE", "");
    const roleID = localStorage.getItem("DST_USER_ROLE_ID");
    const [roleIdOption, setRoleIdOption] = useState(getUserRoleIdOption(roleID));

    const language = localStorage.getItem("DST_USER_LANGUAGE");
    const c = getLanguageOption(language);
    const [languageOption, setLanguageOption] = useState(c);

    // --- API --- //
    const [forceUrl, setForceUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-2];

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Update Users";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    //
    // COMPONENT FUNCTIONS
    //

    // --- Form ---

    function onFirstNameChange(e) {
        setFirstName(e.target.value);
    }

    function onLastNameChange(e) {
        setLastName(e.target.value);
    }

    function onEmailChange(e) {
        setEmail(e.target.value);
    }

    function onRoleIdSelectChange(selectedOption) {
        setRoleIdOption(selectedOption);
    }

    function onLanguageSelectChange(selectedOption) {
        setLanguageOption(selectedOption);
    }

    function onFormSubmitClick(e) {
        setIsLoading(true);
        const putData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            roleId: isEmpty(roleIdOption) ? "" : roleIdOption.value,
            language: isEmpty(languageOption) ? "" : languageOption.value,

        };
        putUserAPI(id, putData, onCreateSuccess, onCreateError, onCreateDone);
    }

    //
    // API
    //

    function onCreateSuccess(user) {
        setForceUrl("/user/"+id);
    }

    function onCreateError(err) {
        setErrors(err);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onCreateDone() {
        setIsLoading(false);
    }

    //
    // MISC
    //


    //
    // COMPONENT RENDER
    //

    if (forceUrl !== "") {
        return <Navigate to={forceUrl} />;
    }


    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/user/${id}`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-container">
                                <h2><strong><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Update Users</strong></h2>
                                <hr className="w3-grey" />
                            </div>
                            {isLoading
                                ? <>
                                sending ...
                                </>
                                : <form className="w3-container">
                                    <ErrorBox errors={errors} />
                                    <p>Please fill in all the required fields (*).</p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>First Name (*)</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input w3-border ${errors.firstName ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="firstName"
                                                   value={firstName}
                                                onChange={onFirstNameChange}
                                             placeholder={"Please fill first name."}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.firstName && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.firstName}</strong>}
                                        </label>}
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Last Name (*)</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input w3-border ${errors.lastName ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="lastName"
                                                   value={lastName}
                                                onChange={onLastNameChange}
                                             placeholder={"Please fill last name."}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.lastName && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.lastName}</strong>}
                                        </label>}
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong>Email (*)</strong>
                                        </label>
                                        <input className={`w3-col m5 w3-input w3-border ${errors.email ? 'w3-border-red' : ''}`}
                                                    type="text"
                                                    name="email"
                                                   value={email}
                                                onChange={onEmailChange}
                                             placeholder={"Please fill email."}
                                                tabIndex={tabIndex}
                                        />
                                        {errors && errors.email && <label className="w3-col">
                                            {<strong className="w3-text-red">{errors.email}</strong>}
                                        </label>}
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong className={`${errors.roleId ? 'w3-text-red' : ''}`}>RoleId (*)</strong>
                                        </label>
                                        <Select className={`w3-col m3 ${errors.roleId ? 'w3-border w3-border-red' : ''}`}
                                                options={USER_ROLE_ID_OPTIONS}
                                               onChange={onRoleIdSelectChange}
                                                   name="roleId"
                                                  value={roleIdOption}
                                            placeholder="Please select role"
                                               tabIndex={tabIndex}
                                        />
                                        {errors.roleId && <label className="w3-col m7 w3-text-red">{errors.roleId}</label>}
                                    </p>
                                    <p className="w3-row">
                                        <label className="w3-col">
                                            <strong className={`${errors.language ? 'w3-text-red' : ''}`}>Language (*)</strong>
                                        </label>
                                        <Select className={`w3-col m2 ${errors.language ? 'w3-border w3-border-red' : ''}`}
                                                options={LANGUAGE_FIELD_OPTIONS}
                                               onChange={onLanguageSelectChange}
                                                   name="language"
                                                  value={languageOption}
                                            placeholder="Please select language"
                                               tabIndex={tabIndex}
                                        />
                                        {errors.language && <label className="w3-col m2 w3-text-red">{errors.language}</label>}
                                        <label className="w3-col w3-text-grey w3-tiny">THE LANGUAGE USED THROUGHOUT THE APPLICATION</label>
                                    </p>
                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-red w3-round-xlarge w3-left"
                                                     to={`/user/${id}`}
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-times-circle" aria-hidden="true"></i>&nbsp;CANCEL
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <button className="w3-col m3 w3-btn w3-green w3-round-xlarge w3-right"
                                                  onClick={onFormSubmitClick}
                                                     type="button"
                                                 tabIndex={tabIndex}>
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;SAVE
                                        </button>
                                    </p>
                                </form>
                            }
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default UserUpdate;
