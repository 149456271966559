export const LETTER_FIELD_OPTIONS = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'C', label: 'D' }
];

export const LANGUAGE_FIELD_OPTIONS = [
    { value: 'EN', label: 'English' },
];

export function getLanguageOption(value) {
    for (let item of LANGUAGE_FIELD_OPTIONS) {
        if (item.value === value.replace(/['"]+/g, '')) {
            return item;
        }
    }
    return null;
}

export const USER_ROLE_IDS = {
    1: "Administrator",
    2: "Senior Teacher",
    3: "Teacher",
    4: "Student",
    99: "Root",
};

export const USER_ROLE_ID_OPTIONS = [
    { value: 1, label: 'Administrator' },
    { value: 2, label: 'Senior Teacher' },
    { value: 3, label: 'Teacher' },
    { value: 4, label: 'Student' }
];

export function getUserRoleIdOption(value) {
    for (let item of USER_ROLE_ID_OPTIONS) {
        if (item.value === parseInt(value)) {
            return item;
        }
    }
    return null;
}

export const USER_STATES = {
    1: "Active",
    0: "Inactive"
};

export const USER_STATE_OPTIONS = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Inactive' }
];
