import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import {
    DIGITALSCANTRON_USER_LIST_CREATE_API_ENDPOINT,
    DIGITALSCANTRON_USER_DETAIL_UPDATE_DELETE_API_ENDPOINT
} from "../Constants/API";


export function getUsersAPI(offset=0, limit=10, filtersMap=new Map(), onSuccessCallback, onErrorCallback, onDoneCallback) {
    // Generate the URL from the map.
    // Note: Learn about `Map` iteration via https://hackernoon.com/what-you-should-know-about-es6-maps-dc66af6b9a1e
    let aURL = DIGITALSCANTRON_USER_LIST_CREATE_API_ENDPOINT+"?offset="+offset+"&limit="+limit;
    filtersMap.forEach(
        (value, key) => {
            let decamelizedkey = decamelize(key)
            aURL += "&"+decamelizedkey+"="+value;
        }
    )

    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function postUserAPI(data, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    // To Snake-case for API from camel-case in React.
    let decamelizedData = decamelizeKeys(data);

    // BUGFIX
    decamelizedData.image_url = decamelizedData.image_u_r_l;
    decamelizedData.startdate = decamelizedData.start_date;

    axios.post(DIGITALSCANTRON_USER_LIST_CREATE_API_ENDPOINT, decamelizedData).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}


export function getUserDetailAPI(userID, onSuccessCallback, onErrorCallback, onDoneCallback) {
    let aURL = DIGITALSCANTRON_USER_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", userID);
    const axios = getCustomAxios();
    axios.get(aURL).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // BUGFIX
        data.imageURL = data.imageUrl;
        data.startDateTime = data.startdateTime;

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}

export function putUserAPI(userID, data, onSuccessCallback, onErrorCallback, onDoneCallback) {
    const axios = getCustomAxios();

    let aURL = DIGITALSCANTRON_USER_DETAIL_UPDATE_DELETE_API_ENDPOINT.replace("xxx", userID);

    // To Snake-case for API from camel-case in React.
    let decamelizedData = decamelizeKeys(data);

    // BUGFIX
    decamelizedData.image_url = decamelizedData.image_u_r_l;
    decamelizedData.startdate = decamelizedData.start_date;

    axios.put(aURL, decamelizedData).then((successResponse) => {
        const responseData = successResponse.data;

        // Snake-case from API to camel-case for React.
        const data = camelizeKeys(responseData);

        // Return the callback data.
        onSuccessCallback(data);
    }).catch( (exception) => {
        let errors = camelizeKeys(exception);
        onErrorCallback(errors);
    }).then(onDoneCallback);
}
