import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTabIndex } from 'react-tabindex';
import { DIGITALSCANTRON_EVALUATION_EXPORT_API_ENDPOINT } from "../../Constants/API";
import { getAccessTokenFromLocalStorage } from "../../Helpers/jwtUtility";

function EvaluationExport() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();

    // --- API --- //
    const [isLoading, setIsLoading] = useState(false);
    const pathname = useLocation().pathname;
    const paths = pathname.split('/');
    const id = paths[paths.length-2];

    useEffect(() => {
        document.title = "Export Evaluation";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);

    //
    // API
    //

    //
    // COMPONENT FUNCTIONS
    //

    function onFormSubmitClick(e) {
        setIsLoading(true);

        const token = getAccessTokenFromLocalStorage();
        const url = DIGITALSCANTRON_EVALUATION_EXPORT_API_ENDPOINT+"?token="+token+"&id="+id;
        console.log("EvaluationExport | onFormSubmitClick: ",url);

        // The following code will open up a new browser tab and load up the
        // URL that you inputted.
        var win = window.open(url, '_blank');
        win.focus();

        // Add minor delay and then run to remove the button ``disable`` state
        // so the user is able to click the download button again.
        setTimeout(() => {
            setIsLoading(false);
        }, 100); // 0.10 seconds.
    }

    //
    // API Handling
    //

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/evaluation/${id}`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            {/*
                                ------
                                HEADER
                                ------
                            */}
                            <div className="w3-container" style={{marginTop:"20px"}}>
                                <div className="w3-display-container" style={{height:"30px"}}>
                                    <div className="w3-display-left">
                                        {/* Desktop*/}
                                        <div className="w3-hide-small w3-hide-medium">
                                            <h1>
                                                <strong><i className="fa fa-cloud-download" aria-hidden="true"></i>&nbsp;Export Evaluation</strong>
                                            </h1>
                                        </div>
                                        {/* Tablet / Mobile */}
                                        <div className="w3-hide-large">
                                            <h2>
                                                <strong><i className="fa fa-cloud-download" aria-hidden="true"></i>&nbsp;Export Evaluation</strong>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="w3-display-right">
                                        {/* Do nothing. */}
                                    </div>
                                </div>
                                <hr className="w3-light-grey " style={{width:"100%",height:"2px",}} />
                            </div>
                            {/*
                                ----------
                                end HEADER
                                ----------
                            */}

                            {/*
                                -----
                                TABLE
                                -----
                            */}
                            {isLoading
                                ? <form className="w3-container">
                                    <div className="w3-col">
                                       <div className="w3-responsive">
                                           <div className="w3-panel w3-pale-blue">
                                               <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                               <p>Please wait while we fetch the data.</p>
                                           </div>
                                       </div>
                                   </div>
                                </form>
                                : <form className="w3-container">
                                    <p>
                                    You are about to <strong>export</strong> the evaluation by downloading a copy of a <i>json</i> file. You can use this file to import evaluations.
                                    </p>
                                    <p className="w3-row">
                                        <hr className="w3-col w3-grey" />
                                        <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                     to={`/evaluation/${id}`}
                                               tabIndex={tabIndex}>
                                            <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                        </Link>
                                        <span className="w3-col m6">&nbsp;</span>
                                        <button className="w3-col m3 w3-btn w3-green w3-round-xlarge w3-right"
                                                  onClick={onFormSubmitClick}
                                                     type="button"
                                                 tabIndex={tabIndex}>
                                            <i className="fa fa-download" aria-hidden="true"></i>&nbsp;DOWNLOAD
                                        </button>
                                    </p>
                                </form>
                            }
                            {/*
                                ----------
                                end TABLE
                                ----------
                            */}
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default EvaluationExport;
