import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTabIndex } from 'react-tabindex';
import getDashboardAPI from "../../API/dashboard";
import { ErrorBox } from "../Element/ErrorBox";


function Dashboard() {
    const tabIndex = useTabIndex();
    const [dashboard, setDashboard] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        document.title = "Dashboard";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        let mounted = true;

        getDashboardAPI((responseData) => {
            if (mounted) {
                console.log(responseData);
                setDashboard(responseData);
            }
        }, (apiErrors) => {
            setErrors(apiErrors);
        }, () => {
            setIsLoading(false);
        });

        return () => mounted = false;
    }, []);

    return (
        <>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-container">
                            <ErrorBox errors={errors} />
                            <h1><strong><i className="fa fa-dashboard" aria-hidden="true"></i>&nbsp;Dashboard</strong></h1>
                            <hr />
                            <h2><strong><i className="fa fa-clock-o" aria-hidden="true"></i>&nbsp;Quick Actions</strong></h2>
                        </div>
                        <div>
                            <div className="w3-row">
                                <div className="w3-col m4 w3-padding">
                                    <div class="w3-card w3-pale-green w3-container">
                                        <h3><strong><i className="fa fa-braille" aria-hidden="true"></i>&nbsp;Evaluations</strong></h3>
                                        <p>Click here to view evaluations.</p>
                                        <p>
                                            <Link className="w3-btn w3-block w3-blue w3-round-xlarge"
                                                         to="/evaluations"
                                                   tabIndex={tabIndex}>View&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="w3-col m4 w3-padding">
                                    <div class="w3-card w3-pale-red w3-container">
                                        <h3><strong><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add Evaluation</strong></h3>
                                        <p>Click here to create an evaluation.</p>
                                        <p>
                                            <Link className="w3-btn w3-block w3-blue w3-round-xlarge"
                                                         to="/evaluations/add"
                                                   tabIndex={tabIndex}>View&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="w3-col m4 w3-padding">
                                    <div class="w3-card w3-pale-blue w3-container">
                                        <h3><strong><i className="fa fa-table" aria-hidden="true"></i>&nbsp;Submissions</strong></h3>
                                        <p>Click here to view submissions.</p>
                                        <p>
                                            <Link className="w3-btn w3-block w3-blue w3-round-xlarge"
                                                         to="/submissions"
                                                   tabIndex={tabIndex}>View&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoading
                            ? <div className="w3-container">
                                <hr />
                                <div className="w3-light-grey w3-padding w3-margin-bottom w3-round-xlarge">
                                    <h2 className="w3-center">
                                        <i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading ...
                                    </h2>
                                </div>
                            </div>
                            : <div className="w3-container w3-margin-bottom">
                                <hr />
                                {dashboard && dashboard.submissions && dashboard.submissions.length > 0
                                    ? <div>
                                        <h2><strong>
                                            <i className="fa fa-bullhorn" aria-hidden="true"></i>&nbsp;Recent Submissions
                                        </strong></h2>
                                        <ul class="w3-ul">
                                            {dashboard.submissions.map((submission) => (
                                                <>
                                                <li className="w3-cell-row w3-border-bottom" style={{padding:"0px",}}>
                                                    <span className="w3-cell w3-container">
                                                        <h4>
                                                            <strong>{submission.studentName}</strong>
                                                        </h4>
                                                        <p className="w3-text-gray">
                                                            <strong><i className="fa fa-clock-o" aria-hidden="true"></i>&nbsp;Created:</strong>&nbsp;{new Date(submission.createdTime).toLocaleString() }
                                                        </p>
                                                        <p className="w3-text-gray">
                                                            <strong><i className="fa fa-table" aria-hidden="true"></i>&nbsp;Evaluation:</strong>&nbsp;{submission.evaluationName}
                                                        </p>
                                                    </span>
                                                    <Link to={`/submission/${submission.id}`}
                                                       style={{textDecoration:"none",padding:"0px",}}
                                                   className="w3-cell w3-cell-middle w3-padding w3-center"
                                                    tabIndex={tabIndex}>
                                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </Link>
                                                </li>
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                    : <div className="w3-light-grey w3-padding w3-margin-bottom w3-round-xlarge">
                                        <h2 className="w3-center">
                                            <i className="fa fa-bullhorn" aria-hidden="true"></i>&nbsp;No Submissions
                                        </h2>
                                    </div>
                                }
                            </div>
                        }
                        <div className="w3-container w3-margin-bottom">
                            <hr />
                            {dashboard && dashboard.tenant
                                ? <div>
                                    <h2><strong>
                                        <i className="fa fa-line-chart" aria-hidden="true"></i>&nbsp;Created Submissions Stats
                                    </strong></h2>
                                    <ul class="w3-ul">
                                        <li className="w3-cell-row w3-border-bottom" style={{padding:"0px",}}>
                                            <span className="w3-cell w3-container">
                                                <p className="w3-text-gray"><strong>Today:</strong>&nbsp;{dashboard.tenant.todaysSubmissionsCount}</p>
                                                <p className="w3-text-gray"><strong>Yesterday:</strong>&nbsp;{dashboard.tenant.yesterdaysSubmissionsCount}</p>
                                                <p className="w3-text-gray"><strong>This Week:</strong>&nbsp;{dashboard.tenant.thisWeeksSubmissionsCount}</p>
                                                <p className="w3-text-gray"><strong>Last Week:</strong>&nbsp;{dashboard.tenant.lastWeeksSubmissionsCount}</p>
                                                <p className="w3-text-gray"><strong>This Month:</strong>&nbsp;{dashboard.tenant.thisMonthsSubmissionsCount}</p>
                                                <p className="w3-text-gray"><strong>Last Month:</strong>&nbsp;{dashboard.tenant.lastMonthsSubmissionsCount}</p>
                                                <p className="w3-text-gray"><strong>This Year:</strong>&nbsp;{dashboard.tenant.thisYearsSubmissionsCount}</p>
                                                <p className="w3-text-gray"><strong>Last Year:</strong>&nbsp;{dashboard.tenant.lastYearsSubmissionsCount}</p>
                                                <p className="w3-text-gray"><strong>Total:</strong>&nbsp;{dashboard.tenant.totalSubmissionsCount}</p>
                                            </span>

                                        </li>
                                    </ul>
                                </div>
                                : <div className="w3-light-grey w3-padding w3-margin-bottom w3-round-xlarge">
                                    <h2 className="w3-center">
                                        <i className="fa fa-bullhorn" aria-hidden="true"></i>&nbsp;No Submissions
                                    </h2>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>
        </>
    );
}

export default Dashboard;
