import React, { useState, useEffect } from "react";
import Scroll from 'react-scroll';
import { useTabIndex } from 'react-tabindex';
import { ErrorBox } from "../Element/ErrorBox";
import { Link, Navigate } from "react-router-dom";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { postEvaluationAPI } from "../../API/evaluation";
import { QuestionRow } from "../Element/QuestionRowChoiceCol";


function EvaluationCreate() {
    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [profile] = useLocalStorage("DIGITALSCANTRON_PROFILE");
    const [name, setName] = useState("");
    const [alternateName, setAlternateName] = useState("");
    const [description, setDescription] = useState("");
    const [imageURL, setImageUrl] = useState("");
    const [numberOfMultipleChoiceQuestions, setNumberOfMultipleChoiceQuestions] = useState("");
    const [numberOfTextareaQuestions, setNumberOfTextareaQuestions] = useState("");
    const [numberOfAreas, setNumberOfAreas] = useState("");
    const [durationInMinutes, setDurationInMinutes] = useState("");
    const [beginTime, setBeginTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [grade, setGrade] = useState("");
    const [year, setYear] = useState("");
    const [city, setCity] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [schoolCode, setSchoolCode] = useState("");
    const [forceUrl, setForceUrl] = useState("");
    const [errors, setErrors] = useState({});
    const [answers, setAnswers] = useState([])
    const [customId, setCustomId] = useState("");
    const [category, setCategory] = useState("");

    // --- useEffect --- //
    useEffect(() => {
        document.title = "Create Evaluation";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    }, []);

    //
    // COMPONENT FUNCTIONS
    //

    // --- Form ---

    function onNameChange(e) {
        setName(e.target.value);
    }

    function onAlternateNameChange(e) {
        setAlternateName(e.target.value);
    }

    function onDescriptionChange(e) {
        setDescription(e.target.value);
    }

    function onCustomIDChange(e) {
        setCustomId(e.target.value);
    }

    function onCategoryChange(e) {
        setCategory(e.target.value);
    }

    function onImageUrlChange(e) {
        setImageUrl(e.target.value);
    }

    function onnumberOfMultipleChoiceQuestionsChange(e) {
        let noq = e.target.value;
        setNumberOfMultipleChoiceQuestions(noq);
    }

    function onNumberOfTextareaQuestionsChange(e) {
        let noq = e.target.value;
        console.log("noq:", noq);
        setNumberOfTextareaQuestions(noq);
    }

    function onNumberOfAreasChange(e) {
        setNumberOfAreas(e.target.value);
    }

    function onDurationInMinutesChange(e) {
        setDurationInMinutes(e.target.value);
    }

    function onBeginTimeChange(e) {
        setBeginTime(e.target.value);
    }

    function onEndTimeChange(e) {
        setEndTime(e.target.value);
    }

    function onGradeChange(e) {
        setGrade(e.target.value);
    }

    function onYearChange(e) {
        setYear(e.target.value);
    }

    function onCityChange(e) {
        setCity(e.target.value);
    }

    function onSchoolNameChange(e) {
        setSchoolName(e.target.value);
    }
    function onSchoolCodeChange(e) {
        setSchoolCode(e.target.value);
    }

    function onFormSubmitClick(e) {

        let bt = beginTime === "" ? 0 : new Date(beginTime).getTime();
        let et = endTime === "" ? 0 : new Date(endTime).getTime();
        const postData = {
            tenant_id: profile.tenantId,
            name: name,
            alternateName: alternateName,
            description: description,
            imageURL: imageURL,
            numberOfMultipleChoiceQuestions: Math.abs(parseInt(numberOfMultipleChoiceQuestions)),
            numberOfTextareaQuestions: Math.abs(parseInt(numberOfTextareaQuestions)),
            numberOfAreas: Math.abs(parseInt(numberOfAreas)),
            durationInMinutes: Math.abs(parseInt(durationInMinutes)),
            beginTime: bt,
            endTime: et,
            grade: Math.abs(parseInt(grade)),
            year: Math.abs(parseInt(year)),
            city: city,
            schoolName: schoolName,
            schoolCode: schoolCode,
            answers: JSON.stringify(answers),
            customId: customId,
            category: category,
        };
        postEvaluationAPI(postData, onCreateSuccess, onCreateError, onCreateDone);
    }

    //
    // API
    //

    function onCreateSuccess(evaluation) {
        setForceUrl("/evaluations");
    }

    function onCreateError(err) {
        console.log("onCreateError | err:", err);
        setErrors(err);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onCreateDone() {}

    //
    // MISC
    //

    // Generate an array of a range of all the numbers from 1 to the questionsCount.
    // Each integer value in this array will be our question ID.
    const start = 1;
    const end = numberOfMultipleChoiceQuestions;
    const questionPKs = [...Array(end - start + 1).keys()].map(x => x + start);; // https://jasonwatmore.com/post/2021/10/02/vanilla-js-create-an-array-with-a-range-of-numbers-in-a-javascript

    function onQuestionChoiceChange(e, questionPK, choicePK, letter) {
        console.log(e.target.value);
        console.log("questionPK:", questionPK);
        console.log("choicePK:",choicePK);
        console.log("letter:",letter);
        console.log();

        var answersCopy = {...answers};

        // let ans = Object.assign(answers);
        answersCopy[questionPK] = letter;
        setAnswers(answersCopy);

        console.log("onQuestionChoiceChange|answersCopy:", answersCopy);
    }

    //
    // COMPONENT RENDER
    //

    if (forceUrl !== "") {
        return <Navigate to={forceUrl} />;
    }

    var fixedTodaysDate = new Date().toISOString().substring(0, 10)

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to="/evaluations"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-container">
                                <h2><strong><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add Evaluations</strong></h2>
                                <hr className="w3-grey" />
                            </div>
                            <form className="w3-container">
                                <ErrorBox errors={errors} />
                                <p>Please fill in all the required fields (*).</p>
                                <p className="w3-row">
                                    <label className="w3-col">
                                        <strong>Name (*)</strong>
                                    </label>
                                    <input className={`w3-col m5 w3-input w3-border ${errors.name ? 'w3-border-red' : ''}`}
                                                type="text"
                                                name="name"
                                              value={name}
                                           onChange={onNameChange}
                                        placeholder={"Please fill name."}
                                           tabIndex={tabIndex}
                                    />
                                    {errors && errors.name && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.name}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>Alternate Name (*)</strong></label>
                                    <input className={`w3-col m7 w3-input w3-border ${errors.alternateName ? 'w3-border-red' : ''}`}
                                                type="text" name="alternateName"
                                               value={alternateName}
                                            onChange={onAlternateNameChange}
                                         placeholder={"Please fill alternate name."}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.alternateName && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.alternateName}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>Description (*)</strong></label>
                                    <textarea className={`w3-col m7 w3-input w3-border ${errors.description ? 'w3-border-red' : ''}`}
                                                   type="text"
                                                   name="description"
                                                  value={description}
                                               onChange={onDescriptionChange}
                                                   rows={6}
                                            placeholder={"Please fill description."}
                                               tabIndex={tabIndex}>
                                    </textarea>
                                    {errors && errors.description && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.description}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col">
                                        <strong>Custom ID</strong>
                                    </label>
                                    <input className={`w3-col m5 w3-input w3-border ${errors.customId ? 'w3-border-red' : ''}`}
                                                type="text"
                                                name="name"
                                              value={customId}
                                           onChange={onCustomIDChange}
                                        placeholder={"Please write a custom id."}
                                           tabIndex={tabIndex}
                                    />
                                    {errors && errors.customId && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.customId}</strong>}
                                    </label>}
                                    <p className="w3-col w3-text-grey w3-tiny">CUSTOM ID REFERS TO AN ID VALUE THATS INTERNAL AND SPECIFIC TO YOUR SYSTEM. THIS FIELD IS OPTIONAL SO LEAVE BLANK IF YOU DO NOT HAVE A CUSTOM VALUE SPECIFIED. THIS VALUE WILL BE MUST BE UNIQUE ORGANIZATION.</p>
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col">
                                        <strong>Category</strong>
                                    </label>
                                    <input className={`w3-col m5 w3-input w3-border ${errors.category ? 'w3-border-red' : ''}`}
                                                type="text"
                                                name="name"
                                              value={category}
                                           onChange={onCategoryChange}
                                        placeholder={"Please write a category."}
                                           tabIndex={tabIndex}
                                    />
                                    {errors && errors.category && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.category}</strong>}
                                    </label>}
                                    <p className="w3-col w3-text-grey w3-tiny">CATEGORY IS AN OPTIONAL FIELD YOU CAN FILL IF YOU WOULD LIKE YOUR EVALUATIONS TO BE CATEGORIZED BY WHATEVER NAMING STRUCTURE YOU WANT.</p>
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>Image URL (*)</strong></label>
                                    <input className={`w3-col m9 w3-input w3-border ${errors.imageURL ? 'w3-border-red' : ''}`}
                                                type="text"
                                                name="imageURL"
                                               value={imageURL}
                                            onChange={onImageUrlChange}
                                         placeholder={"Please fill image url."}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.imageURL && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.imageURL}</strong>}
                                    </label>}
                                    <p className="w3-col w3-text-grey w3-tiny">IMAGE URL IS LINK TO THE IMAGE THAT YOU WOULD LIKE TO DISPLAY FOR THE STUDENTS WHEN THEY LAND ON THE EVALUATION PAGE.</p>
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>Duration in Minutes (*)</strong></label>
                                    <input className={`w3-col m2 w3-input w3-border ${errors.durationInMinutes ? 'w3-border-red' : ''}`}
                                                type="number"
                                                name="durationInMinutes"
                                               value={durationInMinutes}
                                            onChange={onDurationInMinutesChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.durationInMinutes && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.durationInMinutes}</strong>}
                                    </label>}
                                    <p className="w3-col w3-text-grey w3-tiny">WHEN STUDENTS START THE EVALUATION, DURATION CONTROLS HOW LONG THE STUDENT HAS PERMISSION TO WRITE THE EVALUATION BEFORE BEING PENALIZING WITH A `LATE` STATUS.</p>
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>Begin Time</strong></label>
                                    <input className={`w3-col m5 w3-input w3-border ${errors.beginTime ? 'w3-border-red' : ''}`}
                                                type="datetime-local"
                                                name="beginTime"
                                               value={beginTime}
                                                 min={fixedTodaysDate}
                                            onChange={onBeginTimeChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.beginTime && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.beginTime}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>End Time</strong></label>
                                    <input className={`w3-col m5 w3-input w3-border ${errors.endTime ? 'w3-border-red' : ''}`}
                                                type="datetime-local"
                                                name="endTime"
                                               value={endTime}
                                                 min={fixedTodaysDate}
                                            onChange={onEndTimeChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.endTime && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.endTime}</strong>}
                                    </label>}
                                    <p className="w3-col w3-text-grey w3-tiny">SPECIFY THE LAST DATE/TIME THAT THE SYSTEM WILL ACCEPT THE SUBMISSIONS WITHOUT PENALIZING THE STUDENT WITH A LATE STATUS ON THEIR SUBMISSION. NOT SPECIFYING RESULTS IN EVALUATION BEING OPEN-ENDED.</p>
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>School Name</strong></label>
                                    <input className={`w3-col m6 w3-input w3-border ${errors.schoolName ? 'w3-border-red' : ''}`}
                                                type="text"
                                                name="schoolName"
                                               value={schoolName}
                                            onChange={onSchoolNameChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.schoolName && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.schoolName}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>School Code</strong></label>
                                    <input className={`w3-col m6 w3-input w3-border ${errors.schoolCode ? 'w3-border-red' : ''}`}
                                                type="text"
                                                name="schoolCode"
                                               value={schoolCode}
                                            onChange={onSchoolCodeChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.schoolCode && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.schoolCode}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>City</strong></label>
                                    <input className={`w3-col m4 w3-input w3-border ${errors.city ? 'w3-border-red' : ''}`}
                                                type="text"
                                                name="city"
                                               value={city}
                                            onChange={onCityChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.city && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.city}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>Grade (*)</strong></label>
                                    <input className={`w3-col m2 w3-input w3-border ${errors.grade ? 'w3-border-red' : ''}`}
                                                type="number"
                                                name="grade"
                                               value={grade}
                                            onChange={onGradeChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.grade && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.grade}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>Year (*)</strong></label>
                                    <input className={`w3-col m2 w3-input w3-border ${errors.year ? 'w3-border-red' : ''}`}
                                                type="number"
                                                name="year"
                                               value={year}
                                            onChange={onYearChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                    />
                                    {errors && errors.year && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.year}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col"><strong>Number of Areas (*)</strong></label>
                                    <input className={`w3-col m2 w3-input w3-border ${errors.numberOfAreas ? 'w3-border-red' : ''}`}
                                                type="number"
                                                name="numberOfAreas"
                                               value={numberOfAreas}
                                            onChange={onNumberOfAreasChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                                 min={0}
                                    />
                                    {errors && errors.numberOfAreas && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.numberOfAreas}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col m12"><strong># of Multiple-Choice Questions (*)</strong></label>
                                    <input className={`w3-col m2 w3-input w3-border ${errors.numberOfMultipleChoiceQuestions ? 'w3-border-red' : ''}`}
                                                type="number"
                                                name="numberOfMultipleChoiceQuestions"
                                               value={numberOfMultipleChoiceQuestions}
                                            onChange={onnumberOfMultipleChoiceQuestionsChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                                 min={0}
                                    />
                                    {errors && errors.numberOfMultipleChoiceQuestions && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.numberOfMultipleChoiceQuestions}</strong>}
                                    </label>}
                                </p>
                                <p className="w3-row">
                                    <label className="w3-col m12"><strong># of Text Questions</strong></label>
                                    <input className={`w3-col m2 w3-input w3-border ${errors.numberOfTextareaQuestions ? 'w3-border-red' : ''}`}
                                                type="number"
                                                name="numberOfTextareaQuestions"
                                               value={numberOfTextareaQuestions}
                                            onChange={onNumberOfTextareaQuestionsChange}
                                         placeholder={"-"}
                                            tabIndex={tabIndex}
                                                 min={0}
                                    />
                                    {errors && errors.numberOfTextareaQuestions && <label className="w3-col">
                                        {<strong className="w3-text-red">{errors.numberOfTextareaQuestions}</strong>}
                                    </label>}
                                </p>

                                {/* BULK QUESTIONS TABLE */}
                                <p className={`w3-row`}>
                                    <hr className="w3-col w3-grey" />
                                    <h4 className="w3-col m6 w3-left"><strong>Correct Answers</strong></h4>

                                    <p className="w3-col">Please add the correct answers to this evaluation</p>
                                    <p className="w3-col">

                                    <div className={`w3-col m6 ${errors && errors.answers !== undefined ? 'w3-border w3-border-red' : ''} w3-padding`}>
                                        {/* START ROW */}
                                        <div className="w3-white w3-round w3-border w3-border-grey w3-card">
                                            <div>
                                                {/* Load all the questions. */}
                                                {questionPKs.map((questionPK) =>
                                                    <QuestionRow key={`q-${questionPK}`}
                                                          questionPK={questionPK}
                                                        optionsCount={4}
                                              onQuestionChoiceChange={onQuestionChoiceChange}
                                                             answers={answers}
                                                            tabIndex={tabIndex}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {/* END ROW */}
                                    </div>
                                    </p>
                                    {errors && errors.answers !== undefined &&
                                        <p className="w3-col w3-text-red">{errors.answers}</p>
                                    }
                                </p>
                                {/* end BULK QUESTIONS TABLE */}
                                <p className="w3-row">
                                    <hr className="w3-col w3-grey" />
                                    <Link className="w3-col m3 w3-btn w3-red w3-round-xlarge w3-left"
                                                 to="/evaluations"
                                           tabIndex={tabIndex}>
                                        <i className="fa fa-times-circle" aria-hidden="true"></i>&nbsp;CANCEL
                                    </Link>
                                    <span className="w3-col m6">&nbsp;</span>
                                    <button className="w3-col m3 w3-btn w3-green w3-round-xlarge w3-right"
                                              onClick={onFormSubmitClick}
                                                 type="button"
                                             tabIndex={tabIndex}>
                                        <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;SAVE
                                    </button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default EvaluationCreate;
