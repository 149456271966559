import React, { useEffect } from "react";
import { useTabIndex } from 'react-tabindex';
import { Link } from "react-router-dom";
// import ReactJson from 'react-json-view'; // DEPRECATED https://github.com/mac-s-g/react-json-view/issues/403
import useLocalStorage from "../../Hooks/useLocalStorage";
import { DIGITALSCANTRON_SAMPLE_SUBMISSION_CREATE_API_ENDPOINT } from "../../Constants/API";


function RemotePayloadInfo() {
    console.log("RemotePayloadInfo | Started...");

    //
    // COMPONENT STATE MANAGEMENT
    //

    // --- Form ---

    const tabIndex = useTabIndex();
    const [profile] = useLocalStorage("DIGITALSCANTRON_PROFILE");

    // --- API --- //
    const id = profile.tenantId;

    // --- useEffect --- //

    useEffect(() => {
        document.title = "Remote Submission Payload";
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    },[]);


    //
    // MISC
    //

    const remoteSubmissionPayload = [
        {
            "evaluation_id": 1,
            "evaluation_uuid": "6a84f82a-96f1-4890-9147-5e81d04c9029",
            "student_id": "911",
            "student_name": "Aki Kisaragi",
            "student_code": "119",
            "school_name": "Space Knights",
            "school_code": "111",
            "started_time": "2022-02-17T00:59:12.331-05:00",
            "finished_time": "2022-02-17T00:59:21.218-05:00",
            "is_late": false,
            "year": 2022,
            "grade": 12,
            "city": "",
            "answers": [
                {
                    "id": 16,
                    "uuid": "33ed9045-22ad-46e4-b749-596e81c72a80",
                    "submission_id": 5,
                    "tenant_id": 1,
                    "question_number": 1,
                    "actual_letter": "A",
                    "expected_letter": "A",
                    "actual_text": "",
                    "type": 1,
                    "is_correct": true,
                    "started_time": "2022-02-17T00:59:12.331-05:00",
                    "finished_time": "2022-02-17T00:59:14.038-05:00",
                    "is_late": false
                },
                {
                    "id": 17,
                    "uuid": "91afafa5-a040-4869-8778-cb7498fb87f0",
                    "submission_id": 5,
                    "tenant_id": 1,
                    "question_number": 2,
                    "actual_letter": "B",
                    "expected_letter": "B",
                    "actual_text": "",
                    "type": 1,
                    "is_correct": true,
                    "started_time": "2022-02-17T00:59:12.331-05:00",
                    "finished_time": "2022-02-17T00:59:14.995-05:00",
                    "is_late": false
                },
                {
                    "id": 18,
                    "uuid": "0da2d759-5272-4a65-9fba-7f350c0462f6",
                    "submission_id": 5,
                    "tenant_id": 1,
                    "question_number": 3,
                    "actual_letter": "C",
                    "expected_letter": "C",
                    "actual_text": "",
                    "type": 1,
                    "is_correct": true,
                    "started_time": "2022-02-17T00:59:12.331-05:00",
                    "finished_time": "2022-02-17T00:59:15.575-05:00",
                    "is_late": false
                },
                {
                    "id": 19,
                    "uuid": "fa8d6351-138d-4a36-a991-d7f9dc7aa87d",
                    "submission_id": 5,
                    "tenant_id": 1,
                    "question_number": 4,
                    "actual_letter": "",
                    "expected_letter": "",
                    "actual_text": "lalaalla",
                    "type": 2,
                    "is_correct": false,
                    "started_time": "2022-02-17T00:59:12.331-05:00",
                    "finished_time": "2022-02-17T00:59:18.468-05:00",
                    "is_late": false
                },
                {
                    "id": 20,
                    "uuid": "1246f3de-4fb0-4334-a0c1-0ab0cd98c286",
                    "submission_id": 5,
                    "tenant_id": 1,
                    "question_number": 5,
                    "actual_letter": "",
                    "expected_letter": "",
                    "actual_text": "blalblbalbalbalb",
                    "type": 2,
                    "is_correct": false,
                    "started_time": "2022-02-17T00:59:12.331-05:00",
                    "finished_time": "2022-02-17T00:59:21.218-05:00",
                    "is_late": false
                }
            ]
        }
    ];

    //
    // COMPONENT RENDER
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <Link className="w3-large"
                                 to={`/organization/update`}
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col m1 l2 w3-container">
                    {/* Safari fix */}<span className="w3-hide-small w3-hide-medium">&nbsp;</span>
                </div>
                <div className="w3-col m10 l8 w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-container">
                                <h2><strong><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;Payload Information</strong></h2>
                                <hr className="w3-grey" />
                            </div>
                            <form className="w3-container">
                                <p>When DigitalScan sends the evaluation submissions to your remote endpoint, the data is structured as follows. Please note, an array structure is used to send multiple evaluation submissions so your system should be able to handle bulk submissions.</p>
                                <p>Please note if you'd like to use an <strong>example API endpoint</strong> with the <strong>POST</strong> method, feel free to try out the following URL: <strong>{DIGITALSCANTRON_SAMPLE_SUBMISSION_CREATE_API_ENDPOINT}/{id}</strong>. When you are ready, you can see the sample submissions <Link to="/organization/sample-submissions">here</Link>.</p>
                                <div className="w3-col">
                                   <h3><strong>JSON Payload Structure</strong></h3>
                                   <p>Example:</p>
                                   <div className="w3-responsive ">
                                        {/* <ReactJson src={remoteSubmissionPayload} displayDataTypes={false} /> */}
                                        <pre>
                                            {JSON.stringify(remoteSubmissionPayload, null, 2)}
                                        </pre>
                                   </div>
                               </div>

                                <p className="w3-row">
                                    <hr className="w3-col w3-grey" />
                                    <Link className="w3-col m3 w3-btn w3-orange w3-round-xlarge w3-left"
                                                 to={`/organization/update`}
                                           tabIndex={tabIndex}>
                                        <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;BACK
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="w3-col m1 l2 w3-container">
                </div>

            </div>

        </>
    );
}

export default RemotePayloadInfo;
