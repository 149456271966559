import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTabIndex } from 'react-tabindex';
import { getSubmissionRemoteTransfersAPI } from "../../API/submissionRemoteTransfer";
import { PAGINATION_LIMIT } from "../../Constants/App";
import { ErrorBox } from "../Element/ErrorBox";


function SubmissionRemoteTransferList() {
    //
    // State
    //

    const tabIndex = useTabIndex();
    const [isLoading, setIsLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [nextID, setNextID] = useState(0);
    const [offsetHistory, setOffsetHistory] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit] = useState(PAGINATION_LIMIT);
    const [errors, setErrors] = useState({});
    const [isLastPage, setIsLastPage] = useState(false);
    const [sortField, setSortField] = useState("id");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [search, setSearch] = useState("");

    useEffect(() => {
        document.title = "SubmissionRemoteTransfers";
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        let mounted = true;

        let parametersMap = new Map();
        parametersMap.set("state", 1);
        parametersMap.set("sort_order", sortOrder);
        parametersMap.set("sort_field", sortField);
        parametersMap.set("search", search);

        getSubmissionRemoteTransfersAPI(offset, limit, parametersMap, (responseData) => {
            if (mounted) {
                const { nextId, count, results } = responseData;
                if (results === null) {
                    setResults([]);
                } else {
                    setResults(results);
                }
                setNextID(nextId);
                setCount(count);
                // console.log(nextId, count, results);
                setIsLastPage(results.length < PAGINATION_LIMIT);
            }
        }, (apiErrors) => {
            setErrors(apiErrors);
            console.log(apiErrors);
        }, () => {
            setIsLoading(false);
        });

        return () => mounted = false;
    }, [limit, offset, search, sortField, sortOrder]);

    //
    // Event handling.
    //

    function onNextButtonClick(e){
        // Save our current offset to our navigational history.
        offsetHistory.push(offset);
        setOffsetHistory(offsetHistory);

        // Set the current offset to the `next` one so the page reloads.
        setOffset(offset + PAGINATION_LIMIT);

        // Set the `isLoading` to be `true` thus forcing this component to
        // make the API call to the backend and refresh with the latest data.
        setIsLoading(true);
        setErrors({});
    }

    function onPrevioustButtonClick(e){
        // Retrieve from our navigational history the pervious offset ID.
        const previousID = offsetHistory.pop();

        // Set the previous offset ID as the current one so we go back in history.
        setOffset(previousID);

        // Set the `isLoading` to be `true` thus forcing this component to
        // make the API call to the backend and refresh with the latest data.
        setIsLoading(true);
        setErrors({});
    }

    function onSortFieldClick(e, newSortOrder, newSortField) {
        console.log("onSortFieldClick: ",newSortOrder, newSortField);
        setSortOrder(newSortOrder);
        setSortField(newSortField);

        let parametersMap = new Map();
        parametersMap.set("state", 1);
        parametersMap.set("sort_order", newSortOrder);
        parametersMap.set("sort_field", newSortField);
        parametersMap.set("search", search);

        getSubmissionRemoteTransfersAPI(offset, limit, parametersMap, (responseData) => {
            const { nextId, count, results } = responseData;
            if (results === null) {
                setResults([]);
            } else {
                setResults(results);
            }
            setNextID(nextId);
            setCount(count);
            // console.log(nextId, count, results);
            setIsLastPage(results.length < PAGINATION_LIMIT);
        }, (apiErrors) => {
            setErrors(apiErrors);
            console.log(apiErrors);
        }, () => {
            setIsLoading(false);
        });
    }

    function onSearchChange(e) {
        const value = e.target.value;
        setSearch(value);

        let parametersMap = new Map();
        parametersMap.set("state", 1);
        parametersMap.set("sort_order", sortOrder);
        parametersMap.set("sort_field", sortField);
        parametersMap.set("search", search);

        getSubmissionRemoteTransfersAPI(offset, limit, parametersMap, (responseData) => {
            const { nextId, count, results } = responseData;
            if (results === null) {
                setResults([]);
            } else {
                setResults(results);
            }
            setNextID(nextId);
            setCount(count);
            // console.log(nextId, count, results);
            setIsLastPage(results.length < PAGINATION_LIMIT);
        }, (apiErrors) => {
            setErrors(apiErrors);
            console.log(apiErrors);
        }, () => {
            setIsLoading(false);
        });
    }

    //
    // Rendering.
    //

    return (
        <>
            <div className="w3-row w3-margin-top">
                <div className="w3-col w3-container">
                    <Link className="w3-large"
                                 to="/dashboard"
                              style={{textDecoration:"none",}}
                           tabIndex={tabIndex}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back
                    </Link>
                </div>
            </div>
            <div className="w3-row">
                <div className="w3-col w3-container">
                    <div className="w3-white w3-card w3-margin-top w3-border w3-border-grey w3-round-xlarge">
                        <div className="w3-padding">
                            <div className="w3-row">
                                {/*
                                    ------
                                    HEADER
                                    ------
                                */}
                                {/* Desktop*/}
                                <div className="w3-col w3-hide-small w3-hide-medium">
                                    <div className="w3-display-container" style={{height:"80px"}}>
                                        <div className="w3-display-left w3-container">
                                            <h1>
                                                <strong><i className="fa fa-exchange" aria-hidden="true"></i>&nbsp;Transfers</strong>
                                            </h1>
                                        </div>
                                        <div className="w3-display-right">
                                            <input className="w3-input w3-border" type="text" placeholder={"Search"} onChange={onSearchChange} />
                                        </div>
                                    </div>
                                </div>
                                {/* Tablet / Mobile */}
                                <div className="w3-col w3-hide-large">
                                    <div className="w3-row" style={{height:"80px"}}>
                                        <div className="w3-col">
                                            <h3>
                                                <strong><i className="fa fa-exchange" aria-hidden="true"></i>&nbsp;Transfers</strong>
                                            </h3>
                                        </div>
                                        <div className="w3-col w3-margin-bottom">
                                            <input className="w3-input w3-border" type="text" placeholder={"Search"} onChange={onSearchChange} />
                                        </div>
                                    </div>

                                </div>
                                {/*
                                    ----------
                                    end HEADER
                                    ----------
                                */}

                                <ErrorBox errors={errors} />

                                {/*
                                    -----
                                    TABLE
                                    -----
                                */}
                                {isLoading
                                    ? <div className="w3-col">
                                        <div className="w3-responsive">
                                        <div className="w3-panel w3-pale-blue">
                                        <h3><i className="fa fa-spinner w3-spin" aria-hidden="true"></i>&nbsp;Loading...</h3>
                                        <p>Please wait while we fetch the data.</p>
                                    </div>
                                    </div>
                                    </div>
                                    : <>
                                        {results && count > 0
                                            ? <div className="w3-col">
                                                <div className="w3-responsive">
                                                    <table className="w3-table-all">
                                                        <tr style={{cursor: "pointer"}}>
                                                            <HeaderTableCell text={"Evaluation Name"} field={"evaluation_name"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"Student"} field={"student_name"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"School"} field={"school_name"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"Grade"} field={"grade"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"City"} field={"city"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"State"} field={"state"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <HeaderTableCell text={"Created"} field={"created_time"} sortOrder={sortOrder} sortField={sortField} onSortFieldClick={onSortFieldClick} />
                                                            <th className="">
                                                          </th>
                                                        </tr>
                                                        {results.map((SubmissionRemoteTransfer) => (
                                                            <tr>
                                                                <td>{SubmissionRemoteTransfer.evaluationName}</td>
                                                                <td>{SubmissionRemoteTransfer.studentName}&nbsp;(#{SubmissionRemoteTransfer.studentCode})</td>
                                                                <td>{SubmissionRemoteTransfer.schoolName}&nbsp;(#{SubmissionRemoteTransfer.schoolCode})</td>
                                                                <td>{SubmissionRemoteTransfer.grade}&nbsp;(#{SubmissionRemoteTransfer.grade})</td>
                                                                <td>{SubmissionRemoteTransfer.city}&nbsp;(#{SubmissionRemoteTransfer.city})</td>
                                                                <td>
                                                                    {SubmissionRemoteTransfer.state === 1 && <span>On Hold</span>}
                                                                    {SubmissionRemoteTransfer.state === 2 && <span>Queued</span>}
                                                                    {SubmissionRemoteTransfer.state === 3 && <span className="w3-text-green">Completed</span>}
                                                                    {SubmissionRemoteTransfer.state === 4 && <span className="w3-text-red">Error</span>}
                                                                    {SubmissionRemoteTransfer.state === 5 && <span className="w3-text-red">Not Implemented</span>}
                                                                </td>
                                                                <td>{new Date(SubmissionRemoteTransfer.createdAt).toLocaleString() }</td>
                                                                <td className="w3-text-grey w3-right">
                                                                    <Link to={`/transfer/${SubmissionRemoteTransfer.id}`}
                                                                       style={{textDecoration:"none",}}
                                                                    tabIndex={tabIndex}>
                                                                        View&nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                    <div className="w3-bar w3-border w3-round">
                                                        {offset > 0 &&
                                                            <button onClick={onPrevioustButtonClick} className="w3-button" type="button">
                                                                <strong>&#10094; Previous</strong>
                                                            </button>
                                                        }
                                                        {results.length > 0 && count > 0 && !isLastPage &&
                                                            <button onClick={onNextButtonClick} className="w3-button w3-right" type="button">
                                                                <strong>Next &#10095;</strong>
                                                            </button>
                                                        }
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            : <div className="w3-col">
                                                <div className="w3-pale-blue w3-round-xlarge w3-container w3-padding w3-border w3-margin-bottom">
                                                    <h3>No Transfers</h3>
                                                    <p>There are no records, please have your students perform the evaluations to generate data. Please note, if you haven't already, you will need to active remote transfers to utilize this feature. To enable this feature, go the <Link to="/organization">organization&nbsp;<i className="fa fa-link w3-text-grey" aria-hidden="true"></i></Link> and make the edit.</p>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                {/*
                                    ----------
                                    end TABLE
                                    ----------
                                */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function HeaderTableCell(props) {
    const { text, field, sortOrder, sortField, onSortFieldClick } = props;
    // console.log("HeaderTableCell", text, field, sortOrder, sortField);
    if (sortField === field) {
        if (sortOrder === "ASC") {
            return (
                <th onClick={(e, so, sf) => onSortFieldClick(e, "DESC", field) }>
                    {text}&nbsp;<i className="fa fa-sort-asc" aria-hidden="true"></i>
                </th>
            );
        } else {
            return (
                <th onClick={(e, so, sf) => onSortFieldClick(e, "ASC", field) }>
                    {text}&nbsp;<i className="fa fa-sort-desc" aria-hidden="true"></i>
                </th>
            );
        }
    } else {
        return (
            <th onClick={(e, so, sf) => onSortFieldClick(e, sortOrder, field) }>
                {text}&nbsp;<i className="fa fa-sort" aria-hidden="true"></i>
            </th>
        );
    }
}

export default SubmissionRemoteTransferList;
